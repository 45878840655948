@charset "UTF-8";

@font-face {
  //font-family: 'montserratblack';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-black.woff2') format('woff2'), url('../fonts/montserrat-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratblack_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-blackitalic.woff2') format('woff2'), url('../fonts/montserrat-blackitalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratextrabold';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-extrabold.woff2') format('woff2'), url('../fonts/montserrat-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratextrabold_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-extrabolditalic.woff2') format('woff2'), url('../fonts/montserrat-extrabolditalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratbold';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-bold.woff2') format('woff2'), url('../fonts/montserrat-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratbold_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-bolditalic.woff2') format('woff2'), url('../fonts/montserrat-bolditalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratsemibold';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-semibold.woff2') format('woff2'), url('../fonts/montserrat-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratsemibold_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-semibolditalic.woff2') format('woff2'), url('../fonts/montserrat-semibolditalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratmedium';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-medium.woff2') format('woff2'), url('../fonts/montserrat-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratmedium_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-mediumitalic.woff2') format('woff2'), url('../fonts/montserrat-mediumitalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratregular';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-regular.woff2') format('woff2'), url('../fonts/montserrat-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratitalic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-italic.woff2') format('woff2'), url('../fonts/montserrat-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratlight';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-light.woff2') format('woff2'), url('../fonts/montserrat-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratlight_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-lightitalic.woff2') format('woff2'), url('../fonts/montserrat-lightitalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratextralight';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-extralight.woff2') format('woff2'), url('../fonts/montserrat-extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratextralight_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-extralightitalic.woff2') format('woff2'), url('../fonts/montserrat-extralightitalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  //font-family: 'montserratthin';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-thin.woff2') format('woff2'), url('../fonts/montserrat-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  //font-family: 'montserratthin_italic';
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-thinitalic.woff2') format('woff2'), url('../fonts/montserrat-thinitalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}