.gform_wrapper {
  margin: 0 !important;
  max-width: 100% !important;
}

.gform_wrapper ul {
  @extend .list-unstyled;
}
.gform_wrapper .gfield_label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 2 !important;
}
.gform_wrapper li {
  @extend .form-group;
}
.gform_wrapper form {
  margin-bottom: 0;
}
.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $brand-danger;
}
.ginput_container  {
  max-width: 100% !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.gfield_description  {
  max-width: 100% !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  display: none !important;
}
.ginput_container input,
.ginput_container select,
.ginput_container textarea {
	@extend .form-control;
  padding: 10px 10px !important;
  font-size: 13px !important;
  width: 100% !important;
}
.ginput_container textarea {
  height: auto;
}
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
    width: 100% !important;
}
.gform_button {
	@extend .btn;
	@extend .btn-success;

  display: block;
  margin: 0;
  height: 51px;
  line-height: 51px;
  font-size: 13px !important;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 27px;
  width: 100%;
  text-align: center;
  @include gradient-vertical($gradient-second-color-one, $gradient-second-color-two);
  @include box-shadow(0 10px 15px rgba(0,0,0,.1));

  &:active {
    @include gradient-vertical($gradient-second-color-two, $gradient-second-color-one);
  }
}
.gform_wrapper .gfield_error {
  border: none !important;
  background: none !important;
  width: 100% !important;

  .gfield_label {
    color: $brand-danger;
    margin-left: 0px !important;
  }
  input,
  select,
  textarea {
    border-color: $brand-danger;
    color: $brand-danger;
    //@include form-control-focus($alert-danger-text);
  }
}
.validation_error {
  border: none !important;
  border-radius: 3px;
  background: $brand-danger;
  color: #fff !important;
  font-size: 15px !important;
  padding: 30px !important;
  width: 100% !important;
}
#gforms_confirmation_message {
	//@extend .alert;
	//@extend .alert-success;
}

/* .gform_footer input[type=submit] {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  height: 61px;
  line-height: 61px;
  padding: 0 27px;
  font-size: 13px !important;
} */