// Flatly 3.3.4
// Bootswatch
// -----------------------------------------------------

/* @import url("https://fonts.googleapis.com/css?family=Roboto:300,300italic,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700,900"); */
//@import url("http://fonts.googleapis.com/css?family=Montserrat:300,400,700,900");


// Navbar =====================================================================

.navbar {
  border-width: 0;

  .btn-group, .btn-circle-icon {
    margin-top: 21px;
    margin-bottom: 21px;
    margin-left: 5px;
    margin-right: 5px;
  }  

  &-default {

    .badge {
      background-color: #fff;
      color: $navbar-default-bg;
    }

    .btn {
      @extend .btn-default;

      &.btn-icon {

        i {
          color: #fff;
        }

        .badge {
          background: $brand-default;
        }

      }
    }

    .btn-group .btn, .btn-circle-icon {
      @extend .btn-primary;
      background: $navbar-default-darker-color;

      &:hover, &:focus {
        background: $brand-primary-darker !important;
      }
    }
  }

  &-inverse {

    .badge {
      background-color: #fff;
      color: $navbar-inverse-bg;
    }

    .btn {
      @extend .btn-primary;
    }
  }

  &-brand {
    line-height: 1;
  }
}


// Nav Tabs ====================================================================
.nav-tabs, .nav-pills {

  & > li {

    & > a {
      color: $gray-dark;
    }   
  }
}

// Breadcrumb ====================================================================
.breadcrumb {

  margin: 0;

  & > li {

    & + li:before {
      content: "";
      padding: 0px;
      margin: 7px 10px 5px;

      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-left: 3px solid;
      display: inline-block;
      height: 0;
      opacity: 1;
      vertical-align: top;
      width: 0;
    }

    & > a {
      color: $gray-dark;

      &:hover {
        color: $brand-default;
      }
    }
  }
}



// Buttons ====================================================================

.btn {
  border-width: 1px;
  font-family: $font-family-serif;
  font-weight: 400;
}

.btn:active {
  @include box-shadow(none);
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none;
}

// Typography =================================================================

.text-primary,
.text-primary:hover {
  color: $brand-primary;
}

.text-success,
.text-success:hover {
  color: $brand-success;
}

.text-danger,
.text-danger:hover {
  color: $brand-danger;
}

.text-warning,
.text-warning:hover {
  color: $brand-warning;
}

.text-info,
.text-info:hover {
  color: $brand-info;
}

// Tables =====================================================================

table,
.table {

  a:not(.btn) {
    text-decoration: underline;
  }

  .dropdown-menu a {
    text-decoration: none;
  }

  .success,
  .warning,
  .danger,
  .info {
    color: #fff;

    > th > a,
    > td > a,
    > a {
      color: #fff;
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: none;
  }

  &-bordered > thead > tr > th,
  &-bordered > tbody > tr > th,
  &-bordered > tfoot > tr > th,
  &-bordered > thead > tr > td,
  &-bordered > tbody > tr > td,
  &-bordered > tfoot > tr > td {
    border: 1px solid $table-border-color;
  }
}

// Forms ======================================================================

.form-control {
  height: 45px;
  font-size: 11px;

  &.input-lg {
    height: 61px;    
    font-size: 13px;
  }

  &.input-sm {
    height: 31px;    
  }

  &.input-xs {
    height: 21px;
  }

  &.form-circle {
    border-radius: 999px;
  }
}

textarea.form-control, select.form-control {
  &.form-circle {
    border-radius: 22px !important;
  }
}

.label {
  font-weight: normal;
}

.form-control,
input, {
  border-width: 1px;
  @include box-shadow(none);

  &:focus {
    @include box-shadow(none);
  }
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-warning;
  }

  .input-group-addon {
    border-color: $brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-danger;
  }

  .input-group-addon {
    border-color: $brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-success;
  }

  .input-group-addon {
    border-color: $brand-success;
  }
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.pager {
  a,
  a:hover {
    color: #fff;
  }

  .disabled {
    &>a,
    &>a:hover,
    &>a:focus,
    &>span {
      background-color: $pagination-disabled-bg;
    }
  }
}

// Indicators =================================================================

.close {
  color: #fff;
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {

  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

// Progress bars ==============================================================

.progress {
  height: 15px;
  .progress-bar {
    font-size: 10px;
    line-height: 15px;
    border-radius: $progress-border-radius;
    @include box-shadow(none);
  }
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}

a.list-group-item {

  &.active,
  &.active:hover,
  &.active:focus {
    border-color: $list-group-border;
  }

  &-success {
    &.active {
      background-color: $state-success-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-success-bg, 5%);
    }
  }

  &-warning {
    &.active {
      background-color: $state-warning-bg;
    }
    
    &.active:hover,
    &.active:focus {
      background-color: darken($state-warning-bg, 5%);
    }
  }

  &-danger {
    &.active {
      background-color: $state-danger-bg;
    }
    
    &.active:hover,
    &.active:focus {
      background-color: darken($state-danger-bg, 5%);
    }
  }
}

.panel {
  &-default {
    .close {
      color: $text-color;
    }
  }
}

.modal {
  .close {
    color: $text-color;
  }
}

.popover {
  color: $text-color;
  max-width: 100%;
}

// STARTED HERE MY FRIEND! =====================================================================================================

.transition-default {
  -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}

a {
  @extend .transition-default;
}

.btn {
  @extend .transition-default;
}

.background-color-primary {background-color: $primary;}
.background-color-secondary {background-color: $secondary;}
.background-color-purple {background-color: $purple;}
.background-color-green {background-color: $green;}
.background-color-gold {background-color: $gold;}
.background-color-silver {background-color: $silver;}
.background-color-bronze {background-color: $bronze;}
.background-color-gray-base {background-color: $gray-base;}
.background-color-gray-darker {background-color: $gray-darker;}
.background-color-gray-darkest {background-color: $gray-darkest;}
.background-color-gray-dark {background-color: $gray-dark;}
.background-color-gray {background-color: $gray;}
.background-color-gray-light {background-color: $gray-light;}
.background-color-gray-lighter {background-color: $gray-lighter;}
.background-color-gray-lightest {background-color: $gray-lightest;}

.badge {
  padding: 4px 6px;
}

.navbar-default .badge {
  background-color: $brand-default;
  color: #fff;
}

.navbar-brand {
  padding: 35px 15px;
}

.navbar-form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar-nav > li > a {
  padding-top: 34px;
  padding-bottom: 33px;
}

// default
.btn {
  height: 45px;
  line-height: 45px;
  border: none;
  padding: 0 17px;
  font-size: 13px;
}

// xs
.btn-xs, .btn-group-xs > .btn {
  height: 21px;
  line-height: 21px;
  padding: 0 10px;
  font-size: 10px;
}

// sm
.btn-sm, .btn-group-sm > .btn {
  height: 31px;
  line-height: 31px;
  padding: 0 13px;
  font-size: 11px;
}

// lg
.btn-lg, .btn-group-lg > .btn {
  height: 61px;
  line-height: 61px;
  padding: 0 27px;
  font-size: 15px;
}

.btn {
  
  // this s the default
  .icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;

    i {
      line-height: 45px;
      font-size: 16px;
    }

    &.icon-right {
      margin-right: -17px;
      margin-left: 17px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);        
    }

    &.icon-left {
      margin-left: -17px;
      margin-right: 17px;
      border-right: 1px solid rgba(0, 0, 0, 0.08);        
    }
  }

  //text-shadow:0px 1px 0px $brand-default-dark;
  
  // this is just for the primary color
  &.btn-primary { 
    //text-shadow:0px 1px 0px $brand-primary-dark;

    .icon {
      i {
      }
    }
  }

  &.btn-lg {    

    .icon {
      width: 61px;
      height: 61px;
      line-height: 61px;

      i {
        line-height: 61px;
      }

      &.icon-right {
        margin-right: -27px;
        margin-left: 27px;      
      }

      &.icon-left {
        margin-left: -27px;
        margin-right: 27px;        
      }
    }
  }

  &.btn-sm { 
    .icon {
      width: 31px;
      height: 31px;
      line-height: 31px;

      i {
        line-height: 31px;
        font-size: 13px;
      }

      &.icon-right {
        margin-right: -13px;
        margin-left: 13px;       
      }

      &.icon-left {
        margin-left: -13px;
        margin-right: 13px;       
      }
    }
  }

  &.btn-xs { 
    .icon {
      width: 21px;
      height: 21px;
      line-height: 21px;

      i {
        line-height: 21px;
        font-size: 8px;
      }

      &.icon-right {
        margin-right: -10px;
        margin-left: 10px;       
      }

      &.icon-left {
        margin-left: -10px;
        margin-right: 10px;       
      }
    }
  }

  &.btn-block {   

    .icon {
      &.icon-right {
        float: right;
        margin-right: -17px;     
      }

      &.icon-left {
        float: left;
        margin-left: -17px;     
      }
    }

    &.btn-lg {
      .icon {
        &.icon-right {
          margin-right: -27px;     
        }

        &.icon-left {
          margin-left: -27px;     
        }
      }      
    }

    &.btn-sm {
      .icon {
        &.icon-right {
          margin-right: -13px;     
        }

        &.icon-left {
          margin-left: -13px;     
        }
      }      
    }

    &.btn-xs {
      .icon {
        &.icon-right {
          margin-right: -10px;     
        }

        &.icon-left {
          margin-left: -10px;     
        }
      }      
    }
  }

  &.btn-default {
    //text-shadow: 0 1px 0 darken($brand-default, 20%);
  }

  &.btn-primary {
    //text-shadow: 0 1px 0 darken($brand-primary, 20%);
  }

  &.btn-success {
    //text-shadow: 0 1px 0 darken($brand-success, 20%);
  }

  &.btn-info {
    //text-shadow: 0 1px 0 darken($brand-info, 20%);
  }

  &.btn-warning {
    //text-shadow: 0 1px 0 darken($brand-warning, 20%);
  }

  &.btn-danger {
    //text-shadow: 0 1px 0 darken($brand-danger, 20%);
  }
}

.btn-icon {

  padding: 0;
  
  // this s the default
  &.btn { 
    background: none;

    i {
      color: $brand-default;
      font-size: 30px;
    }

    .badge {
      display: block;
      float: right;
      margin-top: -10px;
      margin-left: -13px;
      color: #fff;
      background: $brand-primary;
    }
  }
  
  // this is just for the primary color
  &.btn-primary { 
    i {
      color: $brand-primary;
      font-size: 30px;
    }    

    .badge {
      background: $brand-default;
    }
  }

  &.btn-lg { 
    i {
      font-size: 40px;
    }

    .badge {
      margin-top: -10px;
      margin-left: -20px;
    }
  }

  &.btn-sm { 
    i {
      font-size: 20px;
    }

    .badge {
      margin-top: -10px;
      margin-left: -10px;
    }
  }

   &.btn-xs { 
    i {
      font-size: 10px;
    }

    .badge {
      font-size: 10px;
      padding: 3px 4px;
      margin-top: -10px;
      margin-left: -5px;
    }
  }
}

.btn-group {

  & > .btn + .dropdown-toggle {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }

  &.open {
    & > .btn + .dropdown-toggle {
      border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
    }    
  }
}

.btn-group-circle {
  
  // make all versions round
  & > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 999px;
    border-top-left-radius: 999px;
  }

  & > .btn:last-child:not(:first-child), & > .dropdown-toggle:not(:first-child) {
    border-bottom-right-radius: 999px;
    border-top-right-radius: 999px;
  }
  
  // default
  & > .btn + .dropdown-toggle {
    padding-left: 16px;
    padding-right: 16px;
  }

  // extra small
  & > .btn-xs + .dropdown-toggle, &.btn-group-xs > .btn + .dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  // small
  & > .btn-sm + .dropdown-toggle, &.btn-group-sm > .btn + .dropdown-toggle {
    padding-left: 14px;
    padding-right: 14px;
  }

  // large
  & > .btn-lg + .dropdown-toggle, &.btn-group-lg > .btn + .dropdown-toggle {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.btn-circle { 
  @extend .btn-default;
  border-radius: 999px;

  &.btn-circle-icon {

    &.btn {      
      padding: 0;
      width: 45px;
      height: 45px;

      i {
        line-height: 45px;
        font-size: 16px;
      }
    }
    
    &.btn-lg {
      width: 61px;
      height: 61px;

      i {
        line-height: 61px;
        font-size: 25px;
      }
    }

    &.btn-sm {
      width: 31px;
      height: 31px;

      i {
        line-height: 31px;
        font-size: 11px;
      }
    }

    &.btn-xs {
      width: 21px;
      height: 21px;

      i {
        line-height: 21px;
        font-size: 7px;
      }
    }
  }
}

.tooltip {

  &.in {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  &.top .tooltip-arrow {
    border-top-color: $tooltip-bg;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: $tooltip-bg;
  }

  &.right .tooltip-arrow {
    border-right-color: $tooltip-bg;
  }

  &.left .tooltip-arrow {
    border-left-color: $tooltip-bg;
  }

  .tooltip-inner {
    background: $tooltip-bg;
  } 
}

.dropdown-menu {

  //border: none;
  margin-top: 10px !important;
  border-radius: 0;

  & > li > a {
    border-radius: $border-radius-base;
    margin: 5px 10px;
    padding: 10px 20px;

    &:hover {
      background: $brand-default;    
    }

    & > i {
      font-size: 14px;
      line-height: 14px;
      width: 14px;
      margin-right: 20px;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    right: 17px;
    margin-top: -4px;
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #fff;
    border: solid 1px $dropdown-border;
    border-bottom: none;
    border-right: none;
    @include rotate(45deg);
  }

}

.defaultBoxShadow {
  @include box-shadow(0 2px 5px rgba(0, 0, 0, 0.08));
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

// Bootstrap 3 responsive columns of same height

.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}

/* columns of same height styles */

.row-full-height {
  height: 100%;
}
.col-full-height {
  height: 100%;
  vertical-align: middle;
}
.row-same-height {
  display: table;
  width: 100%;
  /* fix overflow */
  table-layout: fixed;
}
.col-xs-height {
  display: table-cell;
  float: none !important;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

// --------------------------------------------------------- STYLING ELEMENTS THAT ARE BOOTSTRAP EXTENSIONS

/*.ps-container {

  & > .ps-scrollbar-y-rail {
    background: red;
  }

  &.ps-active-y {
    & > .ps-scrollbar-y-rail {
      background: blue;
    }
  }
}*/

.bootstrap-switch {
  border-color: $border;
  @include border-right-radius(2px);
  @include border-left-radius(2px);

  &.bootstrap-switch-on {
    border-color: $green;

    .bootstrap-switch-label {
      @include border-right-radius(1px);
    }
  }

  &.bootstrap-switch-off {
    border-color: $red;

    .bootstrap-switch-label {
      @include border-left-radius(1px);
    }
  }

  .bootstrap-switch-label {
    //@include border-top-radius(2px);
  }

  &.bootstrap-switch-focused {
    @include box-shadow(none);

    &.bootstrap-switch-on {
      border-color: $green;
    }

    &.bootstrap-switch-off {
      border-color: $red;
    }
  }

  .bootstrap-switch-handle-on {
    color: #fff !important;
    background: $green !important;
    @include border-left-radius(1px);
  }

  .bootstrap-switch-handle-off {
    color: #fff !important;
    background: $red !important;
    @include border-right-radius(1px);
  }
}

.radio input[type="radio"] {
  &:focus {
    outline: none !important;    
  }  
}

.checkbox input[type="checkbox"] {
  &:focus {
    outline: none !important;    
  }  
}

.input-knob {
  font-family: $font-family-sans-serif !important;
  font-size: 14px !important;
  //color: $brand-default !important;
}

.bootstrap-select.btn-group {

  .dropdown-toggle {
    &:focus {
      outline: none !important;    
    }
  }

  &.form-circle .dropdown-toggle {
    border-radius: 22px;      
  }

  &.open {

    &.dropup {
      .dropdown-toggle {
        @include border-top-radius(0px);
      }

      .dropdown-menu {
        margin-bottom: 0 !important;
        border-bottom: none;
        @include border-bottom-radius(0px);
      }
    }
    
    &:not(.dropup) {      
      .dropdown-toggle {
        @include border-bottom-radius(0px);
      }

      .dropdown-menu {
        margin-top: 0 !important;
        border-top: none;
        @include border-top-radius(0px);
      }
    }

  }

}

// ------------------- parsley

input.parsley-success, select.parsley-success, textarea.parsley-success {

  color: $brand-success;
  background-color: lighten($brand-success, 70%);
  border-color: $brand-success !important;

  &::-webkit-input-placeholder { color: $brand-success; }
  &::-moz-placeholder { color: $brand-success; }
  &:-ms-input-placeholder { color: $brand-success; }
  &:-moz-placeholder { color: $brand-success; }
}

input.parsley-error, select.parsley-error, textarea.parsley-error {

  color: $brand-danger;
  background-color: lighten($brand-danger, 45%);
  border-color: $brand-danger !important;

  &::-webkit-input-placeholder { color: $brand-danger; }
  &::-moz-placeholder { color: $brand-danger; }
  &:-ms-input-placeholder { color: $brand-danger; }
  &:-moz-placeholder { color: $brand-danger; }
}

.parsley-errors-list {
  margin: 0 0 20px;
  padding: 0;
  text-align: center;
  list-style-type: none;
  font-size: 11px;
  line-height: 11px;
  opacity: 0;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;

  .parsley-custom-error-message {
    color: $brand-danger;  
  }

  &.filled {
    opacity: 1;
  }
}

// --------------------------

*[data-animate-counter] {
  visibility: hidden;
}

.row-centered {
    text-align:center;
}

.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}