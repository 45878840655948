#productBanner {
	$footerHeight: 80px;

	position: relative;
	width: 100%;
	min-height: 500px;
	height: 100vh;

	.bg {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		//@include gradient-directional($gradient-first-color-one, $gradient-first-color-two, 25deg);
	}

	&.tv-corporativa {
		$color-one: #00d2ff;
		$color-two: #1bf1c6;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(100%, $color-two));
		background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 100%); 
		background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 100%); 
		background: linear-gradient(135deg, $color-one 0%, $color-two 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1 );
	}

	&.webinar {
		$color-one: #e81445;
		$color-two: #ffac53;
		$color-three: #ffee5d;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(21%, $color-one), color-stop(72%, $color-two), color-stop(100%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%); 
		background: -o-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%); 
		background: linear-gradient(135deg, $color-one 21%, $color-two 72%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	&.endomarketing-marketing, &.endomarketing-digital {
		$color-one: #300a64;
		$color-two: #4b00bb;
		$color-three: #6cfee8;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(40%, $color-two), color-stop(100%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%); 
		background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%); 
		background: linear-gradient(135deg, $color-one 0%, $color-two 40%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	&.digital-signage {
		$color-one: #3900bb;
		$color-two: #00d2ff;
		$color-three: #abffcf;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(24%, $color-one), color-stop(76%, $color-two), color-stop(1024%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%); 
		background: -o-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%); 
		background: linear-gradient(135deg, $color-one 24%, $color-two 76%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	&.ensino-a-distancia {
		$color-one: #07748b;
		$color-two: #28ccab;
		$color-three: #00fff6;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	.productBannerContainer {
		position: relative;
		display: table;
		width: 100%;
		height: 100%;

		.productBannerContent {
			display: table-cell;
			vertical-align: middle;

			.title {
				display: block;
				text-align: center;

				font-size: 5.2vw;
				line-height: 5.2vw;

				margin: 0;
				font-weight: 300;
				text-transform: uppercase;
				color: #fff;

				br {
					display: none;
				}

				span, b {
					font-weight: 500;
				}
			}

			.subtitle {
				display: block;
				text-align: center;
				font-size: 1.6vw;
				line-height: 2.6vw;
				font-weight: 200;
				width: 70%;
				color: #fff;
				margin:1vw auto 0 auto;

				span, b {
					font-weight: 400;
				}
			}
		}
	}

	.productBannerFooter {
		$productFooterLabelHeight: 15px;

		position: absolute;
		bottom: 0;
		width: 100%;
		//height: $footerHeight;

		.container {
			position: relative;
		}

		.productBannerArrowDown {
			position: absolute;
			bottom: 104px;
			left: 50%;
			margin-left: -15px;
			@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);
			box-shadow: 0 40px 70px rgba(0,0,0,.7);
			@include transition(none);

			.fa {
				font-size: 19px;
			}
		}

		.blogPostContainerWrapper {
			position: relative;
			bottom: 50px;
			
			.blogPostContainer {
				$w: 70px;
				$h: $w / (16 / 9);

				float: right;
				bottom: 100px;
				display: block;
				height: $h;
				width: 350px;
				overflow: hidden;

				&:hover {

					.media {

					    .mediaImage {
							webkit-transform: scale($blogImageScale, $blogImageScale);
						    -ms-transform: scale($blogImageScale, $blogImageScale);
						    -o-transform: scale($blogImageScale, $blogImageScale);
						    transform: scale($blogImageScale, $blogImageScale);
						    opacity: $blogImageOpacity;
					    }

					    .mediaIcon {
							opacity: 1;
						}
					}
				}

				.media {
					position: relative;
					display: block;
					float: left;
					margin-right: 20px;
					background: #000;
					width: $w;
					height: $h;
					overflow: hidden;

					.mediaImage {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						@extend .transition-default;

						img {
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}

					.mediaIcon {
						position: absolute;
						z-index: 1;
						top: 50%;
						left: 50%;
						margin-top: -7px;
						margin-left: -7px;
						font-size: 14px;
						color: #fff;
						opacity: 0;
						@extend .transition-default;
						text-align: center;
						margin-bottom: 30px;
					}
				}

				.text {
					position: relative;
					display: table;
					height: $h;
					overflow: hidden;

					.entry-title {
						display: table-cell;
						vertical-align: middle;
						margin: 0;
						font-size: 14px;
						line-height: 18px;
						font-weight: 300;

						a {
							color: #fff;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		.productBannerFooter {

			.blogPostContainerWrapper {
				display: none;

				.blogPostContainer {
				}
			}
		}

		.productBannerContainer {

			.productBannerContent {

				.subtitle {
					font-size: 2vw;
					line-height: 3.5vw;
					margin:1.5vw auto 0 auto;
				}
			}
		}

	}

	@media (max-width: $screen-sm) {
		
		.productBannerFooter {

			.blogPostContainerWrapper {
				display: none;
			}

			.productBannerArrowDown {
				bottom: 80px;
			}
		}

		.productBannerContainer {

			.productBannerContent {

				.title {
					font-size: 8vw;
					line-height: 10vw;

					br {
						display: inline-block;
					}
				}

				.subtitle {
					font-size: 3.5vw;
					line-height: 5vw;
					margin:2vw auto 0 auto;
				}
			}
		}
	}
}

#materialForm {
	position: relative;
	overflow: hidden;
	padding: 30px 0 50px 0;
	background: #fff;

	.mainTitle {
		display: block;
		font-weight: 700;
		font-size: 30px;
		margin: 0;
		color: $text-color;
	}

	.mainSubtitle {
		display: block;
		font-weight: 400;
		padding: 0;
		margin: 0;
		color: $text-color;
		font-size: 16px;
	}

	.contentSpace {
		padding-top: 75px;
		font-size: 15px;
		line-height: 2;

		.imageContainer {
			padding-bottom: 50px;

			img {
				display: block;
				max-height: 500px;
				width: auto;
				margin: 0 auto;
			}
		}

		.title {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 30px 0;
		}
	}

	@media (max-width: $screen-md) { 
		padding: 30px 15px;
	}
}

#contactForm {
	position: relative;
	overflow: hidden;
	padding: 30px 0 50px 0;
	background: #fff;

	.mainTitle {
		display: block;
		font-weight: 700;
		font-size: 30px;
		margin: 0;
		color: $text-color;
	}

	.mainSubtitle {
		display: block;
		font-weight: 400;
		padding: 0;
		margin: 0;
		color: $text-color;
		font-size: 16px;
	}

	.contentSpace {
		padding-top: 75px;
		font-size: 15px;
		line-height: 2;

		.title {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 30px 0;
		}
	}

	@media (max-width: $screen-md) { 
		padding: 30px 15px;
	}
}

#contactMap {
	position: relative;
	height: 60vh;
	min-height: 500px;

	&:after {
		content: '';
		pointer-events: none;
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		@include box-shadow(inset 0 0 150px rgba(0,0,0,0.3));
	}

	#contactMapContent {
		position: relative;
		height: 100%;
		width: 100%;
	}
}

#aboutGallery {
	$headerColor: #52747d;

	padding: 100px 0;
	background: $gray-lightest-2;

	.aboutGalleryHeader {
		margin-bottom: 50px;

		.title {
			$tickWidth: 24px;

			position: relative;
			margin: 0 auto;
			font-size: 20px;
			text-transform: uppercase;
			color: $headerColor;
			font-weight: 500;
			text-align: center;
			padding: 30px 0 0 0;
	 
	 		&:before {
				position: absolute;
	 			display: block;
	 			content: '';
				height: 4px;
				border-radius: 10px;
				width: $tickWidth;
				top: 0;
				left: 50%;
				margin-left: -($tickWidth / 2);
				background: $headerColor;
			}
		}
	}

	.row {
		margin-left: 0px;
		margin-right: 0px;

		.col {
			padding: 0px;
		}
	}

	.imageContainer {
		position: relative;
		display: block;
		cursor: pointer;
		@include aspect-ratio(4, 3);

		img {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@extend .transition-default;
		}

		.btn {
			$size: 51px;

			position: absolute;
			top: 50%;
			left: 50%;
			width: $size;
			height: $size;
			font-size: 30px;
			line-height: $size;
			color: $blue;
			z-index: 1;
			margin: (- $size / 2) 0 0 (- $size / 2);
			background: #fff;
			opacity: 0;
			@extend .transition-default;
		}

		&:hover {

			img {
				filter: none;
			    -webkit-filter: grayscale(1);
			    -moz-filter:    grayscale(1);
			    -ms-filter:     grayscale(1);
			    -o-filter:      grayscale(1);
			}

			.btn {
				opacity: 1;
			}
		}
	}
}

#productGallery {
	$gridItems: 8;
	$gridSize: 100% / $gridItems;
	$gridHeight: 100vw / $gridItems;
	//$gridSize: 100vw / $gridItems;
	$gridPadd: 2px;

	$buttonHeight: 51px;
	$color: #0c3d49;

	position: relative;
	display: none;
	background: #fff;

	.productGalleryHeader {
		position: relative;
		margin-bottom: -$gridPadd;
		z-index: 3;
		padding: 100px 0;
		background: $gray-lightest;

		.mainTitle {
			display: block;
			float: left;
			text-transform: uppercase;
			font-size: 30px;
			line-height: 35px;
			font-weight: 300;
			margin: 0;
			color: $color;

			span {
				font-weight: 500;
			}
		}
	}

	.productGalleryContent {
		position: relative;
		display: none;
		width: 100%;
		background: #fff;
		padding-bottom: 100px;

		.grid-container {
			margin: -$gridPadd;
		}
		
		.grid-item {
			float: left;
			position: relative;
			width: $gridSize;
			height: $gridHeight;
			//height: 250px;
			padding: $gridPadd;

			.arrowButton {
				$size: 51px;

				position: absolute;
				display: block;
				width: $size;
				height: $size;
				top: 50%;
				left: 50%;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
				@extend .transition-default;
				@include scale(0, 0);
				@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);

				.iconContainer {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					display: block;
					font-size: 35px;
					font-weight: 200;
					color: #fff;
					margin: 0;

					.icon {
						display: block;
						margin: 0 auto;
						text-align: center;
						width: $size;
						height: $size;
						line-height: $size;
					}
				}
			}

			.grid-item-content {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				background: #001728;

				&:hover {

					img {
						opacity: .5;
					}

					.arrowButton {
						@include scale(1, 1);
					}
				}

				img {
					position: relative;
					display: block;
					object-fit: cover;
					width: 100%;
					height: 100%;
					@extend .transition-default;
				}
			}

			.grid-item-button-container {
				position: relative;
				cursor: pointer;
				display: block;
				width: 100%;
				height: 100%;
				background: #fff;

				.arrowButton {
					@include scale(1, 1);
				}

				.label {
					position: absolute;
					top: auto;
					bottom: -20px;
					left: -50%;
					display: block;
					width: 200%;
					text-align: center;
					font-size: 12px;
					line-height: 12px;
					height: 12px;
					padding: 0;
					color: $gray-light;
				}

			}

			&.grid-item-width-2 {
				width: $gridSize * 2;
			}
		}
	}

	@media (max-width: $screen-md) {

		.productGalleryHeader {
			
			.callToActionContainer {

				.textContainer {
					display: none;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		
		.productGalleryHeader {
		    padding: 50px 30px;

		    .mainTitle {
		    	display: block;
		    	width: 100%;
		    	text-align: center;
		    	margin-bottom: 30px;
		    }
			
			.callToActionContainer {
				width: 100%;
				text-align: center;

				.callToAction {
					float: none;
					margin: 0;

					.sm {
						display: inline;
					}

					.bg {
						display: none;
					}
				}

				.textContainer {
					display: none;
				}
			}
		}
	}

	@media (max-width: $screen-xs) {
		
		.productGalleryHeader {
			
			.callToActionContainer {

				.textContainer {
					display: none;
				}
			}
		}
	}
}

#homeClients {
	$textColorOne: #52747d; //$gray-light;
	$ctaHeight: 65px;

	position: relative;
	background: #fff;
	padding: 100px 0;

	.homeClientsHeader {
		margin-bottom: 30px;

		.title {
			$tickWidth: 24px;

			position: relative;
			margin: 0 auto;
			font-size: 20px;
			text-transform: uppercase;
			color: $textColorOne;
			font-weight: 500;
			text-align: center;
			padding: 30px 0 0 0;
	 
	 		&:before {
				position: absolute;
	 			display: block;
	 			content: '';
				height: 4px;
				border-radius: 10px;
				width: $tickWidth;
				top: 0;
				left: 50%;
				margin-left: -($tickWidth / 2);
				background: $textColorOne;
			}
		}
	}

	.homeClientsLogos {
		margin-bottom: 50px;
		text-align: center;

		img {
			width: 100%;
			height: 100%;
			filter: none;
		    -webkit-filter: grayscale(1);
		    -moz-filter:    grayscale(1);
		    -ms-filter:     grayscale(1);
		    -o-filter:      grayscale(1);
		}
	}

	.homeClientsButtonContainer {

		.btn {
			display: block;
			margin: 0 auto;
			border-radius: 999px;
			height: $ctaHeight;
			line-height: $ctaHeight;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
			max-width: 380px;
			text-align: center;
			@include gradient-vertical($gradient-second-color-one, $gradient-second-color-two);

			.sm {
				display: none;
			}

			.bg {
				display: inline;
			}
		}

		.btnLabel {
			display: block;
			text-align: center;
			color: $textColorOne;
			font-size: 15px;
			line-height: 30px;
			padding: 0 30px;
			font-weight: 400;
			margin-top: 30px;
		}
	}

	/* flickity on by default */
	.carousel:after {
	  content: 'flickity';
	  display: none;
	}

	@media screen and ( min-width: $screen-lg ) {
	  /* disable Flickity for large devices */
	  .carousel:after {
	    content: '';
	  }
	}

	@media (max-width: $screen-md) {
		padding: 50px 30px;
	}

	@media (max-width: $screen-sm) {

		.homeClientsButtonContainer {
			margin-top: 30px;

			.btn {
				line-height: 51px;
				height: 51px;
				font-size: 13px;
				max-width: 270px;

				.sm {
					display: inline;
				}

				.bg {
					display: none;
				}
			}
		}
	}
}

#allClients {
	$items: 6;
	$textColorOne: #52747d; //$gray-light;
	$ctaHeight: 65px;
	$bgColor: $gray-lightest-2;
	$borderColor: $gray-lighter;

	position: relative;
	background: $bgColor;
	padding: 100px 0;

	.allClientsText {

		.tick {
			display: block;
			float: left;
			content: '';
			width: 60px;
			height: 3px;
			background: $brand-primary;
			margin-bottom: 30px;
		}

		.title {
			display: block;
			font-size: 35px;
			font-weight: 400;
			margin: 0 0 30px 0;
			clear: both;
		}

		.text {
			display: block;
			width: 100%;
			font-size: 16px;
			line-height: 28px;
			font-weight: 300;

			p {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.allClientsLogos {
		margin-top: 50px;
		border-radius: 10px;
		overflow: hidden;
		border: solid 1px $borderColor;
		background: #fff;

		.allClientsLogosContent {
			margin-bottom: -2px;
			margin-right: -2px;
		}

		.imageContainer, .moreClients {
			@include aspect-ratio(16, 9);
			display: block;
			float: left;
			width: 100% / $items;
			border-right: solid 1px $borderColor;
			border-bottom: solid 1px $borderColor;

			img {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

		.moreClients {
			position: relative;
			@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);

			.moreClientsContent {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				text-align: center;

				span {
					display: block;
					position: relative;
					top: 50%;
					color: #fff;
					font-size: 30px;
					font-weight: 200;
					transform: translateY(-50%);
				}
			}
		}
	}

	.allClientsButtonContainer {

		.btn {
			display: block;
			margin: 0 auto;
			border-radius: 999px;
			height: $ctaHeight;
			line-height: $ctaHeight;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
			max-width: 380px;
			text-align: center;
			@include gradient-vertical($gradient-second-color-one, $gradient-second-color-two);

			.sm {
				display: none;
			}

			.bg {
				display: inline;
			}
		}

		.btnLabel {
			display: block;
			text-align: center;
			color: $textColorOne;
			font-size: 15px;
			line-height: 30px;
			padding: 0 30px;
			font-weight: 400;
			margin-top: 30px;
		}
	}

	@media (min-width: 1200px) and (max-width: $screen-xl - 1px) {
		.hidden-lg-special {
		    display: none !important;
		}
	}

	@media (max-width: $screen-xl) {
		$items: 4;

		.allClientsLogos {

			.imageContainer, .moreClients {
				width: 100% / $items;
			}
		}
	}

	@media (max-width: $screen-md) {
		$items: 2;

		.allClientsLogos {

			.imageContainer, .moreClients {
				width: 100% / $items;
			}
		}
	}

	@media (max-width: $screen-sm) {

		.allClientsLogos {

			.moreClients {

				.moreClientsContent {

					span {
						font-size: 5vw;
					}
				}
			}
		}
	}
}

#homeBanner {
	$footerHeight: 80px;
	$color-one: #FD192F;
	$color-two: #FD1939;
	$color-three: #F57B46;
	$marginBottom: 100px;

	position: relative;
	width: 100%;
	min-height: 500px;
	height: 100vh;
	padding-bottom: $marginBottom;

	.bg {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;


		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
		background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	.circleAnimation {
		$width: 111px;
		$height: 105px;

		position: absolute;
		top: 30%;
		left: 50%;
		width: $width;
		height: $height;
		margin: (- $height / 2) 0 0 (- $width / 2);

		.circle {
			position: absolute;
			display: none;
			top: 50%;
			left: 50%;	

			&.circleOne {
				$w: 53px;
				$h: 54px;

				top: 76%;
				left: 76%;
				width: $w;
				height: $h;
				margin: (- $h / 2) 0 0 (- $w / 2);
				z-index: 3;
			}

			&.circleTwo {
				$w: 33px;
				$h: 34px;

				top: 55%;
				left: 15%;
				width: $w;
				height: $h;
				margin: (- $h / 2) 0 0 (- $w / 2);
			}

			&.circleThree {
				$w: 24px;
				$h: 24px;

				top: 12%;
				width: $w;
				height: $h;
				margin: (- $w / 2) 0 0 (- $h / 2);
			}
		}
	}

	.homeVideo {
		position: absolute;
		width: 100%;
		height: 100%;

		video {
			position: relative;
			display: block;
		}
	}

	.homeBannerContainer {
		position: relative;
		display: table;
		width: 100%;
		height: 100%;

		.homeBannerContent {
			display: table-cell;
			vertical-align: middle;

			.title {
				display: block;
				text-align: center;

				font-size: 5.2vw;
				line-height: 5.2vw;
				padding-top: 5vw;

				margin: 0;
				font-weight: 300;
				text-transform: uppercase;
				color: #fff;

				span {
					font-weight: 500;
				}
			}

			.subtitle {
				display: block;
				text-align: center;
				font-size: 1.2vw;
				line-height: 1.2vw;
				font-weight: 400;
				color: #fff;
				margin:0;
				margin-bottom: 1.5vw;
				letter-spacing: 2px;
			}
		}
	}

	.homeBannerArrowDown {
		position: absolute;
		bottom: 104px + $marginBottom;
		left: 50%;
		margin-left: -15px;
		background: #fff;
		box-shadow: 0 40px 70px rgba(0,0,0,.7);
		@include transition(none);

		.fa {
			font-size: 19px;
			color: $color-two;
		}

		.label {
			$width: 600%;

			position: absolute;
			top: auto;
			bottom: -40px;
			left: -($width / 2);
			margin-left: (31px / 2);
			display: block;
			width: $width;
			text-align: center;
			font-weight: 200;
			font-size: 12px;
			line-height: 12px;
			height: 12px;
			padding: 0;
			color: #fff;
		}
	}

	@media (max-width: $screen-md) {
		padding-bottom: 50px;

		.homeBannerArrowDown {
			bottom: 150px;
		}
	}

	@media (max-width: $screen-sm) {
		padding-bottom: 0;

		.circleAnimation {
			//@include scale(0.7, 0.7);
		}

		.homeBannerContainer {

			.homeBannerContent {

				.title {
					padding-top: 10vw;
				}
			}
		}

		.homeBannerArrowDown {
			bottom: 100px;
		}
	}

	@media (max-width: $screen-xs) {

		.circleAnimation {
			@include scale(0.8, 0.8);
		}
	}
}

#homeProducts {
	$color-questions: #e42d2d;

	$productItemHeight: 438px;
	$productItemIconSize: 64px;
	$productItemButtonHeight: 51px;
	$productItemPadd: 50px;
	$productTextLines: 6;
	$productTitleLineHeight: 24px;
	$productTextLineHeight: 18px;

	position: relative;
	z-index: 2;
	//padding-top: -$productItemPadd;

	.productsDesktop {
		position: relative;
		margin-top: -$productItemPadd;
		//margin-top: -$productItemPadd;
		
		.productItem {
			background: #fff;
			padding: $productItemPadd;
			background: #fff;
			margin-bottom: 30px;
			height: $productItemHeight;
			border-radius: 5px;
			@include box-shadow(0 20px 60px rgba(0,0,0,.09));

			&.ensino-a-distancia {

				.productItemTitle {
					color: $color-ensino-a-distancia;
				}

				.btn {
					background: $color-ensino-a-distancia;
				}
			}

			&.endomarketing-digital {

				.productItemTitle {
					color: $color-endomarketing-digital;
				}

				.btn {
					background: $color-endomarketing-digital;
				}
			}

			&.webinar {

				.productItemTitle {
					color: $color-webinar;
				}

				.btn {
					background: $color-webinar;
				}
			}

			&.tv-corporativa {

				.productItemTitle {
					color: $color-tv-corporativa;
				}

				.btn {
					background: $color-tv-corporativa;
				}
			}

			&.digital-signage {

				.productItemTitle {
					color: $color-digital-signage;
				}

				.btn {
					background: $color-digital-signage;
				}
			}

			&.questions {
				display: table;

				.productItemContent {
					display: table-cell;
					vertical-align: middle;
				}

				.productItemTitle {
					color: $color-questions;
					text-transform: none;
				}

				.productItemText {
					margin-bottom: 0px;
				}

				.productItemIcon {
					width: 99px;
					height: 96px;
					border-radius: 0px;
				}

				.btn {
					background: $color-questions;
				}
			}

			.productItemTitle {
				display: block;
				text-align: center;

				font-size: 18px;
				line-height: $productTitleLineHeight;

				margin: 30px 0 0px 0;
				font-weight: 300;
				text-transform: uppercase;

				span {
					font-weight: 500;
				}
			}

			.productItemText {
				color: #235b69;
				text-align: center;
				font-size: 14px;
				line-height: $productTextLineHeight;
				height: $productTextLineHeight * $productTextLines;
				margin: 30px 0;
			}

			.productItemIcon {
				margin: 0 auto;
				width: $productItemIconSize;
				height: $productItemIconSize;
				border-radius: 999px;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			.btn {
				display: block;
				margin: 0 auto;
				width: 90%;
				text-transform: uppercase;
			    font-weight: 500;
			    height: $productItemButtonHeight;
			    line-height: $productItemButtonHeight;
			    border-radius: 5px;
			    font-size: 13px;
			}
		}
	}

	.productsMobile {
		$imageSize: 50px;
		$paddH: 30px;
		$paddV: 20px;
		$margin: 10px;
		$arrowWidth: 20px;

		display: none;
		position: relative;
		padding: 50px 30px;

		.panel-group {
			margin: 0;

			.panel {
				border: none;
				overflow: hidden;
				@include box-shadow(0 20px 60px rgba(0,0,0,.09));

				&.ensino-a-distancia {

					.productItemTitle {
						color: $color-ensino-a-distancia;
					}

					.btn {
						background: $color-ensino-a-distancia;
					}
				}

				&.endomarketing-digital {

					.productItemTitle {
						color: $color-endomarketing-digital;
					}

					.btn {
						background: $color-endomarketing-digital;
					}
				}

				&.webinar {

					.productItemTitle {
						color: $color-webinar;
					}

					.btn {
						background: $color-webinar;
					}
				}

				&.tv-corporativa {

					.productItemTitle {
						color: $color-tv-corporativa;
					}

					.btn {
						background: $color-tv-corporativa;
					}
				}

				&.digital-signage {

					.productItemTitle {
						color: $color-digital-signage;
					}

					.btn {
						background: $color-digital-signage;
					}
				}

				& + .panel {
				    margin-top: $margin;
				}

				& > .panel-heading {
					background: #fff;
					padding: 0;
					border: none;

					.panel-title {

						a {
							position: relative;
							display: block;
							padding: $paddV $paddH;

							.productItemTitleContainer {
								display: table;
								padding-left: $imageSize + ($paddH / 2);
								padding-right: $arrowWidth;
								width: 100%;
								height: $imageSize;

								.productItemTitle {
									display: table-cell;
									vertical-align: middle;
									font-size: 18px;
									line-height: 24px;
									font-weight: 300;
									text-transform: uppercase;

									span {
										font-weight: 500;
									}
								}
							}

							img {
								position: absolute;
								display: block;
								float: left;
								width: $imageSize;
								height: $imageSize;
								margin-right: $paddV;
								border-radius: 999px;
							}

							.arrow {
								position: absolute;
								top: 50%;
								right: $paddH;
								color: $gray-lighter;
								font-size: 20px;
								line-height: $arrowWidth;
								text-align: center;
								width: $arrowWidth;
								height: $arrowWidth;
								margin-top: -($arrowWidth / 2);
								@extend .transition-default;
							}

							&.collapsed {
								
								.arrow {	
									@include rotate(180deg);
								}
							}
						}
					}
				}

				.panel-collapse {
					
				}

				.panel-body {
					border: none;
					padding: $paddV $paddH;
					padding-top: 0;

					.productItemText {
						color: #235b69;
						text-align: left;
						font-size: 16px;
						line-height: 27px;
						margin: $paddV 0;
					}

					.btn {
						display: block;
						margin: 0 auto;
						width: 100%;
						text-transform: uppercase;
					    font-weight: 500;
					    height: $productItemButtonHeight;
					    line-height: $productItemButtonHeight;
					    border-radius: 5px;
					    font-size: 13px;
					}
				}
			}
		}
	}

	.homeProductsBackground {
		position: absolute;
		z-index: 0;
		top: $productItemPadd;
		width: 100%;
		height: 100%;
		background: $gray-lightest-2;
	}

	@media (max-width: $screen-sm) {

		.productsDesktop {
			margin-top: 0;
			display: none;
		}

		.productsMobile {
			display: block;
		}

		.homeProductsBackground {
			//display: none;
			top: 0;
		}
	}
}

#contactInfo {
	$textColorOne: #52747d; //$gray-light;
	$textColorTwo: #0c3d49;
	$blogPostWidth: 144px;
	$iconWidth: 70px;
	$iconHeight: 30px;

	position: relative;
	background: $gray-lightest-2;
	padding: 70px 0;

	.contactInfoItem {
		position: relative;
		padding: 30px 0;
		padding-left: $iconWidth;
		background: $gray-lightest-2;

		.title {
			display: block;
			font-size: 20px;
			font-weight: 500;
			line-height: $iconHeight;
			margin: 0;
		}

		.text {
			display: block;
			font-size: 14px;
			font-weight: 400;
			line-height: 30px;
			margin-top: 10px;

			a {
				color: $text-color;

				&:hover {
					color: $brand-primary;
				}
			}

			ul {
				display: block;
				list-style: none;
				padding: 0;

				li {
					display: inline-block;

					&:after {
						display: inline-block;
						margin: 0 10px;
						content: '|';
						color: $gray-lighter;
					}

					&:last-child {

						&:after {
							display: none;
						}
					}
				}
			}
		}

		.mdi {
			position: absolute;
			left: 0;
			width: $iconWidth;
			line-height: $iconHeight;
			height: $iconHeight;
			font-size: 40px;
			color: $gray-light;
		}
	}

	@media (max-width: $screen-md) {
		padding-bottom: 20px;

		.contactInfoItem {
			padding-left: 0;
			padding-top: 50px;
			padding-bottom: 50px;

			.title {
				text-align: center;
			}

			.text {
				text-align: center;
			}

			.mdi {
				position: absolute;
				top: 0;
				left: 50%;
				text-align: center;
				margin-left: -($iconWidth / 2);
			}
		}
	}
}

#homeBlog {
	$textColorOne: #52747d; //$gray-light;
	$textColorTwo: #0c3d49;
	$blogPostWidth: 144px;

	position: relative;
	background: $gray-lightest-2;
	padding-bottom: 100px;

	.homeBlogHeader {
		padding: 50px 0 70px 0;

		.title {
			$tickWidth: 24px;

			position: relative;
			margin: 0 auto;
			font-size: 20px;
			text-transform: uppercase;
			color: $textColorOne;
			font-weight: 500;
			text-align: center;
			padding: 30px 0 0 0;
	 
	 		&:before {
				position: absolute;
	 			display: block;
	 			content: '';
				height: 4px;
				border-radius: 10px;
				width: $tickWidth;
				top: 0;
				left: 50%;
				margin-left: -($tickWidth / 2);
				background: $textColorOne;
			}
		}
	}

	.sectionTitle {
		display: block;
		color: $textColorOne;
		font-size: 15px;
		font-weight: 500;
		margin: 0 0 30px 0;
	}

	.moreBlogPostsLink {
		position: absolute;
		bottom: 0;
		color: $textColorOne;
		font-size: 15px;
		line-height: 20px;
		font-weight: 400;

		span {
			float: left;
		}

		i {
			float: left;
			margin-left: 10px;
			font-size: 17px;
			line-height: 22px;
		}
	}

	.blogPostContainer {

		.blogPostContent {
			display: block;
			padding: 0px;
			margin: 0px;

			.blogPost {
				$w: $blogPostWidth;
				$h: $w / (16 / 9);

				display: block;
				height: $h;
				overflow: hidden;
				margin-bottom: 50px;

				&:hover {

					.media {

					    .mediaImage {
							webkit-transform: scale($blogImageScale, $blogImageScale);
						    -ms-transform: scale($blogImageScale, $blogImageScale);
						    -o-transform: scale($blogImageScale, $blogImageScale);
						    transform: scale($blogImageScale, $blogImageScale);
						    opacity: $blogImageOpacity;
					    }

					    .mediaIcon {
							opacity: 1;
						}
					}
				}

				.media {
					position: relative;
					display: block;
					float: left;
					margin-right: 15px;
					background: #000;
					width: $w;
					height: $h;
					overflow: hidden;

					.mediaImage {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						@extend .transition-default;

						img {
							object-fit: cover;
							font-family: 'object-fit: cover;';
							width: 100%;
							height: 100%;
						}
					}

					.mediaIcon {
						position: absolute;
						z-index: 1;
						top: 50%;
						left: 50%;
						margin-top: -9px;
						margin-left: -9px;
						font-size: 18px;
						color: #fff;
						opacity: 0;
						@extend .transition-default;
						text-align: center;
						margin-bottom: 30px;

						&.mdi-arrow-right {
							font-size: 18px;
							margin-top: -11px;
						}
					}
				}

				.text {
					position: relative;
					display: table;
					height: $h;
					overflow: hidden;

					.entry-title {
						display: table-cell;
						vertical-align: middle;
						margin: 0;
						font-size: 16px;
						line-height: 22px;
						font-weight: 500;
						max-width: 300px;

						a {
							color: $textColorTwo
						}
					}
				}
			}
		}
	}

	.blogVideoContainer {
		$playSize: 40px;
		$playBorderSize: 3px;

		.blogVideoContent {
			display: block;
			padding: 0px;
			margin: 0px;

			.blogVideo {
				display: block;
				overflow: hidden;

				.media {
					position: relative;
					width: 100%;
					background: #000;

					.mediaImage {
						position: absolute;
						//top: 0;
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						font-family: 'object-fit: cover;';
						@extend .transition-default;
					}

					.plyr__play-large {
						display: none !important;
					}

					.play {
						position: absolute;
						top: 50%;
						left: 50%;
						margin: (- $playSize / 2) 0 0 (- $playSize / 2);
						width: $playSize;
						height: $playSize;
						border: solid $playBorderSize #fff;
						border-radius: 999px;

						.fa {
							position: absolute;
							top: -$playBorderSize;
							font-size: 15px;
							color: #fff;
							text-align: center;
							line-height: $playSize;
							width: $playSize;
							height: $playSize;
						}
					}
				}
				.entry-title {
					margin: 25px 0 0 0;
					font-size: 15px;
					line-height: 22px;
					font-weight: 400;

					a {
						color: $textColorTwo;
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 50px;
		
		.moreBlogPostsLink {
			display: none;
		}
	}
}

#trackRecords {
	$opacity: rgba(#000, 0.05);
	$trackHeight: 32px;

	//------------

	$color-one: #FD192F;
	$color-two: #FD1939;
	$color-three: #F57B46;

	//------------

	$fontColor: $color-one;

	position: relative;
	padding: 100px 0 100px;
	overflow: hidden;

	background: $color-one;
	background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
	background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
	background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
	background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
	background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%); 
	background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );

	h3 {
		color: #fff;
		font-size: 35px;
		text-transform: uppercase;
		font-weight: 300;
		margin: 0;
	}

	h4 {			
		color: #fff;
		font-weight: 200;
		font-size: 15px;
		margin: 0 0 15px 0;
	}

	#buttons {

		float: right;

		.btn {
			display: block;
			width: 50px;
			height: 40px;
			border: none;
			background: #fff;

			.fa {
				color: $color-one;
				font-size: 20px;
				line-height: 40px;
			}
		}

		& > .btn:last-child:not(:first-child) {
			-webkit-border-top-right-radius: 100px;
			-webkit-border-bottom-right-radius: 100px;
			-moz-border-radius-topright: 100px;
			-moz-border-radius-bottomright: 100px;
			border-top-right-radius: 100px;
			border-bottom-right-radius: 100px;
		}

		& > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
			-webkit-border-top-left-radius: 100px;
			-webkit-border-bottom-left-radius: 100px;
			-moz-border-radius-topleft: 100px;
			-moz-border-radius-bottomleft: 100px;
			border-top-left-radius: 100px;
			border-bottom-left-radius: 100px;
		}
	}

	#trackContainer {
		padding: 60px 0;
	}

	#yearBallon {
		display: block;
		position: absolute;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-weight: bold;
		background: #fff;			
		color: $color-one;
		border-radius: 100px;
	}

	#track {
		position: relative;
		z-index: 999;
		padding: 100px 0;
		-webkit-border-radius: $trackHeight;
		-moz-border-radius: $trackHeight;
		border-radius: $trackHeight;

		.front {
			$point: 1px;

			display: block;
			position: absolute;
			width: $trackHeight;
			height: $trackHeight;
			border-radius: $trackHeight;
			background: #fff;
			overflow: hidden;

			.bg {
				display: absolute;
				padding: 0 ($trackHeight / 2);

				span {
					position: relative;
					top: ($trackHeight / 2) - ($point / 2);
					display: block;
					content: '';
					width: 100%;
					height: $point;
					border-radius: 999px;
					background: $fontColor;
				}
			}

			.left {
				display: block;
				float: left;
				width: 19px;
				height: $trackHeight;
				background: #fff;

				-webkit-border-top-left-radius: 100px;
				-webkit-border-bottom-left-radius: 100px;
				-moz-border-radius-topleft: 100px;
				-moz-border-radius-bottomleft: 100px;
				border-top-left-radius: 100px;
				border-bottom-left-radius: 100px;
			}

			.right {
				display: block;
				float: right;
				width: 19px;
				height: $trackHeight;
				background: #fff;

				-webkit-border-top-right-radius: 100px;
				-webkit-border-bottom-right-radius: 100px;
				-moz-border-radius-topright: 100px;
				-moz-border-radius-bottomright: 100px;
				border-top-right-radius: 100px;
				border-bottom-right-radius: 100px;
			}				
		}

		.back {
			display: block;
			width: 100%;
			height: $trackHeight;
			background: rgba(#000, 0.15);
			border-radius: $trackHeight;

			.point {
				$point: 7px;

				cursor: pointer;
				position: absolute;
				display: block;
				float: left;
				width: $trackHeight;
				height: $trackHeight;

				&:after {
					position: absolute;
					top: ($trackHeight / 2) - ($point / 2);
					left: ($trackHeight / 2) - ($point / 2);
					content: '';
					width: $point;
					height: $point;
					border-radius: 999px;
					background: $fontColor;
				}

				&:hover {
					//background-position: center bottom;
				}
			}			
		}
	}

	#content {
		position: relative;
		z-index: 999;
		color: #fff;
		font-size: 18px;
		line-height: 30px;
		font-weight: 300;
		min-height: 150px;
	}

	#bigYear {
		cursor: default;
		display: block;
		overflow: hidden;
		margin-top: -300px;
		padding-top: 100px;
		left: -100px;
		position: absolute;
		z-index: 0;
		color: rgba(#000, 0.05);
		height: 400px;
		font-size: 500px;
		line-height: 400px;
		font-weight: 700;
	}

	ul#trackRecordsItems {
		display: none;
	}

	@media (max-width: $screen-md) {
		padding: 70px 15px;

		h3, h4 {
			text-align: center;
		}

		#trackContainer {
			margin-bottom: -80px;
		}

		#buttons {
			display: none;
		}
	}
}

#aboutInfo {

	.row {
		margin: 100px 0;
		padding-bottom: 100px;
		border-bottom: solid 1px $gray-lighter;

		&:last-child {
			border: none;
			padding-bottom: 0;
		}
	}

	.image {
		position: relative;
		display: block;
		width: 100%;
		max-width: 300px;
		height: auto;
	}

	.tick {
		display: block;
		float: left;
		content: '';
		width: 60px;
		height: 3px;
		background: $brand-primary;
		margin-bottom: 30px;
	}

	.title {
		display: block;
		font-size: 35px;
		font-weight: 400;
		margin: 0 0 30px 0;
		clear: both;
	}

	.text {
		display: block;
		width: 100%;
		font-size: 16px;
		line-height: 28px;
		font-weight: 300;

		p {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.pull-right {
		float: right !important;

		img {
			float: right;
		}
	}

	.pull-left {
		float: left !important;
	}

	@media (max-width: $screen-lg) {

		.row {
			margin: 70px 0;
			padding-bottom: 70px;
		}

		.image {
			margin: 0 auto;
			float: none !important;
			max-width: 250px;
			margin-bottom: 50px;
		}

		.pull-right {

			img {
				float: none;
			}
		}
	}

	@media (max-width: $screen-sm) {

		.image {
			max-width: 200px;
		}
	}
}

#blogBanner {
	position: relative;
	width: 100%;	
	height: 70vh;
	min-height: 650px;
	overflow: hidden;

	.carousel {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;

		.flickity-viewport {
			height: 100% !important;
		}

		.flickity-page-dots {
			bottom: 50px;

			.dot {
				background: #fff;
				width: 12px;
				height: 12px;
			}
		}

		.item {
			width: 100%;
			height: 100%;
			background: #000;
			overflow: hidden;

			.bg {
				display: block;
				object-fit: cover;
				height: 100%;
				width: 100%;
				opacity: $blogImageOpacity;
			}

			.textContainer {
				position: absolute;
				top: 0;
				z-index: 3;
				height: 100%;
				width: 100%;
				display: table;
				opacity: 0;

				.textContent {
					position: relative;
					z-index: 3;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					padding-left: 30px;
					padding-right: 30px;

					.entry-title {
						display: block;
						color: #fff;
						font-size: 50px;
						line-height: 60px;
						font-weight: 400;
						margin: 0px;
						max-width: 700px;
						margin: 0 auto;

						strong {
							font-weight: 700;
						}
					}

					.btn {
						$height: 41px;
						$border: 1px;

						font-weight: 400;
						font-size: 15px;
						letter-spacing: 1px;
						height: $height;
			    		line-height: $height - ($border * 2);
			    		padding: 0 30px;
			    		border-radius: 99999px;
						margin-top: 50px;
						
						text-transform: lowercase;
						color: #fff;
						border: solid $border #fff;
						background: none;

						&:hover {
							background: $brand-default;
							border-color: $brand-default;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-lg) { 

		.carousel {

			.item {

				.textContainer {

					.textContent {
						padding-right: 100px;
						padding-left: 100px;

						.entry-title {
							font-size: 45px;
							line-height: 55px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) { 

		.carousel {

			/* .flickity-page-dots {
				bottom: -48px;

				.dot {
					background: $gray;
					width: 12px;
					height: 12px;
				}
			} */

			.item {

				.textContainer {

					.textContent {
						padding-top: 50px;
						padding-right: 80px;
						padding-left: 80px;
						
						.entry-title {
							font-size: 35px;
							line-height: 45px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 
		height: 80vh;
		min-height: 700px;

		.carousel {

			.item {

				.textContainer {

					.textContent {
						padding-top: 0px;
						padding-right: 50px;
						padding-left: 50px;
						
						.entry-title {
							font-size: 30px;
							line-height: 35px;
						}

						.btn {
							$height: 31px;
							$border: 1px;

							font-size: 11px;
							letter-spacing: 1px;
							height: $height;
				    		line-height: $height - ($border * 2);
				    		padding: 0 20px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) { 

		.carousel {

			.item {

				.textContainer {

					.textContent {
						padding-right: 30px;
						padding-left: 30px;
						
						.entry-title {
							font-size: 25px;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
}

#blogBannerItem {
	position: relative;
	width: 100%;	
	height: 70vh;
	min-height: 650px;
	background: #000;
	overflow: hidden;

	.bg {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: $blogImageOpacity;

		img {
			position: absolute;
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.textContainer {
		position: absolute;
		top: 0;
		z-index: 3;
		height: 100%;
		width: 100%;
		display: table;

		.textContent {
			position: relative;
			z-index: 3;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding-left: 30px;
			padding-right: 30px;

			.entry-categories {
				text-align: center;
				margin-bottom: 20px;

				a {
					display: inline-block;
					text-transform: uppercase;
					margin: 0 5px;
					font-size: 10px;
					font-weight: 400;
					line-height: 10px;
					color: #fff;
					letter-spacing: 1px;

					&:hover, &:active, &:focus {
						color: $brand-default;
					}
				}
			}

			.entry-title {
				display: block;
				color: #fff;
				font-size: 50px;
				line-height: 60px;
				font-weight: 400;
				margin: 0px;
				max-width: 700px;
				margin: 0 auto;

				strong {
					font-weight: 700;
				}
			}

			.btn {
				$height: 41px;
				$border: 1px;

				font-weight: 400;
				font-size: 15px;
				letter-spacing: 1px;
				height: $height;
	    		line-height: $height - ($border * 2);
	    		padding: 0 30px;
	    		border-radius: 99999px;
				margin-top: 50px;
				
				text-transform: lowercase;
				color: #fff;
				border: solid $border #fff;
				background: none;

				&:hover {
					background: $brand-default;
					border-color: $brand-default;
				}
			}
		}
	}

	.bannerAuthor {
		$photoSize: 50px;
		$w: 300px;
		$photoGap: 30px;
		$titleHeight: 20px;

		position: absolute;
		bottom: 30px;
		left: 50%;
		width: $w;
		margin-left: -($w / 2);

		.photo {
			display: block;
			width: $photoSize;
			height: $photoSize;
			border-radius: 999px;
			margin: 0 auto;

			img {
				position: relative;
				display: block;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.author-details {
			display: block;
			text-align: center;
			font-size: 12px;
			color: #fff;
			margin-top: 10px;
			font-weight: 300;

			.name {
				font-weight: 400;
				font-style: italic;
				font-size: 15px;
			}
		}
	}

	&.notFound {

		.textContainer {

			.textContent {

				.title {
					display: block;
				    color: #fff;
				    font-size: 50px;
				    line-height: 60px;
				    font-weight: 400;
				    margin: 0px;
				    max-width: 700px;
				    margin: 0 auto;
				}

				.subtitle {
					color: $brand-primary;
					font-size: 120px;
					font-weight: 600;
				}
			}
		}
	}

	@media (max-width: $screen-lg) { 

		.textContainer {

			.textContent {
				padding-right: 100px;
				padding-left: 100px;

				.entry-title {
					font-size: 45px;
					line-height: 55px;
				}
			}
		}
	}

	@media (max-width: $screen-md) { 
		
		.textContainer {

			.textContent {
				padding-top: 50px;
				padding-right: 80px;
				padding-left: 80px;
				
				.entry-title {
					font-size: 35px;
					line-height: 45px;
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 
		height: 80vh;
		min-height: 500px;

		.textContainer {

			.textContent {
				padding-top: 0px;
				padding-right: 50px;
				padding-left: 50px;
				
				.entry-title {
					font-size: 30px;
					line-height: 35px;
				}

				.btn {
					$height: 31px;
					$border: 1px;

					font-size: 11px;
					letter-spacing: 1px;
					height: $height;
		    		line-height: $height - ($border * 2);
		    		padding: 0 20px;
				}
			}
		}
	}

	@media (max-width: $screen-xs) { 

		.textContainer {

			.textContent {
				padding-right: 30px;
				padding-left: 30px;
				
				.entry-title {
					font-size: 25px;
					line-height: 30px;
				}
			}
		}
	}
}

#blogBannerItem + #breadcrumbs {
	margin-top: 0;
}

#blogBannerBrand {
	position: relative;
	width: 100%;	
	height: 60vh;

	.brandContainer {
		$logoSize: 250px;
		$dogDistance: -5%;
		$bottomHeight: 0;

		position: relative;		
		z-index: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.bg {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;

			// ------------------------------------------------

			$color-one: #ff192f;
			$color-two: #e1134d;
			$color-three: #ffb750;

			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 60%, $color-three 100%); 
			background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(60%, $color-two), color-stop(100%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 60%, $color-three 100%); 
			background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 60%, $color-three 100%); 
			background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 60%, $color-three 100%); 
			background: linear-gradient(135deg, $color-one 0%, $color-two 60%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );

			// ------------------------------------------------

			img {
				position: absolute;
				display: block;
				object-fit: cover;
				font-family: 'object-fit: cover;';
				width: 100%;
				height: 100%;
				display: none;
			}
		}

		.brandContentWrapper {
			position: relative;
			display: table;
			width: 100%;
			height: 100%;

			.brandContent {
				display: table-cell;
				vertical-align: middle;
				padding-top: 5vh;

				.title {
					display: block;
					text-align: center;

					font-size: 2vw;
					line-height: 2.6vw;
					width: 50%;

					margin: 0 auto;
					font-weight: 300;
					color: #fff;

					span {
						font-weight: 500;
					}
				}

				.subtitle {
					display: block;
					text-align: center;
					font-size: 1.2vw;
					line-height: 1.2vw;
					font-weight: 400;
					color: #fff;
					margin:0;
					margin-bottom: 1.5vw;
					letter-spacing: 2px;
				}
			}
		}
	}

	@media (max-width: $screen-md) { 
		height: 50vh;

		.brandContainer {

			.brandContentWrapper {

				.brandContent {
					padding-top: 5vh;

					.title {
						font-size: 3vw;
						line-height: 4vw;
						width: 70%;
					}
				}
			}
		}
	}
}

#author-block {
	$photoSize: 100px;
	$photoGap: 30px;
	$titleHeight: 20px;

	position: relative;
	margin-top: 80px;
	margin-bottom: 80px;

	a {
		color: $brand-default;
	}

	h3 {
		margin: 0 0 30px 0;
		height: $titleHeight;

		span {
			display: block;
			float: left;
			padding-right: 10px;
			font-size: $titleHeight;
			line-height: $titleHeight;
			text-transform: uppercase;
			background: #fff;
			margin-top: -($titleHeight / 2);
		}

		&:after {
			content: '';
			display: block;
			border-bottom: solid 1px $gray-lighter;
		}
	}

	.photo {
		position: absolute;
		display: block;
		left: 0;
		width: $photoSize;
		height: $photoSize;
		border-radius: 999px;
		border: solid 1px $gray-lightest;
		margin: 0 30px 30px 0;

		img {
			position: relative;
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.name {
		font-weight: 500;
		font-size: 18px;

		a {
			color: $text-color;
		}
	}

	.author-details {
		position: relative;
		padding-left: $photoSize + $photoGap;
		left: 0;
		width: 100%;
	}
}

#featuredPosts {

	.featurePostsTitle {
		text-align: center;
		padding: 30px 0;
		margin: 50px 0;
		font-weight: 400;
		font-size: 24px;
		border-bottom: solid 1px $gray-lighter;
	}

	.postContainer {

		.post {
			position: relative;
			display: block;
			overflow: hidden;

			&:hover {

				.media {

				    .mediaImage {
						webkit-transform: scale($blogImageScale, $blogImageScale);
					    -ms-transform: scale($blogImageScale, $blogImageScale);
					    -o-transform: scale($blogImageScale, $blogImageScale);
					    transform: scale($blogImageScale, $blogImageScale);
					    opacity: $blogImageOpacity;
				    }

				    .mediaIcon {
						opacity: 1;
					}
				}
			}			

			.media {
				position: relative;
				display: block;
				float: left;
				margin-right: 10px;
				background: #000;
				width: 100%;
				overflow: hidden;

				.mediaImage {
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					@extend .transition-default;

					img {
						object-fit: cover;
						font-family: 'object-fit: cover;';
						width: 100%;
						height: 100%;
					}
				}

				.mediaIcon {
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					margin-top: -7px;
					margin-left: -7px;
					font-size: 25px;
					color: #fff;
					opacity: 0;
					@extend .transition-default;
					text-align: center;

					&.mdi-arrow-right {
						font-size: 30px;
						margin-left: -15px;
						margin-top: -20px;
					}
				}
			}

			.text {
				margin-bottom: 20px;

				.entry-title {
					display: table;
					width: 100%;
					text-align: center;
					margin: 0;
					font-size: 18px;
					line-height: 28px;
					height: 28px * 3;
					font-weight: 400;
					
					a {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		.featurePostsTitle {
			padding: 0;
			border: none;
		}
		
		.col {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

#featuredPostsContainer {
	position: relative;
	background: $gray-lightest-2;
	padding: 100px 0 50px;

	#featuredPosts {

		.featurePostsTitle {
			margin-top: 0px;
			padding-top: 0px;
		}
	}
}

/*.fade-in.lazyloading {
	opacity: 0;
	transition: opacity 600ms;
}

.fade-in.lazyloaded {
	opacity: 1;
}*/

.blur-up {
	-webkit-filter: blur(10px);
	filter: blur(10px);
	transition: filter 600ms, -webkit-filter 600ms;
}

.blur-up.lazyloaded {
	-webkit-filter: blur(0);
	filter: blur(0);
}

.img-fade-box .lazyload, .img-fade-box .lazyloading {
	opacity: 0;
	transition: opacity 600ms;
}

.img-fade-box img.lazyloaded {
	opacity: 1;
}

// BUG FIX -> https://github.com/metafizzy/flickity/issues/97
.flickity-enabled.is-draggable .flickity-viewport{
    touch-action: pan-y !important;
}
// ----------------------------------------------------------

#breadcrumbs {
	$size: $blogBannerGap;
	$height: 26px;

	ul.breadcrumb {
		margin: 0;
		background: none;
		padding: (($size - $height) / 2) 0;

		li {
			font-size: 13px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: $height;
			//line-height: $size;
			color: $text-color;

			&:before {
				line-height: $height;
				margin-top: 10px;
			}

			a {
				color: $text-color;
				text-decoration: none;

				&:hover {
					color: $brand-primary;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		display: none;
	}
}	

.questionHeader + #breadcrumbs {
	margin-top: $questionHeaderHeight;
}

.productDetailPriceContainer {
	font-weight: 700;
	color: $gray-lighter;
	font-size: 11px;

	.price {
		color: $brand-default;
		font-size: 25px;
		line-height: 30px;
		font-weight: 900;
	}

	.buyButton {
		margin: 15px 0;
	}

	.cardFlags {
		opacity: .5;
		width: 200px;
	}
}

#backToTopButton {
	$margin: 30px;

	position: fixed;
	bottom: $margin;
	right: $margin;
	z-index: 10;
	opacity: 0;
	@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);
	@include box-shadow(0px 10px 20px rgba(0,0,0,.2));
	
	.fa {
		font-size: 29px;
		line-height: 40px;
	}

	&.show {
		opacity: 1;
	}

	@media (max-width: $screen-sm) {
		display: none;
	}
}

#sectionCourseDetail {
	margin-top: 40px;

	.courseInformationMain {

		.breadcrumb {
			margin-bottom: 10px;

			& > li  {

				& > a, &.active {
					color: $gray-light;
					font-size: 11px;
				}

				& + li:before {
					color: $gray-light;
				}
			}
		}

		.titleRow {

			margin-bottom: 40px;

			.title {
				font-size: 22px;
				color: $brand-default;
				font-weight: 900;
				margin: 0;
			}

			.stars {
				margin-bottom: 5px;
				text-align: right;

				.fa {
					font-size: 18px;
					margin-right: 3px;

					&.full {
						color: $gold;
					}

					&.empty {
						color: $gray-lighter;
					}
				}

				span {
					font-size: 18px;
					color: $brand-default;
				}
			}

			.seeTestimonials {
				display: block;
				text-align: right;
				font-size: 11px;
				color: $brand-default;
			}
		}

		.contentRow {
			margin-bottom: 40px;

			.sidebarMenu {

				.btn {
					padding: 10px;
					margin-bottom: 10px;
					height: auto;
					border-radius: $border-radius-base;

					.fa {
						display: block;
						font-size: 15px;
						line-height: 20px;
						text-align: center;
					}

					span {
						display: block;
						line-height: 20px;
						font-size: 12px;
					}
				}
			}

			.productImage {
				position: relative;
				overflow: hidden;

				img {
					width: 100%;
					max-width: auto;
				}

				.badgeDetail {
					position: absolute;
					z-index: 0;
					top: 0px;
					left: -170px;

					.background {
						background: $brand-primary;
						width: 200px;
						height: 300px;

						@include skew(-30deg, 0deg);
					}

					.text {
						position: absolute;
						top: 15px;
						right: -58px;
						color: #fff;
						text-align: center;
						width: 80px;
						font-size: 11px;
					}
				}
			}

			.detailsTable {
				margin-bottom: 10px;

				tbody {

					tr {
						border-bottom: solid 1px $gray-lightest;

						&:last-child {
							border: none;
						}

						td {
							color: $gray-light;
							font-size: 11px;

							&:nth-child(even) {
								font-weight: 900;
								text-align: right;
								color: $brand-default;
							}
						}

					}
				}
			}

			.discountForSmallScreen {
				$height: 60px;
				$color: #f8b259;

				margin-top: 30px;
				padding: 10px;
				background: $color;
				border-radius: 3px;

				.percentage {
					position: absolute;
					display: block;
					float: left;
					font-size: 20px;
					font-weight: 900;
					width: $height;
					height: $height;
					line-height: $height;
					text-align: center;
					border-radius: 999px;
					margin-right: 10px;
					color: $color;
					background: #fff;
				}

				.textContainer {
					display: table;
					width: 100%;
					padding-left: $height + 10px;
					float: right;
					height: $height;

					.text {
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: #fff;
					}
				}
			}
		}
	}

	.courseInformationStrip {
		$height: 60px;
		$color: #f8b259;

		background: $brand-primary;
		padding: 20px 0;

		.discount {
			float: left;

			.percentage {
				display: block;
				float: left;
				font-size: 20px;
				font-weight: 900;
				width: $height;
				height: $height;
				line-height: $height;
				text-align: center;
				border-radius: 999px;
				margin-right: 10px;
				color: #fff;
				background: $color;
			}

			.textContainer {
				display: table;
				height: $height;

				.text {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					font-weight: 900;
					font-size: 16px;
					line-height: 24px;
					color: #fff;
				}
			}
		}

		.itemsContainer {

			$padd: 10px;

			margin-left: -$padd;
			margin-right: -$padd;

			.col {
				padding-left: $padd;
				padding-right: $padd;
			}

			.item {
				$iconWidth: 30px;
				$iconMargin: 10px;

				color: #fff;
				padding-left: 15px;
				padding-right: 15px;
				border-radius: 3px;
				cursor: default;
				text-align: center;
				@extend .transition-default;

				&:hover {
					background: $brand-primary-dark; 
				}

				& > .icon {
					position: absolute;
					height: $height;
					top: 0;
					display: block;
					width: $iconWidth;
					margin-right: 10px;
					text-align: center;

					.fa {
						line-height: $height;
						font-size: 25px;
					}

					.photo {
						margin: 10px auto;
						width: $height - 20px;
						line-height: $height;
					}
				}

				& > .textContainer {
					display: table;
					width: 100%;
					height: $height;
					padding-left: $iconWidth + $iconMargin;

					.text {
						display: table-cell;
						vertical-align: middle;
						font-weight: 700;

						.title {
							color: $color;
							display: block;
							font-size: 11px;
							margin-bottom: 5px;
						}

						.subtitle {
							display: block;
							font-size: 14px;
						}
					}
				}

				.popoverContent {
					display: none;
				}
			}
		}
	}

	.courseInformationContainer {
		padding: 60px 0;

		.nav-tabs {
			border: none;
			margin-bottom: 40px;

			& > li {
				border: none;
				margin-right: 30px;

				a {
					border: none;
					padding: 0 0 15px 0;
					font-size: 18px;
					font-weight: 400;
					border-bottom: solid 2px #fff;
					color: $gray-lighter;
				}

				&.active {
					
					a {
						border: none;
						border-bottom: solid 2px $brand-primary;
						color: $brand-default;
					}
				}
			}
		}

		.productDetailPriceContainer {
			position: absolute;
			top: 0;
			width: 100%;
			padding-right: 30px;

			.productDetailPriceContent {
				padding: 20px;
				border: solid 1px $gray-lightest;
				border-radius: 3px;

				.buyButton  {
					margin-bottom: 0;
				}
			}
		}

		.tab-content {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			color: $brand-default;

			p {
				margin-bottom: 12px;
			}
		}
	}

	@media (max-width: $screen-md) { 

		.courseInformationMain {
			
			.contentRow {
				margin-bottom: 20px;

				.productDetailPriceContainer {
					margin-top: 20px;

					.colPrice {
						padding-top: 15px;
						padding-bottom: 15px;
						text-align: right;
					}

					.colFlags {
						padding-top: 37px;

						.cardFlags  {
							width: 100%;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 

		.courseInformationMain {
			
			.contentRow {

				.detailsTable {
					margin-top: 20px;
				}

				.productDetailPriceContainer {

					.colPrice {
						text-align: left;
					}
				}
			}
		}

		.courseInformationStrip {


			.itemsContainer {

				.item {

					& > .icon {
						margin: 0 0 0 -15px;
						left: 50%;

						.photo {
							margin: 17px auto;
						}
					}

					& > .textContainer {
						padding: 0;

						.text {
							padding-top: 60px;
							padding-bottom: 15px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) { 

		.courseInformationMain {

			.contentRow {

				.productDetailPriceContainer {

					.colPrice, .colBuyButton {
						width: 100%;
						text-align: center;
					}
				}
			}
		}

		.courseInformationStrip {

			.itemsContainer {

				.item {
					padding-left: 0;
					padding-right: 0;

					&:hover {
						background: none;
					}
				}
			}
		}
	}
}

.teacherPopoverContainer {
	$popoverHeight: 50px;
	max-width: 350px;
	padding: 20px;

	& > .header {
		display: block;
		margin-bottom: 20px;

		& > .icon {
			height: $popoverHeight;
			width: $popoverHeight;
			display: block;
			margin-right: 15px;
			text-align: center;
			float: left;

			.fa {
				line-height: $popoverHeight;
				font-size: 25px;
			}

			.photo {
				line-height: $popoverHeight;
			}
		}

		& > .textContainer {
			display: table;
			height:$popoverHeight;
			text-align: left;

			.text {
				display: table-cell;
				vertical-align: middle;
				font-weight: 700;

				.title {
					color: $brand-primary;
					display: block;
					font-size: 17px;
					margin-bottom: 5px;
				}

				.subtitle {
					display: block;
					font-size: 11px;
					color: $brand-default;
				}
			}
		}
	}

	& > .content {
		display: block;
		color: $brand-default;
		font-size: 12px;
		line-height: 20px;
	}
}

.facebookCommentContainer {
	$photoSize: 32px;
	$photoGap: 10px;
	$blue: #365899;
	$black: #1d2129;
	$gray: #90949c;
	
	.facebookComment {
		position: relative;
		color: $black;
		cursor: default;
		font-family: helvetica, arial, sans-serif;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin-bottom: 10px;
	  
		.facebookComment {
			margin-top: 10px;
			border-left: dotted 1px $gray;
			padding-left: 10px;

			.photo {
				left: $photoGap;
			}
		}
	  
		.photo {
			position: absolute;
			left: 0;
			top: 0;

			img {
				width: $photoSize;
				height: $photoSize;
			}
		}

		.content {
			position: relative;
			padding-left: $photoSize + $photoGap;
			
			.name {
				display: block;
				font-weight: bold;
				font-size: 14px;
				line-height: 19.012px;
				color: $blue;
			}

			.info {
				display: block;
				font-size: 11px;
				line-height: 14.938px;
				color: $gray;
			}

			.comment {
				display: block;
				margin: 4px 0;
				font-size: 14px;
			}

			.button-actions {
				display: block;
				height: 20px;

				ul {
					padding: 0;
					list-style: none;

					li {
						display: inline-block;
						float: left;
						margin: 0;
						padding: 0;
						font-size: 12px;
						color: $blue;
						line-height: 1.358;

						&.like {

							.likeIcon {
								float: left;
								line-height: 0.5;
							}

							span {
								float: left;
								padding: 0 5px;
								line-height: 1.358;
								color: $gray;
							}
						}

						&:after {
							content: '·';
							display: inline-block;
							padding: 0 5px;
							color: $gray;
						}

						&:last-child {
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}


#pageHeaderContainer {
	background: $gray;
	background-attachment: fixed;
	padding-top: $headerHeight;
	height: 200px;

	.pageHeaderContent {
		position: relative;
		width: 100%;
		height: 100%;
		display: table;

		.pageHeaderContentInside {
			display: table-cell;
			vertical-align: middle;

			.page-header {
				margin: 0;
				padding: 0;

				h1 {
					margin: 0;
					font-size: 22px;
					line-height: 22px;
					font-weight: 400;
					color: #fff;

					.fa {
						font-size: 12px;
						margin-right: 10px;
					}

					span {
						color: $brand-default;
					}
				}
			}

		}
	}

	@media (max-width: $screen-md) { 
		height: $headerHeight + 60px;

		.pageHeaderContent {

			.pageHeaderContentInside {

				.page-header {

					h1 {
						font-size: 18px;
						line-height: 22px;
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 

		.pageHeaderContent {

			.pageHeaderContentInside {

				.page-header {

					h1 {
						font-size: 16px;
						line-height: 18px;
					}
				}
			}
		}
	}
}

.ctaContainer {
	$color: #1B998B;
	$color: $brand-primary;

	position: relative;
	height: 200px;
	width: 100%;
	overflow: hidden;
	z-index: 0;
	display: table;

	background: $color;
	background-attachment: fixed;

	.ctaContent {
		display: table-cell;
		vertical-align: middle;

		.or {
			display: block;
			height: 61px;
			line-height: 61px;
			width: 30px;
			text-align: center;
			color: #fff;
			position: absolute;
			left: 50%;
			font-size: 14px;
			font-weight: 400;
			margin-left: -15px;
		}

		.btn {
			font-weight: 700;
			text-shadow: none;
			font-size: 20px;
			border-color: $gray;
			color: $gray;
			text-shadow: none;
		}

		.colQuestion {
			padding-right: 30px;

			.btnQuestion {
				border-color: #fff;
				color: #fff;

				&:hover, &:active, &:focus {
					color: $color;
					background: #fff;
					background-attachment: fixed;
				}
			}
		}

		.colSubscribe {
			padding-left: 30px;

			.btnSubscribe {
				border-color: $brand-default;
				color: $brand-default;

				&:hover, &:active, &:focus {
					color: $color;
					background: $brand-default;
					background-attachment: fixed;
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 
		
		height: 100px;

		.ctaContent {

			.or {
				height: 45px;
				line-height: 45px;
			}
			
			.btn {
				height: 45px;
			    line-height: 45px;
			    padding: 0 17px;
			    font-size: 13px;
			}
		}
	}

	@media (max-width: 650px) { 


		.ctaContent {

			.or {
				display: none !important;
			}
			
			.colQuestion {
				padding-right: 15px;
				padding-left: 15px;
				width: 100%;
			}

			.colSubscribe {
				display: none;
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
}

#homeBannerBrand {
	$carouselPadd: 50px;

	position: relative;
	width: 100%;	
	height: 100vh;
	background: #000;

	.homeVideo {
		position: fixed;
		width: 100%;
		height: 100%;
	}

	.homeCarousel {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;

		.flickity-viewport {
			position: absolute;
			width: 100% !important;
			height: 100% !important;

			.item {
				position: absolute;
				display: table;
				width: 100%;
				height: 100%;

				.item-content {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					color: #fff;
					text-shadow: 0 0 1px rgba(0,0,0,0.6);
					padding: 0 20%;

					h1 {
						text-transform: uppercase;
						font-weight: 700;
						font-size: 40px;
						line-height: 50px;
						margin: 0 0 $carouselPadd 0;
					}

					p {
						font-size: 18px;
					}

					.buttons {
						margin: $carouselPadd 0 0 0;

						.btn {
							text-transform: uppercase;
							height: 61px;
							line-height: 61px;
							padding: 0 27px;
						}
					}
				}
			}
		}

		.flickity-page-dots {
			position: absolute;
			width: 100%;
			bottom: 30px;
			//display: none;

			.dot {
				background: #fff;
				opacity: 0.5;
				margin: 0 5px;

				&.is-selected {
					opacity: 1;
				}
			}
		}

		@media (max-width: $screen-md) { 

			.flickity-viewport {

				.item {

					.item-content {
						padding: 0 15%;

						h1 {
							font-size: 35px;
							line-height: 45px;
						}

						p {
							font-size: 17px;
						}

						.buttons {

							.btn {
								height: 41px;
								line-height: 41px;
								padding: 0 17px;
							}
						}
					}
				}
			}
		}

		@media (max-width: $screen-sm) { 

			.flickity-viewport {

				.item {

					.item-content {
						padding: 0 10%;

						h1 {
							font-size: 30px;
							line-height: 40px;
							margin: 0 0 ($carouselPadd / 2) 0;
						}

						p {
							font-size: 16px;
						}

						.buttons {
							margin: ($carouselPadd / 2) 0 0 0;
						}
					}
				}
			}
		}

		@media (max-width: $screen-xs) { 

			.flickity-viewport {

				.item {

					.item-content {
						padding: 0 10%;

						h1 {
							font-size: 25px;
							line-height: 35px;
						}

						p {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}

#homeIntro {
	$imgSize: 165px;

	position: relative;
	background: $gray-lightest-2;
	padding: 70px 0;

	/* flickity on by default */
	.carousel:after {
	  content: 'flickity';
	  display: none;
	}

	@media screen and ( min-width: $screen-sm ) {
	  /* disable Flickity for large devices */
	  .carousel:after {
	    content: '';
	  }
	}

	.carousel {
		
		.flickity-page-dots {
			bottom: -35px;

			.dot {
				background: $gray;
				opacity: 0.5;
				margin: 0 5px;

				&.is-selected {
					opacity: 1;
				}
			}
		}

		.col {

			img {
				display: block;
				margin: 0 auto 80px auto;
				max-width: $imgSize;
				max-height: $imgSize;
			}

			h4 {
				margin-bottom: 30px;
				color: $gray-dark;
				font-size: 20px;
				font-weight: 700;
			}

			.text-link {
				display: block;
			    font-size: 15px;
			    font-weight: 700;
			    margin-top: 30px;
			    color: $brand-default;
			}
		}
	}

	@media (min-width: $screen-sm) and (max-width: $screen-md) { 

		$margin: 50px;

		.carousel {

			.col {
				position: relative;
				padding-left: $imgSize + 45px;
				margin-bottom: $margin;
				padding-bottom: $margin;
				border-bottom: solid 1px $gray-lighter;
			
				img {
					position: absolute;
					left: 15px;
				}
				
				&.col-odd {
					padding-right: $imgSize + 45px;
					padding-left: 15px;

					img {
						right: 15px;
						left: auto;
					}
				}

				&.col-last {
					border: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		margin-left: 15px;
		margin-right: 15px;
		padding-bottom: 90px;
	}
}

#homeIsotope {
	$columns: 8;

	position: relative;
	background: $gray-darker;

	.filters {
		text-align: center;
		padding: 40px 0;

		button {
			@extend .btn;
			@extend .btn-sm;
			@extend .btn-primary;			
			background: $gray-darkest;
			border-color: $gray-darkest;
			margin: 0 5px;	
			outline: none !important;		

			&.is-checked {
				@extend .btn-primary;		
			}
		}
	}
	
	.isotope {	

		.item {
			position: relative;
			display: block;
			float: left;
			width: 100% / $columns;

			img {
				width: 100%;
				height: auto;
			}

			.content {
				display: block;
				text-align: center;
				position: absolute;
				width: 100%;
				height: 40px;
				margin-top: -20px;
				top: 50%;
				color: #fff;					

				.big {
					display: block;
					font-size: 25px;
					line-height: 25px;
					margin-bottom: 5px;
					font-weight: bold;
				}

				.small {
					display: block;
					font-size: 10px;
					line-height: 10px;
				}
			}
		}
	}

	@media (max-width: $screen-lg) {
		display: none;
	}
}

#homeClientes {
	$btnColor: $gray-dark;

	position: relative;
	padding: 70px 0;
	background: #fff;

	/* flickity on by default */
	.carousel:after {
	  content: 'flickity';
	  display: none;
	}

	@media screen and ( min-width: $screen-sm ) {
	  /* disable Flickity for large devices */
	  .carousel:after {
	    content: '';
	  }
	}

	.colTitle {
		margin-bottom: 20px;

		h3 {
			float: left;
		    color: $btnColor;
		    font-size: 20px;
		    line-height: 31px;
		    margin: 0;
		    text-transform: uppercase;
		    font-weight: bold;
		}

		.btn {
			text-transform: uppercase;
			float: right;
			color: $btnColor;
			border: solid 1px $btnColor;

			.fa {
				color: $btnColor;
				margin-left: 15px;
			}

			&:hover {
				background: $btnColor;
				color: #fff;

				.fa {
					color: #fff;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		.colTitle {

			.btn {
				display: none;
			}
		}
	}
}

// #blogBannerBrand

.socialButtons {
	text-align: center;

	.btn {
		margin-left: 3px;
		margin-bottom: 7px;
		background: $gray-lightest;

		&:hover {
			background: $text-color;
		}

		.fa {
			font-size: 17px !important;
			color: #fff;
			text-shadow: none;
		}
	}
}

#mainSidebar {	
	$paddH: 30px;
	$paddV: 40px;
	display: block;
	//padding-bottom: 30px;

	.widget {
		background-color: $blogContentBackgroundColor;
		//border: solid 1px $widgetBorderColor;
		box-shadow: $boxShadow;
		padding: $paddV $paddH;
		margin-bottom: $paddH;

		&:last-child {
			margin-bottom: 0px;
		}

		h3 {
			margin:  0 0 $paddV 0;
		}

		&.widget_search {
			padding: 0;
		}

		&.es-facebook-widget-class {
			padding: 0;
			border: none;
		}

		&.es-youtube-widget-class {
			padding: 0;
			border: none;
		}

		&.es-perfil-widget-class {
			
			h3.name {
				margin-bottom: 5px;
			}
		}
	}

	@media (max-width: $screen-md) {
		margin-top: 30px;
	}

	@media (max-width: $grid-float-breakpoint) {

		.widget_search {
			display: none;
		}
	}
}

#sectionGallery {
	margin-top: $headerHeight;

	#sectionPhotos {
		padding: 0;
	}
}

#singleLessonSection {
	
}

#singleCourseSection {

	.singleCourseSectionHeader {
		$textColor: #fff;
		$textTitleColor: $brand-default;

		background: $gray;
		background-attachment: fixed;

		text-align: center;
		color: $textColor;

		.logoContainer {
			border-bottom: solid 1px rgba($textColor,0.2);
			
			.logo {
				width: 230px;
				height: 70px;
				margin: 0 auto;

				svg {
					width: 100%;
					height: 100%;

					path {
						fill: $textColor;
					}
				}
			}
		}

		.headingContainer {
			padding: 20px 0;
			
			h1 {
				margin: 0 0 10px 0;
				font-size: 50px;
				font-weight: 700;
				color: $textTitleColor;
			}

			h2 {
				margin: 0;
				font-size: 30px;
				font-weight: 400;
			}
		}
	}

	.singleCourseContainer {
		padding: 50px 0;
		
		.singleCourseContent {
			background: #fff;
			box-shadow: $boxShadow;

			.course-video {
				background: #000;
				margin: 0 0 25px 0;
			}

			.heading {
				display: block;
				font-weight: 400;
				text-align: center;
				font-size: 35px;
				padding: 0 30px;
				margin: 25px 0;

				span {
					font-weight: 700;
					color: $brand-primary;
				}
			}

			.orderButtonContainer {
				padding: 25px 50px;


				.orderButtonContent {
					height: 80px;
					
					#orderButtonWrapper {
						$ctaHeight: 71px;
						
						position: relative;
						height: $ctaHeight;
						width: 100%;

						.primary {
							position: absolute;
							pointer-events: none;
							top: 13px;
							display: block !important;
							width: 100%;
							text-align: center;
							font-size: 20px;
							line-height: 20px;
							font-weight: 700;
							color: #fff;
							@include transition(all .1s ease-out);

							.fa {
								display: inline-block;
								line-height: 16px;
								font-size: 16px;
								margin-right: 5px;
							}
						}

						.secundary {
							position: absolute;
							bottom: 13px;
							pointer-events: none;
							display: block !important;
							width: 100%;
							text-align: center;
							font-size: 16px;
							line-height: 16px;
							font-weight: 300;
							opacity: 0.8;
							color: #fff;
							@include transition(all .1s ease-out);
						}
							
						#place_order, .checkout-button {
							@extend .btn;
							@extend .btn-lg;
							@extend .btn-block;
							@extend .btn-primary;

							text-indent: 99999px;
							height: $ctaHeight;
							border: solid 1px $brand-primary-dark;
							@include box-shadow(0 5px 0px 0px $brand-primary-dark);
							@include transition(all .1s ease-out);
						}

						&:hover {

							.primary {
								top: 15px;
							}

							.secundary {
								bottom: 11px;
							}

							#place_order, .checkout-button {
								margin-top: 3px;
								margin-bottom: -3px;
								background: $brand-primary !important;
								@include box-shadow(0 2px 0px 0px $brand-primary-dark);
							}
						}

						&:active, &:focus {

							.primary {
								top: 17px;
							}

							.secundary {
								bottom: 9px;
							}

							#place_order, .checkout-button {
								margin-top: 5px;
								margin-bottom: -5px;
								background: $brand-primary !important;
								@include box-shadow(0 0px 0px 0px $brand-primary-dark);
							}
						}
					}
				}
			}

			#courseFeaturesContainer {
				padding: 25px 50px;

				ul {
					padding: 0 30px;
					margin: 0;
					list-style: none;

					li {
						position: relative;
						text-align: left;
						font-weight: 400;
						font-size: 18px;
						line-height: 22px;
						margin-bottom: 15px;
						padding-left: 35px;

						&:before {
							position: absolute;
							left: 0;
					    	line-height: 22px;
					    	font-size: 20px;
					    	margin-right: 5px;
							font-family: FontAwesome;
					    	content: "\f00c";
					    	color: $brand-success;
					    }

					    .highlighted {
					    	background: #ffff00;
					    }

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			
			#videoSpoilerBox {
				$imageSize: 75px;
				padding: 25px 50px;

				.spoilerTitle {
					padding: 20px 30px;
					margin: 0 0 -5px 0;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 16px;
					
					background: $gray-lightest;
					color: $text-color;

					.fa {
						display: inline-block;
						margin-right: 10px;
					}
				}

				.spoilerContent {
					padding: 30px;
					border: solid 5px $gray-lightest;
					//padding-bottom: 0;

					.row {

						&:last-child {
							margin-bottom: -30px;
						}
					}

					.item {
						margin-bottom: 30px;

						img {
							margin: 0 8px 5px 0;
							float: left;
							width: $imageSize;
							height: $imageSize;
						}

						.title {
							color: $brand-primary;
							font-size: 15px;
							line-height: 18px;
							font-weight: 700;
							margin: 0 0 10px 0
						}

						.content {
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
			}
		}

		.buyContainer {
			background: #fff;
			box-shadow: $boxShadow;
			margin-bottom: 30px;

			.buyContainerHeader {
				$bg: $brand-success;
				$arrowSize: 20px;

				position: relative;
				background: $bg;
				text-align: center;
				padding: 15px;

				h2 {
					font-weight: 900;
					font-size: 35px;
					line-height: 35px;
					margin-bottom: 10px;
					color: #fff;
					text-transform: uppercase;
				}

				h3 {
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
					color: #fff;
					text-transform: uppercase;

					span {
						text-decoration: underline;
					}
				}

				img {
					margin: -40px 0 -30px 0;
					width: 100%;
					height: auto;
				}

				&:after {
					content: '';
					position: absolute;
					bottom: -$arrowSize;
					left: 50%;
					margin-left: -$arrowSize;
					display: block;
					width: 0; 
					height: 0; 
					border-left: $arrowSize solid transparent;
					border-right: $arrowSize solid transparent;
					border-top: $arrowSize solid $bg;
				}
			}

			.ywqcdg-wrapper {
				padding: 30px;
				padding-top: 22px;

				.woocommerce-info {
					display: none;
				}

				#customer_details {

					.woocommerce-shipping-fields {
						display: none;
					}

					.woocommerce-billing-fields {

						h3 {
							display: none;
						}

						.create-account-info {
							display: none;
						}

						.form-row {
							display: block;
							float: none;
							margin: 0 0 10px 0;
							padding: 0;
							width: 100%;

							label {
								display: block;
								font-size: 13px;
								line-height: 20px;
								margin: 0;
								color: $text-color;
							}

							.input-text {
								@extend .form-control;
							}
						}
					}
				}

				.woocommerce-checkout {

					#order_review_heading {
						text-transform: uppercase;
						font-weight: 700;
						font-size: 16px;
						line-height: 16px;
						margin: 20px 0;
						text-align: center;
						display: none;
					}

					#order_review {

						.shop_table {
							//display: none;
						}

						#payment {
							$ctaHeight: 71px;

							background: #fff;
							margin-top: 15px;

							.wc_payment_methods {
								border-radius: 0;
								border: none;
								background: $gray-lightest-2;

								.payment_box {
									background: $gray-lightest;

									&:before {
										border-bottom-color: $gray-lightest;
									}
								}
							}

							.place-order {
								padding: 0;
								margin: 15px 0 0 0;
								background: none;

								#orderButtonWrapper {
									position: relative;
									height: $ctaHeight;
									width: 100%;

									.primary {
										position: absolute;
										pointer-events: none;
										top: 13px;
										display: block !important;
										width: 100%;
										text-align: center;
										font-size: 20px;
										line-height: 20px;
										font-weight: 700;
										color: #fff;
										@include transition(all .1s ease-out);

										.fa {
											display: inline-block;
											line-height: 16px;
											font-size: 16px;
											margin-right: 5px;
										}
									}

									.secundary {
										position: absolute;
										bottom: 13px;
										pointer-events: none;
										display: block !important;
										width: 100%;
										text-align: center;
										font-size: 16px;
										line-height: 16px;
										font-weight: 300;
										opacity: 0.8;
										color: #fff;
										@include transition(all .1s ease-out);
									}
										
									#place_order {
										@extend .btn;
										@extend .btn-lg;
										@extend .btn-block;
										@extend .btn-primary;

										text-indent: 99999px;
										height: $ctaHeight;
										border: solid 1px $brand-primary-dark;
										@include box-shadow(0 5px 0px 0px $brand-primary-dark);
										@include transition(all .1s ease-out);
										

										&:before {
											position: absolute;
											content: 'Faça sua compra agora!';
											display: block;
											color: #fff;
											font-size: 16px;
											text-transform: uppercase;
										}
									}

									&:hover {

										.primary {
											top: 15px;
										}

										.secundary {
											bottom: 11px;
										}

										#place_order {
											margin-top: 3px;
											margin-bottom: -3px;
											background: $brand-primary !important;
											@include box-shadow(0 2px 0px 0px $brand-primary-dark);
										}
									}

									&:active, &:focus {

										.primary {
											top: 17px;
										}

										.secundary {
											bottom: 9px;
										}

										#place_order {
											margin-top: 5px;
											margin-bottom: -5px;
											background: $brand-primary !important;
											@include box-shadow(0 0px 0px 0px $brand-primary-dark);
										}
									}
								}
							}
						}
					}
				}
			}			
		}

		.facebookContainer {
			$blue: #365899;
			background: #fff;
			box-shadow: $boxShadow;
			padding-top: 0;

			h3 {
				text-align: center;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 20px;
				line-height: 25px;
				padding: 20px 0;
				margin: 0;

				color: $blue;
				border-bottom: dotted 1px $blue;

				/*color: #fff;
				background: $blue;*/

				.fa {
					display: inline-block;
					margin-right: 10px;
				}
			}
			
			.facebookCommentContainer { 
				padding: 30px;
			}
		}

		#moneyBack {
			display: block;
			margin: 30px auto;
		}
	}
}


body {
	$color: #fff;
	$colorOne: $gray-dark;
	$colorTwo: $brand-primary;
	$numberColor: #fff;

	$fillHeight: 10px;
	$stepSize: 40px;

	$totalItems: 3;
	$pctGap: 100% / ($totalItems * 2);
	$pctStep: 100% / ($totalItems - 1);

	.marketplaceCartHeader {
		background: $gray;
		background-attachment: fixed;

		padding-top: $headerHeight + 30px;
		padding-bottom: 30px;

		.sectionTitle {
			float: left;
			font-size: 20px;
			line-height: 30px;
			color: $color;
			font-weight: 700;
			margin: 0;
		}

		.info, .linkButton {
			float: right;
			font-size: 12px;
			line-height: 30px;
			color: $gray-light;
		}

		.linkButton {

			.fa {
				display: inline-block;
				margin-right: 10px;
			}

			&:hover, &:active, &:focus {
				color: $gray-lightest;
			}
		}

		.continueToBuy {
			float: right;
		}

		.timeline {
			position: relative;
			margin-top: 30px;
			height: $stepSize + 30px;

			.timelineContent {

				position: relative;
				width: 100% - ($pctGap * 2);
				margin: 0 auto;

				.step {
					position: absolute;
					top: - $stepSize / 2 + $fillHeight / 2;
					margin-left: -$stepSize / 2;

					.stepContent {
						width: $stepSize;
						height: $stepSize;
						line-height: $stepSize;
						text-align: center;
						border-radius: 999px;
						overflow: hidden;
						background: $colorOne;
						color: $gray-dark;

						.number {
							font-size: 16px;
							font-weight: 700;
							position: relative;
							z-index: 1;
							color: #fff;
						}

						.stepContentBg {
							position: absolute;
							top: 0;
							z-index: 0;
							border-radius: 999px;
							width: $stepSize;
							height: $stepSize;
							background: $colorTwo;
							webkit-transform: scale(0);
							-ms-transform: scale(0);
							-o-transform: scale(0);
							transform: scale(0);
						}

						.fa {
							position: relative;
							z-index: 1;
							font-size: 14px;
						}
					}

					.name {
						$size: 200px;
						font-weight: 400;
						position: absolute;
						text-align: center;
						top: $stepSize + 10px;
						left: 50%;
						color: #fff;
						margin-left: - $size / 2;
						width: $size;
					}

					&:nth-child(1) {
						left: 0;
					}

					&:nth-child(2) {
						left: $pctStep * 1;
					}

					&:nth-child(3) {
						left: $pctStep * 2;
					}
				}
			}

			.timelineFill {
				overflow: hidden;
				border-radius: 999px;
				height: $fillHeight;
				background: $colorOne;

				.timelineFillFront {
					height: $fillHeight;
					background: $colorTwo;
				}
			}
		}

		@media (max-width: $screen-xs) {

			.info, .linkButton {
				display: none;
			}
		}
	}

	&.woocommerce-cart {
		font-family: $font-family-sans-serif;
		
		.marketplaceCartHeader {

			.timeline {

				.timelineContent {

					.step {

						&:nth-child(1) {

							.stepContent {
								background: $colorTwo;

								.number {
									color: $numberColor;
								}
							}
						}
					}
				}

				.timelineFill {

					.timelineFillFront {
						width: $pctGap;
					}
				}
			}
		}

		.actions {
			padding: 5px !important;

			.coupon {
				
				#coupon_code {
					width: 150px;
					@extend .form-control;
					margin-right: 5px;
				}
			}
		}

		@media (max-width: $screen-sm) {

			.actions {
				padding-top: 15px;
				padding-bottom: 15px;

				.coupon {
					
					#coupon_code {
						float: left;
						width: 50%;
						border-radius: 3px;
					}

					input.button {
						float: left;
						width: 50%;
						border-radius: 3px;
					}
				}
			}
		}
	}

	&.woocommerce-checkout {
		font-family: $font-family-sans-serif;
		
		.marketplaceCartHeader {

			.timeline {

				.timelineContent {

					.step {

						&:nth-child(1), &:nth-child(2) {

							.stepContent {
								background: $colorTwo;

								.number {
									color: $numberColor;
								}
							}
						}
					}
				}

				.timelineFill {

					.timelineFillFront {
						width: 50%;
					}
				}
			}
		}
	}

	&.woocommerce-other {
		
		.marketplaceCartHeader {

			.timeline {

				.timelineContent {

					.step {

						&:nth-child(1), &:nth-child(2), &:nth-child(3) {

							.stepContent {
								background: $colorTwo;

								.number {
									color: $numberColor;
								}
							}
						}
					}
				}

				.timelineFill {

					.timelineFillFront {
						width: 100% - $pctGap;
					}
				}
			}
		}
	}
}

#cartSection {
	$contentPadding: 50px;
	padding: 50px 0;

	.cartSectionContainerTwo {
		position: relative;
		background: #fff;
		padding: $contentPadding;
		box-shadow: $boxShadow;
	}

	.cartSectionContainer {
		position: relative;
		background: #fff;
		padding: $contentPadding;
		box-shadow: $boxShadow;
	}

	.woocommerce {
		$infoIconWidth: 40px;
		$sidebarWidth: 100% / 3;
		margin: 0px;
		width: auto;

		.wc-proceed-to-checkout {
			height: 75px;
		}

		input.button {
			@extend .btn;
			@extend .btn-success;
		}

		.woocommerce-MyAccount-content {
			position: relative;
			width: 100% - $sidebarWidth;
			padding-left: 30px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				height: 100%;
				left: -1px;
				border-left: solid 1px $gray-lightest;
			}

			fieldset {
				margin-top: 20px;

				legend {
					font-weight: 700 !important;
					font-size: 25px !important;
					margin: 20px 0 !important;
				}
			}

			input.button {
				float: right;
			}
		}

		.woocommerce-MyAccount-navigation {
			border-right: solid 1px $gray-lightest;
			width: $sidebarWidth;

			ul {
				padding: 0;
				margin: 0;
				list-style: none;

				li {

					a {
						display: block;
						font-size: 13px;
						line-height: 17px;
						padding: 15px;
						text-transform: uppercase;
						font-weight: 700;
						margin: 0;
						border-bottom: dotted 1px $gray-lightest;
						color: $gray;

						&:before {
							font-family: FontAwesome;
							text-align: center;
		    				content: "\f05a";
		    				width: 20px;
							display: inline-block;
							margin-right: 10px;
							font-size: 17px;
							font-weight: 300;
							color: $gray-lighter;
							@extend .transition-default;
						}

						&:hover, &:active, &:focus {
							background: $gray-lightest-2;
							color: $gray;

							&:before {
								color: $gray;
							}
						}
					}

					&:last-child {

						a {
							border: none;
						}
					}

					&.woocommerce-MyAccount-navigation-link--dashboard {

						a {
							&:before {
								content: '\f0e4';
							}
						}
					}

					&.woocommerce-MyAccount-navigation-link--orders {

						a {
							&:before {
								content: '\f145';
							}
						}
					}

					&.woocommerce-MyAccount-navigation-link--downloads {

						a {
							&:before {
								content: '\f0ed';
							}
						}
					}

					&.woocommerce-MyAccount-navigation-link--edit-address {

						a {
							&:before {
								content: '\f041';
							}
						}
					}

					&.woocommerce-MyAccount-navigation-link--edit-account {

						a {
							&:before {
								content: '\f007';
							}
						}
					}

					&.woocommerce-MyAccount-navigation-link--customer-logout {

						a {
							&:before {
								content: '\f00d';
							}
						}
					}
				}
			}
		}

		#customer_login {
			margin-top: -20px;

			h2 {
				font-weight: 700 !important;
				font-size: 25px !important;
				margin: 20px 0 !important;
			}

			form.login, form.register {
				margin: 0;
				padding: 30px;
				border-radius: 0px;
				border: solid 1px $gray-lightest;

				input.button {
					@extend .btn;
					@extend .btn-success;
					float: right;
				}
			}
		}

		#orderButtonWrapper {
			$ctaHeight: 71px;
			
			position: relative;
			height: $ctaHeight;
			width: 100%;
			margin-left: 0px;
			//margin-bottom: 20px;

			.primary {
				position: absolute;
				pointer-events: none;
				top: 13px;
				right: 0;
				display: block !important;
				width: 100%;
				text-align: center;
				font-size: 20px;
				line-height: 20px;
				font-weight: 700;
				color: #fff;
				@include transition(all .1s ease-out);

				.fa {
					display: inline-block;
					line-height: 16px;
					font-size: 16px;
					margin-right: 5px;
				}
			}

			.secundary {
				position: absolute;
				bottom: 13px;
				pointer-events: none;
				display: block !important;
				width: 100%;
				text-align: center;
				font-size: 16px;
				line-height: 16px;
				font-weight: 300;
				opacity: 0.8;
				color: #fff;
				@include transition(all .1s ease-out);
			}
				
			#place_order, .checkout-button {
				@extend .btn;
				@extend .btn-lg;
				@extend .btn-block;
				@extend .btn-primary;

				text-indent: 99999px;
				height: $ctaHeight;
				border: solid 1px $brand-primary-dark;
				@include box-shadow(0 5px 0px 0px $brand-primary-dark);
				@include transition(all .1s ease-out);
			}

			&:hover {

				.primary {
					top: 15px;
				}

				.secundary {
					bottom: 11px;
				}

				#place_order, .checkout-button {
					margin-top: 3px;
					margin-bottom: -3px;
					background: $brand-primary !important;
					@include box-shadow(0 2px 0px 0px $brand-primary-dark);
				}
			}

			&:active, &:focus {

				.primary {
					top: 17px;
				}

				.secundary {
					bottom: 9px;
				}

				#place_order, .checkout-button {
					margin-top: 5px;
					margin-bottom: -5px;
					background: $brand-primary !important;
					@include box-shadow(0 0px 0px 0px $brand-primary-dark);
				}
			}
		}

		table.shop_table {
			border: solid 1px $gray-lightest;
			border-radius: 0;
			//background: $gray-lightest-2;

			.order-total {
				color: $brand-primary;
			}
		}

		.woocommerce-info {
		    background: $gray-lightest-2;
		    color: $text-color;
		    height: auto;
		    border: none;
		    border-left: solid $infoIconWidth $brand-success;
		    border-radius: 3px;
		    margin: 0;
		    line-height: 20px;
		    padding: 10px 15px !important;

		    &:before {
		    	position: absolute;
		    	top: auto;
		    	left: -27px;
		    	line-height: 20px;
		    	margin-right: 10px;
				font-family: FontAwesome;
		    	content: "\f05a";
		    	font-size: 20px;
		    	color: #fff;
		    }

		    a {
		    	font-weight: 700;
		    	color: $text-color;
		    }
		}

		.woocommerce-error {
		    background: lighten($gray-lightest, 7%);
		    color: $text-color;
		    height: auto;
		    border: none;
		    border-left: solid $infoIconWidth $brand-danger;
		    border-radius: 3px;
		    margin: 0;
		    line-height: 20px;
		    padding: 10px 15px !important;

		    &:before {
		    	position: absolute;
		    	top: auto;
		    	left: -28px;
		    	line-height: 20px;
		    	margin-right: 10px;
				font-family: FontAwesome;
		    	content: "\f071";
		    	font-size: 19px;
		    	color: #fff;
		    }

		    a {
		    	font-weight: 700;
		    	color: $text-color;
		    }
		}

		.cart-collaterals {

			.cart_totals {
				width: 100%;
				float: none;
			}
		}

		h3 {
			font-weight: 700 !important;
			font-size: 25px !important;
			margin: 20px 0 !important;

			&.cartTitle {
				margin-top: 0 !important;
			}
		}

		form {
				
			.form-row {
				display: block;
				float: none;
				margin: 0 0 10px 0;
				padding: 0;
				width: 100%;

				label {
					display: block;
					font-size: 13px;
					line-height: 20px;
					margin: 0;
					color: $text-color;
				}

				.select2-container {
					//background: red;

					.select2-container {
						@extend .form-control;
					}
				}

				input.input-text {
					@extend .form-control;
				}

				&.woocommerce-validated {

					input.input-text {
						border-color: $brand-success;
					}
				}

				&.woocommerce-invalid {

					label {
						color: $brand-danger;
					}

					input.input-text {
						border-color: $brand-danger;
						background: lighten($brand-danger, 40%);
					}
				}
			}
		}

		#payment {
			background: #fff;
			margin-top: 15px;

			.wc_payment_methods {
				border-radius: 0;
				border: none;
				background: $gray-lightest-2;

				.payment_box {
					background: $gray-lightest;

					&:before {
						border-bottom-color: $gray-lightest;
					}
				}
			}

			.place-order {
				padding: 0;
				margin: 15px 0 0 0;
				background: none;
			}
		}

		#customer_details {

			.col-1 {
				float: none;
				width: 100%;
			}

			.col-2 {
				display: none;
			}
		}

		@media (min-width: $screen-sm) and (max-width: $screen-md)  {
			$height: 150px;
			$itemWidth: 100% / 6;

			.woocommerce-MyAccount-content {
				width: 100%;
				padding: 0;
				margin-top: $height;

				&:before {
					display: none;
				}
			}

			.woocommerce-MyAccount-navigation {
				position: absolute;
				top: 0;
				left: 0;
				border: none;
				overflow-y: hidden;
				width: 100%;
				height: $height;
				border-bottom: solid 1px $gray-lightest-2;

				ul {
					text-align: left;
					height: $height;
					white-space: nowrap;
					
					li {
						display: block;
						float: left;
						position: relative;
						//display: inline-block;
						width: $itemWidth;

						a {
							height: $height;
							text-align: center;
							font-weight: 400;
							//color: $gray-light;
							white-space: normal;
							border: none;

							&:before {
								display: block;
								width: 100%;
								text-align: center;
								margin: 0;
								line-height: 80px;
								height: 80px;
								font-size: 20px;
							}
						}

					}
				}
			}
		}

		@media (max-width: $screen-sm - 1px) {

			.woocommerce-MyAccount-content {
				width: 100%;
				padding: 0;
				margin-top: 30px;

				&:before {
					display: none;
				}
			}

			.woocommerce-MyAccount-navigation {
				position: relative;
				border: none;
				width: 100%;
			}
		}
	}

	@media (max-width: $screen-md) {
		$contentPadding: 30px;
		
		padding: $contentPadding 0;
		
		.cartSectionContainerTwo {
			padding: $contentPadding;
		}

		.cartSectionContainer {
			padding: $contentPadding;
		}
	}
}

.essb_displayed_sidebar {
	left: -50px !important;
	@extend .transition-default;

	&.show {
		left: 0px !important;
	}

	@media (max-width: 1024px) {
		display: none !important;
	}
}

.et_social_mobile {

	.et_social_close {
		display: none;
	}
}

.posts-navigation {
	margin-top: 30px;

	.nav-previous {
		float: left;
	}

	.nav-next {
		float: right;
	}

	.next, .previous {
		font-weight: 700;
		text-transform: uppercase;
		//text-shadow: none;
	}
}

#sectionPhotos {
	$color: #0E1114;
	$textColor: $brand-default;

	background: $color;
	background-attachment: fixed;
	padding-bottom: 100px;

	.titleRow {
		margin-top: 60px;
		margin-bottom: 60px;

		.title {
			display: block;
			font-size: 30px;
			margin: 0;
			font-weight: 700;
			color: #fff;
		}

		.subtitle {
			color: $textColor;
			font-size: 14px;
			margin: 5px 0 0 0;

			a {
				color: $cyan;
			}

			.fa {
				margin-right: 7px;
			}
		}

		.buttonTitle {
			text-align: right;
			line-height: 22px;
			font-size: 16px;
			margin: 10px 20px 10px 0;
			padding: 0;
			float: right;
			color: #fff;
		}

		.visit {
			color: $gray;
			font-weight: 700;
			font-size: 13px;
			text-transform: uppercase;
			text-shadow: none;
		}
	}

	.contentRow {
		margin: 0;

		$iconSize: 40px;

		.col {
			padding: 0px !important;

			.photo {
				position: relative;
				display: block;
				width: 100%;
				
				@extend .transition-default;
				

				&:after {
					content: "";
					display: block;
					padding-bottom: 100%;
				}

				&:hover {

					.icon {
						opacity: 1;
					}
				}
				
				.image {
					position: absolute;
					width: 100%;
					height: 100%;
					background-size: cover;

					/* make it grayscale */
					-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
					-o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
					-webkit-filter: grayscale(100%);
					filter: gray;
					filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
				}

				.icon {
					position: absolute;
					opacity: 0;
					width: 100%;
					height: 100%;
					background: rgba($color, .8);
					@extend .transition-default;

					.fa {
						font-size: 18px;
						position: absolute;
						margin: (- $iconSize / 2) 0 0 (- $iconSize / 2);
						display: block;
						top: 50%;
						left: 50%;
						height: $iconSize;
						width: $iconSize;
						border-radius: 999px;
						background: $brand-default;
						color: $color;
						line-height: $iconSize;
						text-align: center;
						@extend .transition-default;
						@include rotate(45deg);
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		padding-bottom: 40px;

		.titleRow {
			margin-top: 40px;
			margin-bottom: 40px;
			text-align: center;
		}
	}
}

#sectionTestimonial {
	//$color: #5A7D7C;
	$bgColor: $brand-default;
	$color: $text-color;
	$colorTwo: #fff;
	$starColor: $text-color;

	background: $bgColor;
	padding: 100px 0;

	background: $bgColor;
	background-attachment: fixed;

	.owl-carousel {

		.owl-nav {

			.owl-prev, .owl-next {
				position: absolute;
				top: 0;
				height: 100%;
				width: 150px;

				.fa {
					position: absolute;
					font-size: 60px;
					color: $color;
					margin-top: -30px;
					top: 50%;
					@extend .transition-default;
				}

				&:hover {
					.fa {
						color: #fff;					
					}
				}
			}

			.owl-prev {
				left: 0;
				//@include gradient-horizontal(rgba($color,1), rgba($color,0), 20%, 100%);

				.fa {
					left: 0;
				}
			}

			.owl-next {
				right: 0;
				//@include gradient-horizontal(rgba($color,0), rgba($color,1), 0%, 80%);

				.fa {
					right: 0;
				}
			}
		}

		.owl-item {
			color: $color;
			text-align: center;
			-webkit-backface-visibility: hidden;
    		-webkit-transform: translateZ(0) scale(1.0, 1.0);

			.item {

				.photo {
					display: block;
					margin: 0 auto 50px;
					width: 90px;
				}

				.text {
					position: relative;

					.textContent {
						position: relative;
						font-weight: 400;
						font-size: 23px;
						margin-bottom: 50px;
					}

					.fa {
						position: absolute;
						top: -10px;
						z-index: 0;
						left: 0;
						font-size: 50px;
						color: $color;
						opacity: .1;
					}
				}

				.name {
					font-size:20px;
					font-weight: 700;
					margin-bottom: 10px;
					color: $colorTwo;
				}

				.location {
					font-weight: 400;
					font-style: 13px;
					margin-bottom: 20px;

					.fa {
						margin-right: 6px;
					}
				}

				.stars {

					.fa {
						font-size: 14px;
						color: $starColor;

						/*&.full {
							color: $gold;
						}

						&.empty {
							color: #fff;
							opacity: .2;
						}*/
					}

					span {
						font-size: 10px;
					}
				}
			}
		}
	}

	@media (max-width: 600px) { 

		padding: 50px 0;

		.owl-carousel {

			.owl-nav {
				display: none;
			}

			.owl-item {

				.item {

					.photo {
						margin-bottom: 25px;
					}

					.text {
						font-size: 18px;
						margin-bottom: 30px;
					}

					.name {
						font-size: 16px;
						margin-bottom: 10px;
					}

					.location {
						font-style: 13px;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}

#sectionProducts {
	background: $body-bg;
	padding-bottom: 100px;

	& > .container {

		.titleRow {

			margin-top: 60px;
			margin-bottom: 60px;

			.title {
				display: block;
				font-size: 35px;
				margin: 0;
				line-height: 45px;
				font-weight: 900;
				color: $brand-primary;
			}

			.seeCourses {
				$imageSize: 36px;

				float: right;
				border-color: $brand-primary;
				color: $brand-primary;
				background: none;
				font-size: 16px;
				line-height: 43px;
				padding-left: 25px;
				padding-right: 16px;
				font-weight: 700;
				text-shadow: none;
				@extend .transition-default;

				.image {
					display: inline-block;
					float: right;
					margin: 4px 7px 4px 15px;
					width: $imageSize;
					height: $imageSize;
					background-size: 100% auto;
					background-position: center top;
					background-repeat: no-repeat;
				}

				&:hover {
					background: $brand-primary;
					color: #fff;

					.image {
						background-position: center bottom;
					}
				}
			}
		}

		.contentRow {

			.productsSlider {
				position: relative;

				.owl-carousel {

					.owl-item {
						-webkit-backface-visibility: hidden;
    					-webkit-transform: translateZ(0) scale(1.0, 1.0);
					}

					.gridItem {
						display: block;
						background: #fff;
						text-align: center;
						max-width: $gridItemMaxWidth;
						margin: 0 auto;
						color: $gray;

						.header {
							display: table;
							width: 100%;
							height: 80px;

							.headerContent {
								display: table-cell;
								vertical-align: middle;
								padding: 20px;

								.category {
									font-size: 12px;
									line-height: 20px;
								}

								.title {
									font-weight: 700;
									font-size: 16px;
									line-height: 20px;
									height: 60px;
									max-height: 60px;
								}
							}
						}

						.image {
							display: table;
							height: 200px;
							width: 100%;

							.imageContent {
								display: table-cell;
								vertical-align: middle;
							}
						}

						.price {
							display: table;
							height: 60px;
							width: 100%;

							.priceContent {
								display: table-cell;
								vertical-align: middle;

								.amount {
									font-size: 20px;
									font-weight: 700;
								}

								.interest {
									font-size: 12px;
								}
							}
						}

						.buy {
							margin: 30px 0;
						}
					}
				}


				#productsSliderDots {

					$size: 12px;
					text-align: center;
					position: absolute;
					width: 100%;
					bottom: -60px;

					&.disabled {
						display: none;
					}

					.owl-dot {
						display: inline-block;
						margin: 0 5px;
						width: $size;
						height: $size;
						cursor: pointer;
						border-radius: 999px;
						background: $gray-lighter;
						@extend .transition-default;

						&:hover {
							background: $gray;
						}

						&.active {
							background: $brand-primary;

							&:hover {
								background: $brand-primary;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) { 

		& > .container {

			.titleRow {

				margin-top: 120px;
    			margin-bottom: 40px;
    			text-align: center;

    			.title {
    				font-size: 30px;
    			}

				.seeCourses {
					$imageSize: 71px;
					$margin: 7px;
					width: $imageSize;
					height: $imageSize;
					padding: 0;
					border: none;
					float: none;
					margin-top: -170px;

					.text {
						display: none;
					}

					.image {
						float: none;
						margin: $margin;
						width: $imageSize - ($margin * 2) - 2px;
						height: $imageSize - ($margin * 2) - 2px;
					}
				}
			}
		}
	}
}

#courseCountDownContainer {
	//float: left;
	margin-left: -15px;
	display: none;

	.subscribeLimit {
		font-weight: 400;
		padding: 0;
		margin: 0 5px 5px 0;
		color: $text-color;
		font-size: 14px;
	}

	#courseCountdown {
		$size: 33px;

		div {
			position: relative;
			margin: 0 3px 0 0;
			border-radius: 3px;
			display: inline-block;
			//float: left;
			padding: 10px 15px;
			
			background: $brand-primary;

			.timer {
				font-size: $size;
				line-height: $size;
				font-weight: 300;
				color: #fff;
			}

			.label {
				position: absolute;
				width: 200px;
				text-align: center;
				bottom: -20px;
				font-size: 10px;
				font-weight: 900;
				text-transform: uppercase;
				left: 50%;
				margin-left: -100px;
				color: $brand-primary;
			}
		}
	}

	@media (max-width: $screen-md) { 

		margin: 50px auto 0;

		.subscribeLimit {
			text-align: center;
		}

		#courseCountdown {
			text-align: center;
		}
	}
}

#pageNotFound {
	text-align: center;
	padding: 150px 0;
	min-width: 320px;
	max-width: 400px;
	margin: 0 auto;

	h2 {
		font-weight: 700;
		font-size: 140px;
		line-height: 140px;
		color: $brand-primary;
		margin: 0 0 30px 0;
		padding: 0;
	}

	h1 {
	    font-weight: 700;
	    font-size: 20px;
	    line-height: 25px;
	    color: $gray;
	    margin: 0;
	    padding: 0;
	}

	/*.btn {
		background: $gray;
		text-shadow: none;

		&:hover, &:active, &:focus {
			background: darken($gray, 10%);
		}
	}*/
}

#sectionStartNow {
	background: $brand-primary;
	padding: 100px 0 70px;

	.btn {
		font-weight: 700;
		margin-bottom: 30px;

		&:hover {
			background: #fff;
			border-color: $brand-primary;
			color: $brand-primary;
			text-shadow: none;
		}
	}
}

#comentar {
	$padd: $headerHeight + 30px;
	position: relative;
	padding-top: $padd;

	h3 {
		display: block;
		font-weight: 400;
		color: $brand-default;
		margin: 0 0 50px 0;
		font-size: 30px;
		padding: 0px;

		i {
			float: left;
			margin-right: 15px;
			line-height: 35px;
		}
	}
 
	@media (max-width: $screen-md) {
		/* padding-left: 30px;
		padding-right: 30px; */
		padding-bottom: 30px;

		h3 {
			font-size: 20px;

			i {
				line-height: 30px;
			}
		}
	}
}

#ap-single + #comentar {
	padding-top: 60px;
}

#previousAndNewerPosts {
	$w: 100px;
	$h: $w / (16 / 9);
	$lines: 3;
	$lineHeight: $h / $lines;

	margin-top: 30px;

	.postContainer {
		background: #fff;
		padding: 30px;
		box-shadow: $boxShadow;
		//border: solid 1px $blogContentBorderColor;

		.title {
			display: block;
			font-size: 13px;
			line-height: 13px;
			font-weight: 400;
			color: $text-color;
			border-bottom: dotted 1px $blogContentBorderColor;
			padding-bottom: 20px;
			margin-bottom: 20px;

			&:hover, &:active, &:focus {
				color: $brand-primary;
			}

			span {
				display: inline-block;
			}

			.fa {
				font-size: 13px;
				line-height: 13px;

				&.fa-arrow-left {
					margin-right: 10px;
				}

				&.fa-arrow-right {
					margin-left: 10px;
				}
			}
		}

		&.newer {

			.title {
				text-align: right;
			}

			.post {

				.media {
					float: right;
					margin-right: 0;
					margin-left: 10px;
				}

				.text {
					text-align: right;
				}
			}
		}

		.post {
			position: relative;
			display: block;
			height: $h;
			overflow: hidden;

			&:hover {

				.media {

				    .mediaImage {
						webkit-transform: scale($blogImageScale, $blogImageScale);
					    -ms-transform: scale($blogImageScale, $blogImageScale);
					    -o-transform: scale($blogImageScale, $blogImageScale);
					    transform: scale($blogImageScale, $blogImageScale);
					    opacity: $blogImageOpacity;
				    }

				    .mediaIcon {
						opacity: 1;
					}
				}
			}			

			.media {
				position: relative;
				display: block;
				float: left;
				margin-right: 10px;
				background: #000;
				width: $w;
				height: $h;
				overflow: hidden;

				.mediaImage {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					@extend .transition-default;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}

				.mediaIcon {
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					margin-top: -7px;
					margin-left: -7px;
					font-size: 14px;
					color: #fff;
					opacity: 0;
					@extend .transition-default;
					text-align: center;
					margin-bottom: 30px;
				}
			}

			.text {

				.entry-title {
					margin: 0;
					font-size: 14px;
					line-height: $lineHeight;
					font-weight: 700;
					//text-transform: uppercase;
				}

				time {
					font-size: 10px;
					line-height: $lineHeight;
					font-weight: 700;
					text-transform: uppercase;
				}
			}
		}
	}
}

.ap-loading-icon {
	z-index: 3 !important;
}

.popover {
	border-radius: 3px;
	font-size: 14px;

	.popover-title {
		font-weight: 700;
		font-size: 16px;
	}
}

.questionHeader {
	height: $questionHeaderHeight;
	padding: (($questionHeaderHeight - 45px) / 2) 0;
	z-index: 6;
	margin-bottom: -$questionHeaderHeight;

	background: #fff;
	@extend .defaultBoxShadow;
	@extend .transition-default;

	&.fixed {
		position: fixed;
		top: $headerHeight;
		width: 100%;
	}

	#profile {
		display: block;
		float: left;
		height: 45px;

		.name {
			display: block;
			float: left;
			margin: 0px;
			line-height: 45px;
			font-size: 20px;
			font-weight: 700;
			color: $brand-primary;
		}

		.help {
			display: inline-block;
			float: left;
			line-height: 53px;
			//margin-left: 10px;
			padding: 0 10px;

			.fa {
				font-size: 16px;
				color: $gray-lightest;
				@extend .transition-default;
			}

			&:hover {

				.fa {
					color: $gray-lighter;
				}
			}
		}

		img {
			display: block;
			float: left;
			margin-right: 10px;
			height: 100%;
			width: auto;
		}
	}

	.newQuestionButton {
		float: right;
		text-transform: uppercase;
		font-weight: 400;
		color: #fff !important;
	}

	.questionSearch {
		$searchHeight: 45px;
		$colorLink: $text-color;
		$colorLinkHover: $brand-primary;
		position: relative;
		box-shadow: $boxShadow;

		.search-submit {
			position: absolute;
			bottom: 0;
			right: 0;
			height: $searchHeight;
			width: $searchHeight;
			padding: 0;
			margin: 0;
			background: none;
			border: none;
			text-indent: -9999px;
		}

		&:hover, &:active, &:focus {
			
			label {

				.search-field {
					color: $colorLinkHover;
				}

				&:after {
					color: $colorLinkHover;
				}
			}
		}

		label {
			margin: 0;
			display: block;

			.search-field {
				width: 100%;
				@extend .form-control;
				padding: 10px 45px 10px 20px;
				font-size: 13px;
				color: $colorLinkHover;
				font-weight: 400;
				border: solid 1px $widgetBorderColor;
				border-radius: 0;
				@extend .transition-default;

				&::-webkit-input-placeholder { color: $colorLinkHover; }
				&::-moz-placeholder { color: $colorLinkHover; }
				&:-ms-input-placeholder { color: $colorLinkHover; }
				&:-moz-placeholder { color: $colorLinkHover; }

				&:focus {
					border-color: $widgetBorderColor;
				}
			}
			
			&:after {
				position: absolute;
				bottom: 0;
				right: 0;
				display: block;
				height: $searchHeight;
				line-height: $searchHeight;
				width: $searchHeight;
				text-align: center;
				content: "\f002";
				font-size: 16px;
				color: $colorLink;
				font-family: FontAwesome;
				@extend .transition-default;
			}
		}
	}

	@media (max-width: $screen-sm) {

		.newQuestionButton {
			display: block;
			width: 100%;
		}

		.questionSearch {

			label {

				.search-field {
					padding-left: 15px;
				}
			}
		}
	}
}

.popover{
    max-width: 200px;
}
