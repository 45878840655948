@import "common/_fonts.scss";
@import "common/_variables.scss";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

@import "common/_elements.scss";
@import "common/_sage.scss";

// plugin photoswipe
@import "plugins/photoswipe/main.scss";
@import "plugins/photoswipe/default-skin/default-skin.scss";
// -----------------

// plugin plyr
@import "plugins/plyr/plyr.scss";
// -----------------

// style gravityforms with bootstrap style
@import "plugins/gravityforms.scss";
// -----------------

// style gravityforms with bootstrap style
@import "plugins/BootstrapXL.scss";
// -----------------

@function norm($value, $min, $max) {
  @return (($value - $min) / ($max - $min));
}

// --------------------------------------------------------------------------------------------

@import "start.scss";

#clientesFlip {
	position: relative;
	background: #fff;
	padding: 100px 0;

	.logos {
		margin-top: 50px;
	}

	#clientesContainerInvisible {
		display: none;
	}

	.logo {
		padding: 0;

		.cardWrapper{
			width:100%;
			padding: 5%;
			position:relative;
			float: left;
			cursor:pointer;
			-webkit-font-smoothing:antialiased;

			.card {
				position: absolute;
				top: 0;
				@include aspect-ratio(4, 3);

				.cardFace{
				  position:absolute;
				  top: 0;
				  width: 100%;
				  height: 100%;

				  img {
				  	position: relative;
				  	display: block;
				  	height: 100%;
				  	width: 100%;
				  	object-fit: cover;
				  }
				}
			}
		}
	}

	@media (max-width: $screen-md) {
		padding: 80px 0;
	}
}

#genericBanner {
	$marginBottom: 100px;
	$color-one: #FD192F;
	$color-two: #FD1939;
	$color-three: #F57B46;

	position: relative;
	width: 100%;
	height: 60vh;
	min-height: 450px;
	background: #000;
	overflow: hidden;

	.genericBannerArrowDown {
		position: absolute;
		bottom: 50px;
		left: 50%;
		z-index: 5;
		margin-left: -15px;
		background: #fff;
		box-shadow: 0 40px 70px rgba(0,0,0,.7);
		@include transition(none);

		.fa {
			font-size: 19px;
			color: $color-two;
		}
	}

	.bg {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;

		background: $color-one;
		background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
		background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
		background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
		background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
		background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
	}

	&.tv-corporativa {
		$color-one: #00d2ff;
		$color-two: #1bf1c6;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(100%, $color-two));
			background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 100%);
			background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 100%);
			background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 100%);
			background: linear-gradient(135deg, $color-one 0%, $color-two 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $blue;
			}
		}
	}

	&.webinar {
		$color-one: #e81445;
		$color-two: #ffac53;
		$color-three: #ffee5d;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(21%, $color-one), color-stop(72%, $color-two), color-stop(100%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%);
			background: -o-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%);
			background: -ms-linear-gradient(-45deg, $color-one 21%, $color-two 72%, $color-three 100%);
			background: linear-gradient(135deg, $color-one 21%, $color-two 72%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $blue;
			}
		}
	}

	&.endomarketing-marketing, &.endomarketing-digital {
		$color-one: #300a64;
		$color-two: #4b00bb;
		$color-three: #6cfee8;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(40%, $color-two), color-stop(100%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%);
			background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%);
			background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 40%, $color-three 100%);
			background: linear-gradient(135deg, $color-one 0%, $color-two 40%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $blue;
			}
		}
	}

	&.digital-signage {
		$color-one: #3900bb;
		$color-two: #00d2ff;
		$color-three: #abffcf;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(24%, $color-one), color-stop(76%, $color-two), color-stop(1024%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%);
			background: -o-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%);
			background: -ms-linear-gradient(-45deg, $color-one 24%, $color-two 76%, $color-three 100%);
			background: linear-gradient(135deg, $color-one 24%, $color-two 76%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $blue;
			}
		}
	}

	&.ensino-a-distancia {
		$color-one: #07748b;
		$color-two: #28ccab;
		$color-three: #00fff6;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $blue;
			}
		}
	}

	&.second-color {
		$color-one: #07748b;
		$color-two: #28ccab;
		$color-three: #00fff6;

		.bg {
			background: $color-one;
			background: -moz-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, $color-one), color-stop(50%, $color-two), color-stop(100%, $color-three));
			background: -webkit-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -o-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: -ms-linear-gradient(-45deg, $color-one 0%, $color-two 50%, $color-three 100%);
			background: linear-gradient(135deg, $color-one 0%, $color-two 50%, $color-three 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-three', GradientType=1 );
		}

		.genericBannerArrowDown {

			.fa {
				color: $color-two;
			}
		}
	}

	&.noArrowDown {

		.genericBannerContainer {

			.genericBannerContent {
				padding-top: 100px;
			}
		}
	}

	&.notFound {

		.genericBannerContainer {

			.genericBannerContent {

				.title {
					font-size: 6vw;
					line-height: 6vw;
					margin: 0 auto 2vw auto;
				}

				.subtitle {
					font-size: 2vw;
					line-height: 2.6vw;
				}
			}
		}
	}

	.genericBannerContainer {
		position: absolute;
		top: 0;
		z-index: 3;
		height: 100%;
		width: 100%;
		display: table;

		.genericBannerContent {
			position: relative;
			z-index: 3;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding-left: 30px;
			padding-right: 30px;

			.title {
				display: block;
				text-align: center;

				font-size: 3.2vw;
				line-height: 3.2vw;

				margin: 0 auto 2vw auto;
				font-weight: 300;
				text-transform: uppercase;
				color: #fff;

				span {
					font-weight: 500;
				}
			}

			.subtitle {
				display: block;
				text-align: center;

				font-size: 1.4vw;
				line-height: 2vw;
				width: 50%;

				margin: 0 auto;
				font-weight: 300;
				color: #fff;

				span {
					font-weight: 500;
				}
			}

			.btn {
				$height: 41px;
				$border: 1px;

				font-weight: 400;
				font-size: 15px;
				letter-spacing: 1px;
				height: $height;
	    		line-height: $height - ($border * 2);
	    		padding: 0 30px;
	    		border-radius: 99999px;
				margin-top: 50px;

				text-transform: lowercase;
				color: #fff;
				border: solid $border #fff;
				background: none;

				&:hover {
					background: #fff;
					border-color: #fff;
					color: $blue;
				}
			}
		}
	}

	@media (max-width: $screen-lg) {

		.genericBannerContainer {

			.genericBannerContent {
				padding-right: 100px;
				padding-left: 100px;

				.title {
					font-size: 3.6vw;
					line-height: 4.6vw;
					margin: 0 auto 3vw auto;
				}

				.subtitle {
					width: 70%;
					font-size: 1.8vw;
					line-height: 2.8vw;
				}
			}
		}

		&.notFound {

			.genericBannerContainer {

				.genericBannerContent {

					.title {
						font-size: 7vw;
						line-height: 7vw;
						margin: 0 auto 3vw auto;
					}

					.subtitle {
						font-size: 3vw;
						line-height: 3.6vw;
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		.genericBannerContainer {

			.genericBannerContent {
				padding-top: 50px;
				padding-right: 50px;
				padding-left: 50px;

				.title {
					font-size: 4.2vw;
					line-height: 6.2vw;
					margin: 0 auto 3vw auto;
				}

				.subtitle {
					width: 70%;
					font-size: 2.4vw;
					line-height: 3.4vw;
				}
			}
		}

		&.notFound {

			.genericBannerContainer {

				.genericBannerContent {

					.title {
						font-size: 8vw;
						line-height: 8vw;
						margin: 0 auto 4vw auto;
					}

					.subtitle {
						font-size: 4vw;
						line-height: 4.6vw;
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		height: 80vh;
		min-height: 500px;

		&.noArrowDown {

			.genericBannerContainer {

				.genericBannerContent {
					padding-top: 0;
				}
			}
		}

		.genericBannerContainer {

			.genericBannerContent {
				padding-top: 0px;
				padding-right: 50px;
				padding-left: 50px;

				.title {
					font-size: 5.2vw;
					line-height: 7.2vw;
					margin: 0 auto 4vw auto;
					width: 80%;
				}

				.subtitle {
					width: 80%;
					font-size: 3.8vw;
					line-height: 5.8vw;
				}
			}
		}

		&.notFound {

			.genericBannerContainer {

				.genericBannerContent {

					.title {
						font-size: 11vw;
						line-height: 11vw;
						margin: 0 auto 4vw auto;
					}

					.subtitle {
						font-size: 5vw;
						line-height: 5.6vw;
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		.genericBannerContainer {

			.genericBannerContent {
				padding-right: 30px;
				padding-left: 30px;
			}
		}
	}
}

#wrapper {
	padding-right: 0;

	#wrapper-overlay {
		position: fixed;
		background: #000;
		opacity: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		visibility: hidden;
		cursor: pointer;
	}

	#wrapper-sidebar-toggle {
		$height: 26px;

		display: block !important;
		margin: 0 0 0 25px;
		padding: (($headerNavHeight / 2) - ($height / 2)) 0;
		width: 23px;
		border: none;
		@include transition(all .3s ease);

		.icon-bar {
			display: block;
			width: 100%;
			height: 2px;
			margin: 5px 0;
			border: none;
			background: $blue;
			@include transition(all .3s ease);
		}
	}

	#wrapper-sidebar {
		z-index: 1000;
	    position: fixed;
	    right: -$sidebarWidth;
	    width: $sidebarWidth;
	    height: 100%;
	    overflow-x: hidden;
	    overflow-y: hidden;
	    background: #fff;

	    .firstRow {
			position: relative;
			background: #fff;
			z-index: 2;
		}

		.secondRow {
			position: relative;
			z-index: 1;
		}

		.thirdRow {
			position: absolute;
			z-index: 0;
			top: 0;
			background: $gray-lighter;
			height: 100%;
			width: 100%;

			.buttonContainer {
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 25px 25px;
				width: 100%;

				.btn {
					background: $blue;
					color: #fff;
					width: 100%;
					font-weight: 300;

					span {
						font-weight: 400;
					}
				}
			}
		}
	}

	#wrapper-content {
		width: 100%;
		overflow: hidden;
    	position: absolute;
    	background-color: $gray-lightest-2;
		background-attachment: fixed;
	}

	&.open {

		#wrapper-sidebar-toggle {

			&.smallScreen {
				//margin-right: -33px;
			}

			.icon-bar {
				opacity: 0;

				&:first-child {
					opacity: 1;
					transform: translateY(7px) rotate(45deg);
				}

				&:last-child {
					opacity: 1;
					transform: translateY(-7px) rotate(-45deg);
				}
			}
		}
	}

	@media (max-height: 790px) {

		#wrapper-sidebar {

			.thirdRow {
				background: $gray-lightest;

				.buttonContainer {
					display: none;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		#wrapper-sidebar-toggle {
			margin-left: 10px;
		}
	}
}

#sidebarLogoContainer {
	$sidebarIconHeight: 50px;

	width: 100%;
	padding: ($headerHeight - ($sidebarIconHeight / 2)) 0;

	.sidebarLogo {
		position: relative;
		width: 100%;
		height: $sidebarIconHeight;

		path {
			fill: $brand-primary;
		}
	}

	@media (max-height: 650px) {
		display: none;
	}
}

.verticalMenu {
	$paddH: 25px;
	$paddV: 25px;
	$fontSize: 12px;
	$arrowSize: 10px;
	$listItemHeight: 20px;
	$listItemBorderColor: $gray-lighter;
	$linkColor: $blue;
	$textTransform: none;
	$linkHoverColor: $text-color;
	$bgColor: #fff;
	$linkBgHoverColor: $bgColor;
	$iconSize: 35px;

	position: relative;
	background: $bgColor;
	//width: 100%;
	width: $sidebarWidth;
	height: 500px;
	overflow-x: hidden;
	overflow-y: auto;

	&.fixedHeight {
		overflow-y: hidden;
	}

	#menu-sidebar-menu-top {

		&:after {
			content: '';
			display: block;
			height: 20px;
		}

		li {
			a {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}

	#menu-sidebar-menu-bottom {

		&:before, &:after {
			content: '';
			display: block;
			background: $gray-lightest;
			height: 20px;
		}

		background: $gray-lightest;
	}

	//Global Styles
	ul {
		position: absolute;
		display: block;
		width: 100%;
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			position: static;
			display: block;
			//border-bottom: solid 1px $listItemBorderColor;

			@include transition(all 0.2s ease-out);

			&.menu-item-solution {

				a {
					padding-left: $paddH + $iconSize + ($paddH / 2);
					padding-right: $paddH;

					img {
						position: absolute;
						z-index: 1;
						top: 50%;
						left: $paddH;
						margin-top: -($iconSize / 2);
						display: block;
						width: $iconSize;
						height: $iconSize;
						border-radius: 999px;
						object-fit: cover;
					}
				}
			}

			&:last-child {
				border-bottom: none;
			}

			.active {
				background: $linkBgHoverColor;
			}

			&:hover {
				background: $linkBgHoverColor;

				a {

					span {
						color: $linkHoverColor;
					}

					.fa {
						color: $linkHoverColor;
					}
				}
			}

			a, .categoryParent {
				position: relative;
				display: block;
				line-height: $listItemHeight;
				padding: $paddV ($paddH * 3) $paddV $paddH;

				font-size: $fontSize;
				font-weight: 500;
				text-transform: $textTransform;
				color: $linkColor;
				@include transition(all 0.2s ease-out);

				span {
					display: block;
					text-align: left;
					@include transition(all 0.2s ease-out);
				}

				.fa {
					position: absolute;
					display: block;
					font-size: $arrowSize;
					line-height: $arrowSize;
					height: $arrowSize;
					margin-top: (- $arrowSize / 2);
					top: 50%;
					right: $paddH;
					color: $linkColor;
					@include transition(all 0.2s ease-out);
				}

				.icon {
					position: relative;
					display: block;
					float: left;
					right: 0;
					left: 0;
					width: 20px;
					margin-right: 10px;
					font-size: 14px;
					color: $gray;
					line-height: $listItemHeight + 10px;
				}

				&.categoryParent {
					background: $linkBgHoverColor;
					padding: $paddV $paddH $paddV ($paddH * 3);
					border-bottom: solid 1px $listItemBorderColor;

					span {
						text-align: right;
						padding-right: 0px;
						padding-left: $paddH;
						color: $linkHoverColor;
					}

					.fa {
						left: $paddH;
						color: $linkHoverColor;
					}
				}
			}
		}
	}

	//Individual Styles
	ul {

		li {

			ul {
				top: 0px;
				left: 100%;
				display: none;
			}
		}
	}

	//listItemContainer Styles
	ul {

		li {

			&.listItemContainer {
				border-bottom: none;

				& > a {
					display: none;
				}

				.listItemContent {

					.dropdown-messages, .dropdown-notifications {
						padding-bottom: 40px;

						.footer {
							text-align: left;

							.messages, .configuration {
								padding: 0;
								margin: 2px 15px 2px 0;
							}
						}
					}

					.content {
						width: $sidebarWidth;
						margin-right: 0;

						.header {
							border-radius: 0px;

							.markAsRead {
								padding: 0;
							}
						}

						.body {
						    max-height: 100%;

							.categoryParent {
								display: none;
							}

							.icon {
								display: inline-block;
							    margin-left: 0 5px 0 0;
							}
						}
					}

					ul {
						position: relative;
						top: 0px;
						left: 0;
						display: block !important;

						li {
							border: none;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		#menu-sidebar-menu-top {

			&:before {
				content: '';
				display: block;
				height: 20px;
			}
		}

		ul {

			li {

				&.menu-ensino-a-distancia, &.menu-endomarketing-digital, &.menu-webinar, &.menu-digital-signage, &.menu-tv-corporativa {

					a {
						padding-top: 15px;
						padding-bottom: 15px;
					}
				}
			}
		}
	}
}

#mainSection {
	$contentPadding: 30px;
	$blogPostContentBackground: #fff;
	padding: $blogBannerGap 0;

	.blogPostsGap {
		height: 41px + (20px * 2);
		margin-bottom: 100px;
	}

	// used in blog home
	#postsCategories {
		$height: 41px;

		position: absolute;
		top: auto;
		width: 100%;
		padding: 20px 0;
		background: #fff;
		box-shadow: $boxShadow;

		&.fixed {
			z-index: 999;
			position: fixed;
			top: 0px;
			width: 100%;
		}

		.mainPostsCategoriesList {
			padding: 0px;
			margin: 0px;
			display: block;

			li {
				display: block;
				float: left;
				margin: 0 10px 0 0;

				&.active {

					a {
						@include gradient-vertical(#d12756, #e62b42);
					}
				}
			}

			a {
				display: block;
				font-size: 12px;
				font-weight: 300;
				height: $height;
				line-height: $height;

				@include gradient-vertical($gray, $gray);
				@extend .transition-default;

				&:hover {
					@include gradient-vertical(#d12756, #e62b42);
				}
			}
		}

		.search-form {
			$searchHeight: $height;
			$colorLink: $text-color;
			$borderColor: $gray-lighter;
			$colorLinkHover: $brand-primary;

			position: relative;
			float: right;
			width: 275px;
			height: $searchHeight;
			//border-bottom: solid 1px $borderColor;

			.search-submit {
				position: absolute;
				top: 0px;
				right: 0px;
				height: $searchHeight;
				width: $searchHeight;
				padding: 0;
				margin: 0;
				background: none;
				border: none;
				text-indent: -9999px;
			}

			&:hover, &:active, &:focus {

				label {

					.search-field {
						//color: $colorLinkHover;
					}

					&:after {
						color: $colorLinkHover;
					}
				}
			}

			label {
				margin: 0;
				display: block;

				.search-field {
					width: 100%;
					@extend .form-control;
					border-color: $borderColor;
					height: $searchHeight;
					font-size: 13px;
					color: $colorLink;
					font-weight: 400;
					background: $gray-lightest-2;
					padding-top: 8px;
					padding-bottom: 8px;
					@extend .transition-default;

					&::-webkit-input-placeholder { color: $colorLink; }
					&::-moz-placeholder { color: $colorLink; }
					&:-ms-input-placeholder { color: $colorLink; }
					&:-moz-placeholder { color: $colorLink; }
				}

				&:after {
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					height: $searchHeight;
					line-height: $searchHeight;
					width: $searchHeight;
					text-align: center;
					content: "\f002";
					font-size: 14px;
					color: $colorLink;
					font-family: FontAwesome;
					@extend .transition-default;
				}
			}
		}
	}

	// used in blog home
	.mainPostsContainer {
		$fontSize: 14px;
		$lineHeight: 24px;

		article {

			&.format-standard, &.format-video, &.type-page {
				display: block;
				margin-bottom: 80px;
				background: $blogContentBackgroundColor;

				&.has-no-content {

					.content {

						.entry-footer {
							border-top: none;
							margin-top: 0;
							padding-top: 0;
						}
					}
				}

				&.has-no-media {

					.content {
						border-top: solid 1px $widgetBorderColor;
					}
				}

				&:hover {

					.media {

						.mediaImage {
							webkit-transform: scale($blogImageScale, $blogImageScale);
						    -ms-transform: scale($blogImageScale, $blogImageScale);
						    -o-transform: scale($blogImageScale, $blogImageScale);
						    transform: scale($blogImageScale, $blogImageScale);
						    opacity: $blogImageOpacity;
					    }

						.mediaIcon {
							opacity: 1;
						}
					}
				}

				.media {
					position: relative;
					display: block;
					background: #000;
					margin: 0px;

					.mediaIcon {
						position: absolute;
						z-index: 1;
						top: 50%;
						left: 50%;
						margin-top: -15px;
						margin-left: -15px;
						font-size: 30px;
						color: #fff;
						opacity: 0;
						@extend .transition-default;
						text-align: center;

						&.fa-sign-in {
							margin-top: -18px;
							margin-left: -18px;
							font-size: 36px;
						}

						&.mdi-arrow-right {
							margin-top: -24px;
							font-size: 36px;
						}
					}

					.mediaImage {
		    			position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						@extend .transition-default;

			    		img {
			    			object-fit: cover;
			    			font-family: 'object-fit: cover;';
							width: 100%;
							height: 100%;
			    		}
		    		}
				}

				.content {
					position: relative;
					padding: $contentPadding;
					height: 320px;

					.entry-categories {
						text-align: center;
						margin-bottom: 20px;

						a {
							display: inline-block;
							text-transform: uppercase;
							margin: 0 5px;
							font-size: 10px;
							font-weight: 400;
							line-height: 10px;
							color: $text-color;
							letter-spacing: 1px;

							&:hover, &:active, &:focus {
								color: $brand-primary;
								border-color: $brand-primary;
							}
						}
					}

					header {
						text-align: center;
						margin: 0;

						.entry-title {
							display: table;
							width: 100%;
							margin: 0 0 20px 0;
							font-size: 18px;
							line-height: 28px;
							height: 28px * 3;
							font-weight: 400;

							a {
								display: table-cell;
							}
						}

						.entry-meta {
							$height: 30px;
							text-align: center;
							position: relative;

							.entry-meta-content {
								position: relative;
								z-index: 1;
								display: inline-block;
								background: $blogContentBackgroundColor;
								padding: 0 10px;
								text-transform: uppercase;
								font-size: 10px;
								font-weight: 700;

								.updated {
									display: inline-block;
									line-height: $height;
								}

								.author {
									display: inline-block;
									margin: 0;
									line-height: $height;
								}
							}

							&::after {
								position: absolute;
								top: $height / 2;
								content: '';
								display: block;
								width: 100%;
								height: 1px;
								background: $blogContentBorderColor;
							}
						}
					}

					.entry-summary {
						margin: 0;
						height: $lineHeight * 5;
						overflow: hidden;

						p {
							font-size: $fontSize;
							line-height: $lineHeight;
							margin: 0;
						}

						&:before {
							$fadeHeight: $lineHeight;

							position: absolute;
							left: 0;
							bottom: $contentPadding;
							content: '';
							display: block;
							height: $fadeHeight;
							width: 100%;
							@include gradient-vertical(rgba($blogContentBackgroundColor,0), rgba($blogContentBackgroundColor,1), 0%, 100%);
							display: none;
						}
					}

					.entry-footer {
						$margin: 30px;

						position: relative;
						border-top: solid 1px $blogContentBorderColor;
						padding-top: $margin;
						height: 21px + $margin;

						.postLink, .messageLink {
							position: absolute;
							top: $margin;
							z-index: 1;
							padding-right: 0;
							background: none;
							color: $text-color;
							text-shadow: none;
							font-weight: 700;
							text-transform: uppercase;
							@extend .transition-default;

							.icon-left {
								width: 15px;
								border: none;
							}

							&.messageLink {
								right: 0;
							}

							&:hover, &:active, &:focus {
								color: $brand-primary;
							}
						}

						.share-post {
							position: absolute;
							top: $margin;
							width: 100%;
							text-align: center;

							.btn {
								margin: 0 10px;

								&:hover, &:active, &:focus {
									background: none;
								}

								.fa {
									color: $text-color;
									text-shadow: none;
									font-size: 14px;
									@extend .transition-default;

									&:hover, &:active, &:focus {
										color: $brand-primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// used in blog post item
	.mainPostContent {

		.articleMedia {
			margin-bottom: 50px;
		}

		.entry-content-wrapper {
			/*position: relative;
			max-height: none;
    		height: 500px;
    		overflow: hidden;*/

    		&.hideReadingTime {

    			.rt-reading-time {
					display: none !important;
    			}
    		}

    		#postContentHeader {
    			$buttonsHeight: 36px;
    			$height: $buttonsHeight;

    			height: $height;
	    		margin-bottom: 50px;

    			.essb_links {
    				margin: 0;
    				float: left;

					.essb_totalcount_item {

						.essb_t_r_big {
							padding-top: 3px;

							.essb_t_nb {
								color: $text-color;

								.essb_t_nb_after {
									color: $text-color;
								}
							}
						}
					}
				}

	    		.share-buttons {

	    			.sharedTimes {
						display: block;
						text-align: left;
						text-transform: uppercase;
						font-family: $font-family-sans-serif;
						font-size: 12px;
						line-height: $buttonsHeight;
						color: $gray-dark;
						margin: 0;

						b {
							color: $brand-primary;
							font-weight: 400;
						}
					}

					.essb-total {
						display: inline-block;
					}

					.essb_links_list {
						text-align: left;
					}
	    		}
    		}

    		.rt-reading-time {
    			$lineHeight: 36px;
    			$color: $text-color;

				position: absolute;
				top: 0;
				right: 15px;
    			text-align: left;
    			font-size: 12px;
    			color: $color;
    			text-transform: uppercase;
				line-height: $lineHeight;
    			font-weight: 400;

    			&:before {
					content: "\f017";
					font-size: 14px;
					margin-right: 5px;
					line-height: $lineHeight;
    				color: $color;
					font-family: FontAwesome;
				}
    		}
		}

		.read-more-button {
			$fadeHeight: 100px;

			position: relative;
			padding-left: 0;
			padding-right: 0;

			&::before {
				position: absolute;
				top: -$fadeHeight;
				content: '';
				display: block;
				height: $fadeHeight;
				width: 100%;
				@include gradient-vertical(rgba($blogPostContentBackground,0), rgba($blogPostContentBackground,1), 0%, 100%);
			}
		}

		.entry-content {
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1.8;

			p, ul {margin-bottom: 1.3em;}

			figure {
				width: 500px;
				max-width: 100%;
			}

			img {
			  max-width: 100%;
			  height: auto;
			}

			h1, h2, h3, h4, h5, h6 {
			  //margin: 1.414em 0 0.5em;
			  font-weight: 400;
			  margin: 20px 0 30px;
			  //line-height: 1.2;
			}

			h1 {font-size: 2.827em;}

			h2 {font-size: 1.999em;}

			h3 {font-size: 1.414em;}

			h4 {font-size: 1.1em;}

			h5 {font-size: 0.8em;}

			h6 {font-size: 0.6em;}

			small, .font_small {font-size: 0.707em;}

			/* &:first-letter{
				color: $brand-primary;
				font-size: 90px;
				line-height: 90px;
				margin-left: -5px;
				font-weight: 400;
				margin-right: 17px;
				float: left;
			} */

			//-----------------------------

			.thumbnail {
				border-radius: 0;
			}

			.wp-caption {
				border-radius: 0;
				border: none;
				background: $gray-lightest;
			}

			.wp-caption-text {
				font-size: 13px;
			}

			.alignleft {
				margin-right: 20px;
				margin-bottom: 15px;
			}

			.alignright {
				margin-left: 20px;
				margin-bottom: 15px;
			}

			hr {
				border-color: $gray-lightest;
			}

			blockquote {
				border-color: $brand-primary;

				p {
					color: $brand-primary;
					margin: 0;
				}
			}
		}

		.image-credits {
			text-align: right;
			font-size: 13px;
			color: $gray-lighter;
		}
	}

	@media (max-width: $screen-lg) {

		.blogPostsGap {
			height: 0px;
			margin-bottom: 80px;
		}

		#postsCategories {
			position: relative;
			padding: 20px 0;
			text-align: center;

			.mainPostsCategoriesList {
				display: block;
				text-align: center;

				li {
					display: inline-block;
					float: none;
					margin: 5px;
				}
			}

			.search-form {
				float: none;
				display: inline-block;
				margin: 20px 0 10px 0;
			}
		}
	}

	@media (max-width: $screen-md) {
		$contentPadding: 30px;
		padding: 80px 0;

		.mainPostsContainer {

			& > article {

				&.format-quote, &.format-link, &.format-standard, &.format-video, &.type-page, &.type-galeria {
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0;
					}

					.content {
						padding: $contentPadding;
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		.mainPostContent {
			padding-left: 15px;
			padding-right: 15px;

			.entry-content-wrapper {

	    		.rt-reading-time {
	    			$h: 36px;
	    			$lineHeight: $h / 2;

	    			width: 140px;
	    			right: 30px;
	    			text-align: right;
	    			line-height: $lineHeight;

	    			&:before {
						line-height: $lineHeight;
					}
	    		}
			}
		}

		.mainPostsContainer {

			.col {
				padding-left: 0px;
				padding-right: 0px;
			}

			& > article {

				&.format-quote, &.format-link, &.format-standard, &.format-video, &.type-page, &.type-galeria {

					.content {

						header {

							.entry-title {
								font-size: 25px;
								line-height: 30px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		.mainPostContent {

			.entry-content-wrapper {

	    		.rt-reading-time {
	    			display: none !important;
	    		}
			}
		}
	}
}

#productVideo {
	$squarePadd: 5vw;
	$playSize: 100px;
	$playBorderSize: 5px;

	position: relative;
	background: #000;
	overflow: hidden;
	z-index: 2;
	margin-bottom: -$headerHeight;

	.squareDefault {
		position: relative;
		width: 50%;
		@include aspect-ratio(4, 3);
	}

	.square {
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;

		.squareContainer {
			position: relative;
			height: 100%;
			width: 100%;

			.squareContent {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

		&.squareOne {

			.videoContainer {
				position: absolute;
				display: none;
				opacity: 0;
				top: 0;
				height: 100%;
				width: 100%;

				.closeVideoButton {
					position: absolute;
					pointer-events: none;
					right: 45px;
					top: 45px;
					display: block;
					padding: 0;
					width: 23px;
					border: none;
					background: none;

					.icon-bar {
						display: block;
						background: #fff;
						width: 100%;
						height: 2px;
						margin: 5px 0;
						border: none;
						opacity: 0;

						&:first-child {
							opacity: 1;
							transform: translateY(7px) rotate(45deg);
						}

						&:last-child {
							opacity: 1;
							transform: translateY(-7px) rotate(-45deg);
						}
					}
				}

				.videoCloseArea {
					position: absolute;
					width: 100%;
					height: 100%;
					background: #000;
				}

				.videoContent {
					position: relative;
    				margin: 0 auto;

					.plyr {
						position: relative;
						width: 100%;
						height: 100%;

						.plyr__play-large {
							display: none !important;
						}
					}
				}
			}

			.squareContainer {
				background: #000;

				.squareContent {

					img {
						position: relative;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.play {
						display: none;
						position: absolute;
						top: 50%;
						left: 50%;
						margin: (- $playSize / 2) 0 0 (- $playSize / 2);
						width: $playSize;
						height: $playSize;
						border: solid $playBorderSize #fff;
						border-radius: 999px;

						.fa {
							position: absolute;
							top: -$playBorderSize;
							//left: -$playBorderSize;
							font-size: 40px;
							color: #fff;
							text-align: center;
							line-height: $playSize;
							width: $playSize;
							height: $playSize;
						}
					}
				}
			}
		}

		&.squareTwo {
			left: 50%;

			.squareContainer {
				background: $gray-lightest-2;

				.squareContent {
					display: table;
					padding: $squarePadd;
					padding-right: $squarePadd;

					.squareContentText {
						position: relative;
						display: table-cell;
						vertical-align: middle;
						width: 100%;

						.title {
							text-transform: uppercase;
							/*font-size: 60vh;
							line-height: 70vh;*/

							font-size: 2.5vw;
							line-height: 3vw;
							font-weight: 300;
							margin: 0 0 4vw 0;
							color: $blue;

							span, b {
								font-weight: 500;
							}
						}

						.text {
							font-size: 1vw;
							line-height: 1.6vw;

							/*font-size: 25px;
							line-height: 35px;*/

							font-weight: 300;
							margin: 0;
							color: $blue;
							opacity: 0.6;

							span, b {
								font-weight: 500;
							}
						}
					}
				}
			}
			}
	}

	@media (max-width: $screen-lg) {

		.squareDefault {
			width: 60%;
		}
	}

	@media (max-width: $screen-sm) {

		.squareDefault {
			display: none;
		}

		.square {

			.squareContainer {
				position: absolute;
				top: 0;
			}

			&.squareOne, &.squareTwo {
				position: relative;
				top: 0;
				left: 0;
				right: auto;
				width: 100%;
			}

			&.squareOne {
				width: 100% !important;
				@include aspect-ratio(16, 9);

				.videoContainer {

					.closeVideoButton {
						display: none;
					}
				}

				.squareContainer {

					.squareContent {

						.play {
							$squarePadd: 5vw;
							$playSize: 70px;
							$playBorderSize: 5px;

							//display: none;
							position: absolute;
							top: 50%;
							left: 50%;
							margin: (- $playSize / 2) 0 0 (- $playSize / 2);
							width: $playSize;
							height: $playSize;
							border: solid $playBorderSize #fff;
							border-radius: 999px;

							.fa {
								position: absolute;
								top: -$playBorderSize;
								//left: -$playBorderSize;
								font-size: 30px;
								color: #fff;
								text-align: center;
								line-height: $playSize;
								width: $playSize;
								height: $playSize;
							}
						}
					}
				}
			}

			&.squareTwo {
				left: 0 !important;
				height: auto;

				.squareContainer {
					position: relative;
					height: auto;

					.squareContent {
						position: relative;
						height: auto;
						padding: 50px 30px;

						.squareContentText {

							.title {
								font-size: 40px;
								line-height: 45px;
								font-weight: 300;
								margin: 0 0 30px 0;
							}

							.text {
								font-size: 18px;
								line-height: 34px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		.square {

			&.squareTwo {

				.squareContainer {

					.squareContent {

						.squareContentText {

							.title {
								font-size: 30px;
								line-height: 35px;
								margin: 0 0 20px 0;
							}

							.text {
								font-size: 16px;
								line-height: 34px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 400px) {

		.square {

			&.squareTwo {

				.squareContainer {

					.squareContent {

						.squareContentText {

							.title {
								font-size: 25px;
								line-height: 30px;
							}

							.text {
								font-size: 15px;
								line-height: 34px;
							}
						}
					}
				}
			}
		}
	}
}

#detalhes {
	height: $headerHeight;
}

#productSolution {
	$columnOneSize: 30%;
	$carouselIconSize: 50px;
	$color: #001728;

	position: relative;
	background: $blue-dark;

	.bg {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.circle {
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;

			background: $color;
			opacity: 0.1;
			border-radius: 9999999px;

			&.circleOne {
				$size: 30vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}

			&.circleTwo {
				$size: 60vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}

			&.circleThree {
				$size: 90vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}
		}
	}

	.collums {
		position: relative;
		display: table;
		width: 100%;

		.collum {
			position: relative;
			display: table-cell;
			vertical-align: middle;
			overflow: hidden;

			&.collumOne {
				position: relative;
				width: $columnOneSize;

				.mainTitle {
					position: absolute;
					top: 29%;
					left: 16%;
					display: block;
					text-transform: uppercase;
					font-size: 35px;
					line-height: 40px;
					font-weight: 100;
					color: #fff;
					margin: 0;

					span {
						font-weight: 500;
					}
				}

				.dot {
					position: absolute;
					opacity: 0;
					@extend .transition-default;

					&.lazyloaded {
						opacity: 1;
					}

					&.dotOne {
						top: 50%;
						left: 0%;
						width: 364px;
						height: auto;
						margin-top: -147px;
						margin-left: -218px;
					}

					&.dotTwo {
						top: 8%;
						left: 12%;
						width: 76px;
						height: auto;
						margin-top: -16px;
						margin-left: -51px;
					}

					&.dotThree {
						top: 60%;
						left: 60%;
						width: 81px;
						height: auto;
						margin-top: -19px;
						margin-left: -54px;
					}

					&.dotFour {
						top: 75%;
    					left: 30%;
						width: 10px;
						height: auto;
						margin-top: -5px;
						margin-left: -5px;
					}
				}
			}

			&.collumTwo {
				position: relative;
				width: (100% - $columnOneSize);
				background: rgba($color, 0.2);
			}
		}
	}

	.accordionContainer {
		$imageHeight: 150px;
		$imageOpenHeight: 100vh;
		$itemPadd: 30px;
		$titleFontSize: 18px;
		$titleFontOpacity: 0.8;

		display: none;

		.panel-group {
			margin: 0;

			.panel {
				margin: 0;
				border-radius: 0px;
				border: none;

				.panel-heading {
					padding: 0;
					border: none;
					background: #001728;

					a {
						display: block;
						position: relative;
						width: 100%;
						height: $imageHeight;
						border-bottom: solid 1px rgba(#fff, 0.2);

						.image {
							position: absolute;
							width: 100%;
							height: 100%;
							opacity: 0.1;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.title {
							position: absolute;
							display: block;
							font-size: $titleFontSize;
							line-height: 30px;
							font-weight: 400;
							color: #fff;
							top: 50%;
							width: 100%;
							transform: translateY(-50%);
							z-index: 2;
							text-align: center;
							opacity: $titleFontOpacity;
							padding: 0 $itemPadd;
						}
					}
				}

				.panel-collapse {

					.panel-body {
						padding: 30px;

						.title {
							display: none;
						}

						.text {
							font-size: 16px;
	    					line-height: 23px;
							font-weight: 200;

							b {
								font-weight: 400;
							}

							a {
								font-size: 12px;
								font-weight: 300;
								display: inline-block;
							    text-transform: uppercase;
							    letter-spacing: 1px;
							    padding-left: 30px;
							    margin-top: 20px;
							    margin-right: 20px;
							    height: 45px;
							    line-height: 45px;
							    border: none;
							    padding: 0 17px;
								border-radius: 3px;
								color: #fff;
								background: $brand-default;
							}
						}
					}
				}
			}
		}

		ul {
			display: block;
			padding: 0px;
			margin: 0px;
			list-style: none;

			li {
				border-bottom: solid 1px rgba(#fff, 0.2);

				&:last-child {
					border-bottom: none;
				}

				.accordionItem {
					position: relative;
					text-align: left;
					display: block;
					height: $imageHeight;
					background: #001728;
					color: #fff;

					.title {
						display: block;
						font-size: $titleFontSize;
						line-height: 30px;
						font-weight: 400;
						position: absolute;
						top: 50%;
						width: 100%;
						transform: translateY(-50%);
						z-index: 2;
						text-align: center;
						opacity: $titleFontOpacity;
						padding: 0 $itemPadd;

						i {
							position: absolute;
							right: $itemPadd;
							font-size: 30px;
							line-height: 30px;
							font-weight: 100;
						}
					}

					.image {
						position: absolute;
						height: 100%;
						width: 100%;
						opacity: 0.1;

						img {
							position: relative;
							display: block;
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}

					.accordionItemDetail {
						display: none;
					}
				}
			}
		}

		.accordionDetailContainer {
			display: none;
			position: fixed;
			z-index: 1001;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.closeButton {
				$height: 26px;

				position: absolute;
				top: 30px;
				right: 30px;
				z-index: 5;
				display: block;
				margin: 0;
				padding: 0;
				width: 23px;
				border: none;
				outline: none;
				background: none;
				opacity: 0;

				.icon-bar {
					display: block;
					width: 100%;
					height: 2px;
					margin: 5px 0;
					border: none;
					background: #fff;
					opacity: 0;

					&:first-child {
						opacity: 1;
						transform: translateY(7px) rotate(45deg);
					}

					&:last-child {
						opacity: 1;
						transform: translateY(-7px) rotate(-45deg);
					}
				}
			}

			.imageContainer {
				position: absolute;
				text-align: left;
				display: block;
				height: $imageOpenHeight;
				width: 100%;
				background: #001728;
				color: #fff;
				z-index: 2;
				overflow: hidden;

				.titleContainer {
					position: absolute;
					height: 100%;
					width: 100%;

					.title {
						display: block;
						font-size: $titleFontSize;
						line-height: 30px;
						font-weight: 400;
						position: absolute;
						top: 50%;
						width: 100%;
						transform: translateY(-50%);
						z-index: 2;
						text-align: center;
						padding: 0 $itemPadd;

						i {
							position: absolute;
							right: $itemPadd;
							font-size: 30px;
							line-height: 30px;
							font-weight: 100;
						}
					}
				}

				.image {
					position: absolute;
					height: 100%;
					width: 100%;
					opacity: 0.5;

					img {
						position: relative;
						display: block;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
			}

			.textFade {
				content: '';
				pointer-events: none;
				position: absolute;
				z-index: 10000;
				left: 0;
				bottom: 0;
				height: 150px;
				width: 100%;
				opacity: 0;
				@include gradient-vertical(rgba(#fff, 0), rgba(#fff, 1));
				display: block;
				display: none;
			}

			.textContainer {
				$textPadd: 30px;
				$fadeHeight: $textPadd;

				position: relative;
				width: 100%;
				z-index: 3;
				background: #fff;

				&:after {
					content: '';
					left: -$textPadd;
					position: absolute;
					bottom: 0;
					height: $fadeHeight;
					width: 100%;
					@include gradient-vertical(rgba(#fff, 0), rgba(#fff, 1));
					display: block;
				}

				.textContent {
					position: relative;
					width: 100%;
					padding: $textPadd;
					padding-bottom: $textPadd + 500px;

					.title {
						//display: none;
					}

					.text {
						//display: none;
						font-size: 16px;
    					line-height: 23px;
						font-weight: 200;

						b {
							font-weight: 400;
						}

						a {
							font-size: 12px;
							font-weight: 300;
							display: inline-block;
						    text-transform: uppercase;
						    letter-spacing: 1px;
						    padding-left: 30px;
						    margin-top: 20px;
						    height: 45px;
						    line-height: 45px;
						    border: none;
						    padding: 0 17px;
							border-radius: 3px;
							color: #fff;
							background: $brand-default;
						}
					}
				}
			}
		}
	}

	.gridContainer {
		$items: 4;
		$padd: 30px;
		$paddH: ($padd * 2);
		$paddV: ($padd * 3);

		&.uniqueLine {
			padding: 60px 0;

			.gridContent {

				.item {
					@include aspect-ratio(1, 1.5);
				}
			}
		}

		.gridContent {
			position: relative;
			width: 100%;
			padding: ($paddV) $paddH;

			.gridTitle {
				display: block;
				color: #fff;
				font-size: 25px;
				line-height: 40px;
				margin: 0 0 $padd 0;
				padding: 0 ($paddH - $padd);
				font-weight: 100;

				span {
					font-weight: 400;
				}
			}

			.item {
				position: relative;
				float: left;
				width: 100% / $items;
				cursor: pointer;
				@include aspect-ratio(1, 1.2);

				&.is-selected {

					.itemContent {

						.title {
							opacity: 1;
						}
					}
				}

				.itemContainer {
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					padding: $padd;

					.itemContent {
						position: relative;
						width: 100%;
						height: 100%;

						&:hover {

							.imageContainer {

								img {
									opacity: 1;
									top: -5%;
								}

								.button {
									bottom: 0;
								}
							}

							.title {
								margin-bottom: -5%;
								opacity: 0;
							}
						}

						.imageContainer {
							position: relative;
							height: 100%;
							width: 100%;
							overflow: hidden;
							background: $color;

							img {
								position: absolute;
								top: 0;
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								font-family: 'object-fit: cover;';
								opacity: 0.3;
								@extend .transition-default;
							}

							.button {
								position: absolute;
								bottom: -55%;
								left: 0;
								display: block;
								width: 100%;
								height: 50%;
								@include gradient-directional($gradient-second-color-two, $gradient-second-color-one, 25deg);
								@extend .transition-default;

								.iconContainer {
									pointer-events: none;
									position: relative;
									top: 50%;
									transform: translateY(-50%);
									display: block;
									font-size: 40px;
									font-weight: 200;
									color: #fff;
									margin: 0;

									.icon {
										display: block;
										margin: 0 auto;
										text-align: center;
										width: $carouselIconSize;
										height: $carouselIconSize;
										line-height: $carouselIconSize;
									}
								}
							}
						}

						.title {
							position: absolute;
							display: block;
							margin: 0;
							width: 100%;
							padding: 30px;
							color: #fff;
							font-size: 18px;
							line-height: 26px;
							bottom: 0px;
							@extend .transition-default;
						}
					}
				}

				.itemDetail {
					display: none;
				}
			}
		}
	}

	.itemDetailContainer {
		$closeButtonSize: 60px;
		$imageContainer: norm($columnOneSize, 0, (100% - $columnOneSize)) * 100%;
		$textContainerWidth: 100% - $imageContainer;

		position: absolute;
		display: none;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		.textContainer {
			position: absolute;
			left: 0;
			width: $textContainerWidth;
			height: 100%;

			.textContent {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
				overflow: hidden;
				padding: 5vw;
				color: #fff;

				.title {
					font-size: 25px;
    				line-height: 35px;
					margin-bottom: 30px;
					font-weight: 500;
				}

				.text {
					font-size: 18px;
    				line-height: 30px;
					font-weight: 200;

					b {
						font-weight: 400;
					}

					a {
						$color: #fff;
						$activeColor: $brand-default;

						@extend .btn;
						@extend .btn-default;

						text-transform: uppercase;
						margin: 15px 15px 0 0;
						border-radius: 999px;
						border: solid 1px $color;
						color: $color;
						background: none;

						&:hover, &:active, &:focus {
							color: $activeColor;
							border-color: $activeColor;
							background: none;
						}
					}
				}
			}
		}

		.imageContainer {
			position: absolute;
			left: $textContainerWidth;
			width: $imageContainer;
			height: 100%;

			.imageContent {
				position: relative;
				width: 100%;
				height: 100%;

				img {
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					@extend .transition-default;
				}
			}


			.button {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: -$closeButtonSize;
				display: block;
				width: $closeButtonSize;
				height: $closeButtonSize;
				@include gradient-directional($gradient-second-color-two, $gradient-second-color-one, 25deg);
				@extend .transition-default;

				.iconContainer {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					display: block;
					font-size: 40px;
					font-weight: 200;
					color: #fff;
					margin: 0;

					.icon {
						display: block;
						margin: 0 auto;
						text-align: center;
						width: $carouselIconSize;
						height: $carouselIconSize;
						line-height: $carouselIconSize;
						@include rotate(45deg);
					}
				}
			}
		}
	}

	@media (max-width: $screen-xl) {
		$columnOneSize: 28%;

		.collums {

			.collum {

				&.collumOne {
					width: $columnOneSize;
				}

				&.collumTwo {
					width: (100% - $columnOneSize);
				}
			}
		}

		.itemDetailContainer {
			$imageContainer: norm($columnOneSize, 0, (100% - $columnOneSize)) * 100%;
			$textContainerWidth: 100% - $imageContainer;

			.textContainer {
				width: $textContainerWidth;

				.textContent {

					.title {
						font-size: 23px;
	    				line-height: 33px;
					}

					.text {
						font-size: 16px;
	    				line-height: 28px;
					}

					/* .title {
						font-size: 1.5vw;
						line-height: 2.2vw;
					}

					.text {
						font-size: 1.4vw;
						line-height: 2.1vw;
					} */
				}
			}

			.imageContainer {
				left: $textContainerWidth;
				width: $imageContainer;
			}
		}

		.gridContainer {
			$padd: 25px;
			$paddH: ($padd * 2);
			$paddV: ($padd * 3);

			.gridContent {
				padding: $paddV $paddH;

				.gridTitle {
					padding: 0 ($paddH - $padd);
					margin: 0 0 $padd 0;
				}

				.item {

					.itemContainer {
						padding: $padd;

						.itemContent {

							.title {
								padding: 20px;
								font-size: 15px;
								line-height: 22px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-lg) {
		$closeButtonSize: 45px;
		$columnOneSize: 25%;

		.collums {

			.collum {

				&.collumOne {
					width: $columnOneSize;
				}

				&.collumTwo {
					width: (100% - $columnOneSize);
				}
			}
		}

		.itemDetailContainer {
			$imageContainer: norm($columnOneSize, 0, (100% - $columnOneSize)) * 100%;
			$textContainerWidth: 100% - $imageContainer;

			.textContainer {
				width: $textContainerWidth;

				.textContent {

					.text {

	    				a {
							height: 31px;
							line-height: 31px;
							font-size: 11px;
						}
					}

					/* .title {
						font-size: 2vw;
						line-height: 2.3vw;
					}

					.text {
						font-size: 1.6vw;
						line-height: 2.6vw;

						a {
							height: 31px;
							line-height: 31px;
							font-size: 11px;
						}
					} */
				}
			}

			.imageContainer {
				left: $textContainerWidth;
				width: $imageContainer;

				.button {
					left: -$closeButtonSize;
					width: $closeButtonSize;
					height: $closeButtonSize;

					.iconContainer {

						.icon {
						    width: $closeButtonSize;
						    height: $closeButtonSize;
						    line-height: $closeButtonSize;
						}
					}
				}
			}
		}

		.gridContainer {
			$padd: 15px;
			$paddH: ($padd * 2);
			$paddV: ($padd * 6);

			.gridContent {
				padding: $paddV $paddH;

				.gridTitle {
					padding: 0 ($paddH - $padd);
					margin: 0 0 ($padd * 2) 0;
				}

				.item {

					.itemContainer {
						padding: $padd;

						.itemContent {

							.title {
								padding: 15px;
								font-size: 14px;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {
		$columnOneSize: 30%;

		.collums {

			.collum {

				&.collumOne {
					width: $columnOneSize;
				}

				&.collumTwo {
					width: (100% - $columnOneSize);
				}
			}
		}

		.itemDetailContainer {
			$imageContainer: norm($columnOneSize, 0, (100% - $columnOneSize)) * 100%;
			$textContainerWidth: 100% - $imageContainer;

			.textContainer {
				width: $textContainerWidth;

				.textContent {
					padding: 4vw;

					/* .title {
						font-size: 23px;
	    				line-height: 33px;
					}

					.text {
						font-size: 16px;
	    				line-height: 28px;
					} */

					.title {
						font-size: 2vw;
						line-height: 2.5vw;
						margin-bottom: 2vw;
					}

					.text {
						font-size: 1.5vw;
						line-height: 2.5vw;
					}
				}
			}

			.imageContainer {
				left: $textContainerWidth;
				width: $imageContainer;
			}
		}

		.gridContainer {
			$padd: 10px;
			$paddH: ($padd * 2);
			$paddV: ($padd * 5);

			.gridContent {
				padding: $paddV $paddH;

				.gridTitle {
					padding: 0 ($paddH - $padd);
					margin: 0 0 ($padd * 2) 0;
				}

				.item {

					.itemContainer {
						padding: $padd;

						.itemContent {

							.title {
								padding: 10px;
								font-size: 13px;
								line-height: 18px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 1350px) {

		.collums {

			.collum {

				&.collumOne {

					.dot {

						&.dotThree {
							display: none;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		min-height: 0;

		.bg {
			display: none;
		}

		.gridContainer {
			display: none;
		}

		.itemDetailContainer {
			display: none !important;
		}

		.accordionContainer {
			display: block;
		}

		.collums {
			position: relative;
			display: block;
			width: auto;
			height: auto;

			.collum {
				display: block;
				float: none;
				height: auto;
				overflow: visible;

				&.collumOne {
					width: 100%;
					height: 280px;

					.mainTitle {
						top: 0;
						left: 0;
						width: 100%;
						font-size: 40px;
						line-height: 45px;
						font-weight: 300;
						margin: 0;
						text-align: left;
						padding: 50px 30px;
					}

					.dot {

						&.dotOne {
							top: 50%;
							left: auto;
							right: 0;
							width: 200px;
							height: auto;
							margin-top: -81px;
							margin-right: -81px;
						}

						 &.dotTwo {
							top: 20%;
							left: auto;
							right: 12%;
							width: 36px;
						}

						&.dotThree {
							display: block;
							top: 55%;
							left: auto;
							right: 25%;
							width: 51px;
						}

						&.dotFour {
							top: 75%;
	    					left: auto;
	    					right: 20%;
							width: 10px;
						}
					}
				}

				&.collumTwo {
					height: auto;
					width: 100%;

					.carouselHeader {
						display: none;
						position: relative;
						top: auto;
						padding: 50px 30px;
						margin: 0;

						.carouselTitle {
							margin: 0;
							float: none;
						}

						.arrowButtonsContainer {
							display: none;
						}
					}

					.carousel {
						display: none !important;
					}

					.accordion {
						display: block;
					}
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		.collums {

			.collum {

				&.collumOne {
					height: 220px;

					.mainTitle {
						font-size: 25px;
						line-height: 30px;
					}
				}
			}
		}
	}

	@media (max-width: 400px) {

		.collums {

			.collum {

				&.collumOne {
					height: 220px;

					.mainTitle {
						font-size: 25px;
						line-height: 30px;
					}
				}
			}
		}
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

	#productSolution {
		$height: 800px;

		.collums {

			.collum {
				position: relative;
				height: $height;

				&.collumOne {
					display: block;
					float: left;
					width: 100%;
				}
			}
		}
	}
}

#testimonialsContainer {
	position: relative;
	width: 100%;
	height: 50vh;
	min-height: 550px;
	overflow: hidden;

	.bg {
		$color: #001728;
		background: $blue-dark;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.circle {
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;

			background: $color;
			opacity: 0.1;
			border-radius: 9999999px;

			&.circleOne {
				$size: 30vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}

			&.circleTwo {
				$size: 60vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}

			&.circleThree {
				$size: 90vw;

				width: $size;
				height: $size;
				margin-top: -($size / 2);
				margin-left: -($size / 2);
			}
		}
	}

	.carousel {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;

		.flickity-viewport {
			height: 100% !important;
		}

		.flickity-prev-next-button {
			$width: 100px;
			$height: 100px;

			border-radius: 0;
			padding: 0;
			background: none;
			width: $width;
			height: $height;
			@extend .transition-default;

			svg {
				display: none;
			}

			&:after {
				font-family: FontAwesome;
				line-height: $height;
				font-size: 60px;
				color: #fff;
			}

			&.previous {
				left: 0;

				&:after {
					content: "\f104";
				}
			}

			&.next {
				right: 0;

				&:after {
					content: "\f105";
				}
			}
		}

		.flickity-page-dots {
			bottom: 50px;

			.dot {
				background: #fff;
				width: 12px;
				height: 12px;
			}
		}

		.item {
			width: 100%;
			height: 100%;
			overflow: hidden;

			.bg {
				display: block;
				object-fit: cover;
				height: 100%;
				width: 100%;
				opacity: $blogImageOpacity;
			}

			.textContainer {
				position: absolute;
				top: 0;
				z-index: 3;
				height: 100%;
				width: 100%;
				display: table;
				opacity: 0;

				.textContent {
					position: relative;
					z-index: 3;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					padding-left: 30px;
					padding-right: 30px;

					.photo {
						width: 120px;
						height: 120px;
						border-radius: 999px;
						overflow: hidden;
						margin: 0 auto 30px auto;

						img {
							display: block;
							position: relative;
							width: 100%;
							height: 100%;
						}
					}

					.name, .testimonial, .company {
						display: block;
						color: #fff;
						font-size: 17px;
						line-height: 27px;
						font-weight: 400;
						max-width: 800px;
						padding: 0 30px;
						margin: 0px;
						margin: 0 auto;
					}

					.testimonial {
						font-weight: 200;
					}

					.company {
						font-weight: 200;
						font-size: 12px;
						line-height: 16px;
						margin-top: 10px;
						text-transform: uppercase;
					}

					.name {
						margin-top: 50px;
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		.carousel {

			.item {

				.textContainer {

					.textContent {

						.photo {
						    width: 120px;
						    height: 120px;
						}

						.name, .testimonial  {
							font-size: 17px;
							line-height: 25px;
						}

						.name {
							margin-top: 30px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		.carousel {

			.flickity-prev-next-button {
				display: none;
			}
		}
	}
}

#productSolution + #testimonialsContainer {

	.bg {
		@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);

		.circle {
			display: none;
		}
	}
}

#productFunctions {
	$secondRowTopMargin: 20px;
	$opacity: 0.2;

	position: relative;

	.productFunctionsBg {
		position: absolute;
		width: 100%;
		height: 100%;
		@include gradient-directional($gradient-second-color-two, $gradient-second-color-one, 25deg);
	}

	.firstRow {
		position: relative;
		z-index: 2;

		.productFunctionsHeader {
			padding: 80px 0 50px 0;

			.title {
				$tickWidth: 24px;

				position: relative;
				margin: 0 auto;
				font-size: 20px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 200;
				text-align: center;
				padding: 30px 0 0 0;

				span, b {
					font-weight: 500;
				}

		 		&:before {
					position: absolute;
		 			display: block;
		 			content: '';
					height: 4px;
					border-radius: 10px;
					width: $tickWidth;
					top: 0;
					left: 50%;
					margin-left: -($tickWidth / 2);
					background: #fff;
				}
			}
		}

		.desktopVersion {

			.imageContainer {
				position: relative;
				margin: 0 auto;

				.imageContent {
					position: relative;
					background: url(../images/loader.gif) center center no-repeat rgba(0,0,0, 0.9);
					background-size: 30px 30px;
				}

				.image {
					position: absolute;
					z-index: 1;
					top: 0;
					width: 100%;
					height: 100%;

					&.current {
						z-index: 2;
					}

					img {
						width: 100%;
						height: auto;
						opacity: 0;
						transition: opacity 600ms;

						&.lazyloaded {
							opacity: 1;
						}
					}
				}

				.shadow {
					position: absolute;
					opacity: 0.5;
					margin-top: 20px;
					width: 100%;
					height: 50px;
				}
			}

			.buttonContainer {
				$size: 61px;
				$padd: 15px;
				$iconWidth: 45px;

				display: block;
				list-style: none;
				padding: 0;
				border-radius: 5px;
				overflow: hidden;

				&.right {

					li {
						a {
							text-align: right;

							i {
								right: $padd;
								float: right;
								margin-left: 20px;
							}

							.labelContainer {
								padding-left: $padd;
								padding-right: $iconWidth + $padd;
							}
						}
					}
				}

				&.left {

					li {
						a {
							text-align: left;

							i {
								left: $padd;
								float: left;
								margin-right: 20px;
							}

							.labelContainer {
								padding-right: $padd;
								padding-left: $iconWidth + $padd;
							}
						}
					}
				}

				li {
					display: block;
					padding-top: 5px;
					padding-bottom: 5px;
					border-bottom: solid 1px rgba(0, 0, 0, $opacity);

					@extend .transition-default;

					&:last-child {
						margin-bottom: 0px;
						border-bottom: none;
					}

					&:hover, &.active {
						background: rgba(0, 0, 0, $opacity);
						border-bottom-color: transparent;
					}

					&.mobileOnly {
						display: none;
					}

					a {
						position: relative;
						display: block;
						background: none;
						padding: 0;
						height: $size;
						line-height: $size;

						i {
							position: absolute;
							width: $iconWidth;
							display: inline-block;
							font-size: 31px;
							line-height: $size;
							height: $size;
						}

						.labelContainer {
							position: relative;
							display: table;
							width: 100%;
							font-size: 14px;
							font-weight: 300;
							line-height: $size;
							height: $size;

							span {
								display: table-cell;
								vertical-align: middle;
								line-height: 18px;
							}
						}

						.itemDetail {
							display: none;
						}
					}
				}
			}
		}

		.mobileVersion {
			$items: 1.5;
			$margin: 15px;

			display: none;

			.mainCarousel {

				.item {
					width: 100% / $items;
					padding: 0 $margin;

					.itemContainer {
						background: #000;
						@include aspect-ratio(16, 9);

						.itemContent {
							position: absolute;
							top: 0;
							width: 100%;
							height: 100%;

							iframe {
								display: block;
								position: relative;
								width: 100%;
								height: 100%;
							}

							img {
								display: block;
								position: relative;
								width: 100%;
								height: 100%;
								object-fit: 'cover';
							}
						}
					}
				}
			}

			.navCarousel {
				$fontSize: 17px;
				$lineHeight: 27px;
				$itemHeight: ($lineHeight * 2) + 6px;

				position: relative;
				margin-top: 30px;
				z-index: 2;
				margin-bottom: -1px;

				.item {
					position: relative;
					z-index: 2;
					width: (100% / 3);
					min-width: 250px;
					padding: 0 $margin;

					&.is-nav-selected {

						.itemContainer {
							border-bottom: #fff solid 2px;
						}
					}

					.itemContainer {
						display: table;
						width: 100%;
						height: $itemHeight;
						color: #fff;
						border-bottom: transparent solid 2px;
						@extend .transition-default;

						.itemContent {
							display: table-cell;
							vertical-align: middle;
							font-size: $fontSize;
							font-weight: 200;
							padding: 0 15px;
							line-height: $lineHeight;
							text-align: center;

							br {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.secondRow {
		position: relative;
		padding: 100px 0 (100px - $secondRowTopMargin);
		margin-top: -$secondRowTopMargin;
		min-height: 280px;

		.bg {
			display: none;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, $opacity);
		}

		.footerText {
			font-size: 18px;
			line-height: 30px;
			text-align: center;
			font-weight: 200;
			color: #fff;
		}
	}

	@media (max-width: $screen-xl) {

		.firstRow {

			.productFunctionsHeader {
				padding: 50px  0 30px 0;
			}

			.desktopVersion {

				.imageContainer {
					max-width: 600px;
				}
			}
		}

		.secondRow {
			padding: 50px 0;
			margin-top: -20px;
			min-height: 200px;
		}
	}

	@media (max-width: $screen-lg) {

		.firstRow {

			.productFunctionsContainer {
				width: 100% !important;
			}
		}
	}

	@media (max-width: $screen-md) {
		$mobileBgColor: #f51738;

		.firstRow {

			.desktopVersion {
				display: none;
			}

			.mobileVersion {
				display: block;
			}

			/* .imageContainer {

				.shadow {
					display: none;
				}
			}

			.productFunctionsHeader {
				padding: 50px 30px;
			}

			.buttonContainer {

				&.left {
					display: none;
				}

				&.right {
					$fadeHeight: 61px;
					$fadeWidth: 70px;

					padding: 0;
					margin: 30px 0 0 0;

					position: relative;
					height: 61px;

					.flickity-viewport {
						height: 100% !important;
						overflow: visible !important;

						&:before {
							content: '';
							pointer-events: none;
							position: absolute;
							z-index: 2;
							left: -15px;
							bottom: 0;
							height: $fadeHeight;
							width: $fadeWidth;
							@include gradient-horizontal(rgba($mobileBgColor, 1), rgba($mobileBgColor, 0));
							display: block;
							display: none;
						}

						&:after {
							content: '';
							pointer-events: none;
							position: absolute;
							z-index: 2;
							right: -15px;
							bottom: 0;
							height: $fadeHeight;
							width: $fadeWidth;
							@include gradient-horizontal(rgba($mobileBgColor, 0), rgba($mobileBgColor, 1));
							display: block;
							display: none;
						}
					}

					&:after {
					  content: 'flickity';
					  display: none;
					}

				}

				li {
					text-align: center;
					display: block;
					float: left;
					margin: 0;
					width: 180px;
					border-radius: 0;
					background: none;

					&:hover, &.active {
						background: none;
						border-bottom: solid 2px #fff;
					}

					&.mobileOnly {
						display: block;
					}

					a {
						display: inline-block;
					}
				}
			} */
		}

		.secondRow {
			margin: 0;
			padding: 50px 0;
			min-height: 0px;
			background: rgba(0, 0, 0, 0.2);

			.footerText {
				font-size: 16px;
    			line-height: 22px;
			}
		}
	}
}

.mainFooter {
	$columns: 12;
	$columSize: 100% / $columns;

	$colorOne: $brand-default;
	$colorTwo: #a1d0e1;
	$borderColor: #434a52;

	position: relative;
	background: #141d27;

	.secondRow {
		padding: 100px 0 50px 0;

		.widget {
			height: 300px;
			float: left;

			.title {
				font-size: 20px;
				font-weight: 400;
				margin: 0 0 50px 0;
				text-transform: uppercase;
				text-align: left;
				color: $brand-default;
			}

			&.es-postagens-widget-class {
				$textColorOne: #52747d;
				$textColorTwo: #0c3d49;
				$blogPostWidth: 50px;
				width: ($columSize * 4);
				margin-right: ($columSize * 1);

				.blogPostContainer {
					display: block;
					padding: 0px;
					margin: 0px;
					padding-right: 30px;

					.blogPost {
						$w: $blogPostWidth;
						$h: $blogPostWidth;

						display: block;
						overflow: hidden;
						padding-bottom: 30px;
						margin-bottom: 30px;
						border-bottom: solid 1px $borderColor;

						&:last-child {
							border-bottom: none;
							margin-bottom: none;
							padding-bottom: none;
						}

						&:hover {

							.media {

							    .mediaImage {
									webkit-transform: scale($blogImageScale, $blogImageScale);
								    -ms-transform: scale($blogImageScale, $blogImageScale);
								    -o-transform: scale($blogImageScale, $blogImageScale);
								    transform: scale($blogImageScale, $blogImageScale);
								    opacity: $blogImageOpacity;
							    }

							    .mediaIcon {
									opacity: 1;
								}
							}
						}

						.media {
							position: relative;
							display: block;
							float: left;
							margin-right: 15px;
							background: #000;
							width: $w;
							height: $h;
							overflow: hidden;

							.mediaImage {
								position: relative;
								display: block;
								width: 100%;
								height: 100%;
								@extend .transition-default;

								img {
									object-fit: cover;
									font-family: 'object-fit: cover;';
									width: 100%;
									height: 100%;
								}
							}

							.mediaIcon {
								position: absolute;
								z-index: 1;
								top: 50%;
								left: 50%;
								margin-top: -7px;
								margin-left: -7px;
								font-size: 14px;
								color: #fff;
								opacity: 0;
								@extend .transition-default;
								text-align: center;
								margin-bottom: 30px;

								&.mdi-arrow-right {
									font-size: 15px;
									margin-top: -11px;
								}
							}
						}

						.textContainer {
							position: relative;
							display: table;
							height: $h;
							overflow: hidden;
							max-width: 300px;

							.textContent {
								display: table-cell;
								max-height: $h;
								overflow: hidden;
								vertical-align: middle;

								.entry-title {
									font-size: 14px;
									line-height: 20px;
									font-weight: 300;
									margin: 0;

									a {
										color: #fff;

									}
								}

								.date {
									display: block;
									float: left;
									font-size: 10px;
									color: $textColorOne;
									line-height: 15px;
								}
							}

						}
					}
				}
			}

			&.widget_nav_menu {
				width: ($columSize * 2);

				ul {
					display: block;
					padding: 0;
					list-style: none;

					li {
						display: block;
						font-size: 13px;
						font-weight: 200;
						line-height: 35px;

						a {
							color: $colorTwo;

							&:hover {
								color: #fff;
							}
						}
					}
				}
			}

			&.widget_text {
				width: ($columSize * 3);

				.widgetContact {

					span {
						display: block;
						font-size: 11px;
						line-height: 25px;
						color: $colorTwo;
						font-weight: 200;
					}

					a {
						color: $colorTwo;

						&:hover {
							color: #fff;
						}
					}

					.btn {
						text-align: left;
						max-width: 300px;
						background: $colorOne;
						color: #fff;
						font-size: 12px;
						text-transform: uppercase;
						letter-spacing: 1px;
						padding-left: 30px;
						margin-bottom: 15px;

						i {
							font-size: 20px;
							line-height: 45px;
						}

						.icon-right {
							float: right;
							color: #fff;
						}
					}
				}
			}
		}
	}

	.thirdRow {
		padding-bottom: 100px;

		.copyrightContainer {
			padding-top: 50px;
			border-top: solid 1px $borderColor;

			.first {
				float: left;
			}

			.second {
				float: right;

				ul {
					float: right;
				}
			}

			.socialButtons {
				margin-right: -5px;
				margin-left: 15px;

				a {
					background: none;
					border-radius: 0px;
					margin: 0 5px;
					width: auto;
					height: auto;

					.fa {
						font-size: 13px;
						line-height: 30px;
						color: $colorTwo;
					}

					&:hover {

						.fa {
							color: #fff;
						}
					}
				}
			}

			ul {
				display: block;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					float: left;
					font-size: 12px;
					font-weight: 200;
					line-height: 30px;
					color: $colorTwo;

					&:last-child {

						&:after {
							display: none;
						}
					}

					&:after {
						display: inline-block;
						content: '·';
						font-weight: 500;
						margin: 0 10px;
						color: $colorTwo;
					}

					a {
						color: $colorTwo;

						&:hover {
							color: #fff;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-lg) {

		.secondRow {
			padding: 50px 0;

			.widget {

				&.es-postagens-widget-class {
					width: ($columSize * 6);
					margin-right: 0;
				}

				&.widget_nav_menu {
					width: ($columSize * 3);
				}

				&.widget_text {
					width: ($columSize * 6);
				}
			}
		}

		.thirdRow {
			padding-bottom: 50px;

			.copyrightContainer {

				.first {
					width: 100%;
					float: none;
					text-align: center;

					ul {

						li {

							&:last-child {

								&:after {
									display: inline-block;
								}
							}
						}
					}
				}

				.second {
					text-align: center;
					width: 100%;
					float: none;
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		.secondRow {

			.widget {

				&.es-postagens-widget-class {
					width: ($columSize * 12);

					.blogPostContainer {
						padding-right: 0;
					}
				}

				&.widget_nav_menu {
					width: ($columSize * 4);
				}

				&.widget_text {
					width: ($columSize * 4);
				}
			}
		}
	}

	@media (max-width: $screen-sm) {

		.secondRow {

			.widget {

				&.es-postagens-widget-class {
					width: ($columSize * 12);
				}

				&.widget_nav_menu {
					width: ($columSize * 6);
				}

				&.widget_text {
					width: ($columSize * 12);
				}
			}
		}

		.fistRow, .secondRow, .thirdRow {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

/* ::::::::::::::::::::::::::: START CHANGE HEADER HEIGHT VAR BASED ON @media ::::::::::::::::::::::::::: */

@media (max-width: $screen-lg) {

	$headerHeight: 70px;

	.mainHeader {
		height: $headerHeight;
		padding: (($headerHeight - $headerNavHeight) / 2) 0;
	}

	#productVideo {
		margin-bottom: -$headerHeight;
	}

	#detalhes {
		height: $headerHeight;
	}

	#headerTitle {
		height: $headerHeight;

		.title {
			line-height: $headerHeight;

			&:before {
				$gap: 15px;
				top: $gap;
				height: $headerHeight - ($gap * 2);
			}
		}
	}
}

@media (max-width: $screen-sm) {

	$headerHeight: 70px;

	#productVideo {
		margin-bottom: 0;
	}

	#detalhes {
		height: 0;
	}

	#wrapper #wrapper-sidebar {
		width: 230px;

		.wrapper-sidebar-brand, .nav-primary ul li a {

			&.wrapper-sidebar-brand {
				height: $headerHeight;
			}
		}
	}

	#blogBannerSlider .media .textContainer {
		padding-top: $headerHeight / 2;
	}

	#breadcrumbs {
		margin-top: $headerHeight;
	}

	#headerTitle {
		display: none;
	}

	.mainHeader {
		position: absolute;
		padding-left: 15px !important;
		padding-right: 15px !important;

		&.onTop {
			$height: 100px;

			height: $height;
			padding-top: (($height - $headerNavHeight) / 2);
			padding-bottom: (($height - $headerNavHeight) / 2);
			box-shadow: none;
		}

		.brand {

			.logo {

				.logo-text {
					display: none;
				}
			}
		}
	}

	#pageHeaderContainer {
		padding-top: $headerHeight;
	}

	#blogBannerBrand {

		.brandContainer {
			$bottomHeight: 100px;
			padding: $headerHeight 0 $bottomHeight;

			.ctaContainer {
				height: $bottomHeight;
			}
		}
	}

	#sectionGallery {
		margin-top: $headerHeight;
	}

	#comentar {
		padding-top: $headerHeight;
	}

	#mainSection {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	body.page-parent {
		// used for the default loop like /conceito-bandog
		#mainSection {
	    	padding-top: $headerHeight + 30px;
	    }
	}

	.questionHeader {

		&.fixed {
			//top: $headerHeight;
			top: 0;
		}
	}

	body {

		.marketplaceCartHeader {
			padding-top: $headerHeight + 30px;
		}
	}
}

/* ::::::::::::::::::::::::::: END CHANGE HEADER HEIGHT VAR BASED ON @media ::::::::::::::::::::::::::: */

@import "end.scss";

@import "editor-style.scss";
