code {
	padding: 0;
    color: #c7254e;
    background-color: none;
    border-radius: 0px;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (($height / $width) * 100%);
  }
}

$questionHeaderHeight: 70px;

$headerHeight: 80px;
$headerHeightBg: 150px;
$headerNavHeight: 61px;
$sidebarWidth: 270px;
$homeBannerMinHeight: 500px;
$gridItemMaxWidth: 400px;
$blogImageOpacity: .3;
$blogImageScale: 1.1;
$blogContentBackgroundColor: $gray-lightest-2;
$blogContentBorderColor: $gray-lightest;
$boxShadow: 0 1px 2px rgba($gray, 0.2);

$widgetBorderColor: $gray-lightest;
$blogBannerGap: 100px;

$color-ensino-a-distancia: #28ccab;
$color-endomarketing-digital: #a183fa;
$color-webinar: #ffad54;
$color-tv-corporativa: #02c1ea;
$color-digital-signage: #3e75ff;

@media (min-width: 1300px) {

	.container-special {
	    width: 100%;
		padding-left: 45px;
		padding-right: 45px;
	}
}

@media (min-width: 1300px) {

	.container-special-two {
	    width: 100%;
		padding-left: 113px;
		padding-right: 113px;
	}
}

.bgWhite {
	background-attachment: fixed;
}

.bgBrown {
	background-color: $gray;
	background-attachment: fixed;
}

body {
	background-color: #fff;
	background-attachment: fixed;
	min-height: 100vh;

	&.block {
		overflow: hidden;
	}

	&.woocommerce-page {

		.et_social_sidebar_networks {
			display: none !important;
		}
	}
}

.btn-border {
	border: solid 1px #fff;
	background: none;
}

a {
	@extend .transition-default;
}

body {	

	&.page-template-template-about-php {

		.mainHeader {

			&.onTop {
				
				.nav-primary {

					ul {

						li {

							a {
								
								.badge {
									background: #fff;
									color: #28ccab;
									box-shadow: 0 10px 20px rgba(0,0,0,.3);
								}
							}
						}
					}
				}
			}
		}
	}

	&.page-template-template-contact-php, &.page-template-template-thankyou-php {

		.mainHeader {

			&.onTop {
				
				.nav-primary {

					ul {

						li {

							a {
								
								.badge {
									background: #fff;
									color: $blue;
									box-shadow: 0 10px 20px rgba(0,0,0,.3);
								}
							}
						}
					}
				}
			}
		}
	}

	&.home, &.error404, &.page-template-template-clients-php {

		.mainHeader {

			&.onTop {
				
				.nav-primary {

					ul {

						li {

							a {
								
								.badge {
									background: #fff;
									color: $brand-primary;
									box-shadow: 0 10px 20px rgba(0,0,0,.3);
								}
							}
						}
					}
				}
			}
		}
	}

	&.single, &.blog, &.error404, &.archive, &.search, &.contato, &.page:not(.page-parent)  {

		.mainHeader {

			&.onTop {
				background-color: transparent;
				
				.nav-primary {

					ul {

						li {

							a {
								color: #fff;

								&:hover, &:active, &:focus {
									color: $brand-default;
								}
							}
						}
					}
				}

				#wrapper-sidebar-toggle {

					.icon-bar {
						background: #fff !important;
					}
				}
			}
		}
	}
}

.mainHeader {
	position: fixed;
	display: none;
	top: 0;
	z-index: 1001;
	width: 100%;
	height: $headerHeight;
	padding: (($headerHeight - $headerNavHeight) / 2) 0;
	background-color: #fff;
	box-shadow: $boxShadow;

	&.transition {
		@extend .transition-default;

		&.onTop {

			.brand {

				.logo {

					.logo-icon {

						path {
							@extend .transition-default;
						}
					}
				}
			}
		}
	}

	&.onTop {
		height: $headerHeightBg;
		padding-top: (($headerHeightBg - $headerNavHeight) / 2);
		padding-bottom: (($headerHeightBg - $headerNavHeight) / 2);
		box-shadow: none;

		.brand {

			.logo {

				.logo-icon {

					path {
						fill: #fff;
					}
				}
			}
		}

		.nav-primary {
			
			.callToAction {
				display: none;
			}

			.subscribe {
				color: #fff;
				border-color: #fff;
			}

			.blogCategory {

				.btn {
					color: #fff;
				}

				.dropdown-menu {

					.btn {
						color: #fff;
					}
				}
			}

			ul.nav {

				li {

					a {
						color: #fff;
						font-size: 20px;

						&:hover, &:focus, &:active {
							color: #fff !important;
						}
					}
				}
			}
		}
	}

	.brand {
		position: absolute;
		display: block;
		float: left;
		text-indent:-9999px;
		width: 265px;
		height: $headerNavHeight;

		.logo {

			.logo-icon {

				path {
					fill: $brand-primary;
				}
			}

			.logo-text {

				path {
					fill: #fff;
				}
			}
		}

		svg {
			display: block;
			width: 100%;
			height: 100%;	
		}
	}

	.nav-primary {
		$padd: 15px;

		height: $headerNavHeight;

		.callToAction {
			$height: 41px;

			float: right;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 12px;
			letter-spacing: 1px;
			height: $height;
    		line-height: $height;
    		padding: 0 30px;
    		border-radius: 99999px;
			margin: (($headerNavHeight - $height) / 2) 0;
			margin-right: 20px;
		}

		.subscribe {
			$height: 41px;
			$border: 1px;

			float: right;
			font-weight: 400;
			font-size: 15px;
			letter-spacing: 1px;
			height: $height;
    		line-height: $height - ($border * 2) - 2px;
    		padding: 0 30px;
    		border-radius: 99999px;
			margin: (($headerNavHeight - $height) / 2) 0;
			margin-right: 20px;
			
			text-transform: lowercase;
			color: $blue;
			border: solid $border $blue;
			background: none;

			&:hover {
				background: $brand-default;
				border-color: $brand-default;
				color: #fff;
			}
		}

		.blogCategory {
			$height: 41px;
			$borderSize: 2px;
			$arrowWidth: 30px;
			$padd: 10px;
			
			position: relative;
			float: right;
			margin: (($headerNavHeight - $height) / 2) 0;
			margin-right: 20px;

			.btn {
				height: $height;
				padding-left: $padd;
				padding-right: $arrowWidth + $padd;
				line-height: $height - 4px;
				background: none;
				border-radius: 0;
				border: none !important;
				text-transform: lowercase;
				font-weight: 400;
				font-size: 15px;
				letter-spacing: 1px;
				color: $blue;

				.fa {
					line-height: $height;
					font-size: 25px;
				}

				&.dropdown-toggle {
					text-align: right;
					position: absolute;
					left: 0;
					padding: 0 $padd 0 0;
					width: 100%;
				}
			}

			.dropdown-menu {
				left: auto;
				right: -3px;

				li {

					a {
						color: $blue !important;

						&:hover, &:active, &.active {
							color: #fff !important;
							background: $brand-default;
						}
					}
				}
			}
		}

		ul.nav {
			float: right;
			padding: 0;

			li {
				float: left;
				display: block;
				height: $headerNavHeight;
				line-height: $headerNavHeight;
				background: none;

				&:hover {

					ul.sub-menu {
						display: block;
					}
				}

				a {
					display: block;
					background: none;
					padding: 0 $padd;
					font-size: 16px;
					text-transform: lowercase;
					font-weight: 500;
					color: $blue;
					outline: none;

					.badge {
						position: relative;
						border-radius: 100%;
						margin: -20px 0 0 -5px;
						font-size: 9px;
						line-height: 16px;
						height: 15px;
						min-width: 15px;
						padding: 0 4px;
						opacity: 0;
						@extend .transition-default;
						@include gradient-directional($gradient-second-color-one, $gradient-second-color-two, 25deg);
					}

					&:hover {
						color: $blue;
					}
				}

				ul.sub-menu {
					position: absolute;
					display: block;
					width: 200px;
					padding: 0px;
					margin: 0;

					li {
						display: block;
						font-size: 12px;
						line-height: 20px;
						height: auto;
						width: 100%;
						margin: 0;
					}
				}
			}
		}

		@media (max-width: $grid-float-breakpoint-max) { 
			
			.callToAction, .blogCategory, .subscribe {
				display: none;
			}
		}
	}
}

#headerTitle {
	$imageSize: 31px;
	$titleGap: 21px;

	position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: $headerHeight;
    overflow: hidden;
    pointer-events: none;

	.title {
		position: absolute;
		top: 0;
		display: block;
		font-size: 18px;
		font-weight: 400;
		margin-left: 50px;
		text-transform: uppercase;
		color: $blue;
		line-height: $headerHeight;
		height: $headerHeight;
		opacity: 0;
		visibility: hidden;
		padding-left: $titleGap;

		&:before {
			$gap: 15px;

			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: $gap;
			height: $headerHeight - ($gap * 2);
			border-left: solid 1px $gray-lightest;
		}

		.text {
			word-break: break-all;
			font-size: inherit;
			line-height: inherit;
			margin: 0;
		}

		.image {
			position: absolute;
			left: $titleGap;
			display: block;
			width: $imageSize;
			height: $imageSize;
			margin-top: -2px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 2px;
				object-fit: cover;
			}
		}
	}

	&.hasImage {

		.title {
			padding-left: $imageSize + ($titleGap * 2);
		}
	}

	&.titleSmall {

		.title {
			$fadeWidth: 150px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				z-index: 3;
				right: 0;
				height: 100%;
				width: $fadeWidth;
				@include gradient-horizontal(rgba(#fff, 0), rgba(#fff, 1));
			}
		}
	}
}